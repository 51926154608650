import React, { useState, useEffect } from "react";
import axios from "axios";
import BetsList from "./BetsList";
import Container from "../../components/Container";
import TitleContainer from "../../components/TitleContainer";
import { FaMoneyBill } from "react-icons/fa";

const Index = () => {
  const [bets, setBets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get("/profile/api/my-bets/")
      .then((response) => {
        setBets(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error al obtener apuestas:", error);
        setIsLoading(false);
      });
  }, []);

  return (
    <Container>
      <div className="flex flex-col gap-2 mt-4">
        <TitleContainer
          text="My Predicts"
          icon={<FaMoneyBill />}
          color="bg-blue-600"
          flex="start"
        />
        <BetsList bets={bets} loading={isLoading}/>
      </div>
    </Container>
  );
};

export default Index;
