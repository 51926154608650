import React from 'react';
import { Users, Award, Calendar, Clock, AlertCircle, UserPlus } from 'lucide-react';

const ScrimListHeading = () => {
  return (
    <div className="hidden md:grid grid-cols-6 items-center py-2 px-4 bg-gray-700 rounded-t-lg text-white text-xs lg:text-sm font-semibold">
      <div className="flex items-center">
        <Users size={16} className="mr-2 hidden lg:inline" />
        <span>Equipo</span>
      </div>
      <div className="flex items-center justify-center">
        <Award size={16} className="mr-2 hidden lg:inline" />
        <span>Avg. Rating</span>
      </div>
      <div className="flex items-center justify-center">
        <Calendar size={16} className="mr-2 hidden lg:inline" />
        <span>Fecha</span>
      </div>
      <div className="flex items-center justify-center">
        <Clock size={16} className="mr-2 hidden lg:inline" />
        <span>Hora</span>
      </div>
      <div className="flex items-center justify-center">
        <AlertCircle size={16} className="mr-2 hidden lg:inline" />
        <span>Estado</span>
      </div>
      <div className="flex items-center justify-end">
        <UserPlus size={16} className="mr-2 hidden lg:inline" />
        <span>Acción</span>
      </div>
    </div>
  );
};

export default ScrimListHeading;