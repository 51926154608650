import challenger from '../../../assets/challenger.webp';
import grandmaster from '../../../assets/grandmaster.webp';
import master from '../../../assets/master.webp';
import diamond from '../../../assets/diamond.webp';
import emerald from '../../../assets/emerald.webp';
import platinum from '../../../assets/platinum.webp';
import gold from '../../../assets/gold.webp';
import silver from '../../../assets/silver.webp';
import bronze from '../../../assets/bronce.webp';
import iron from '../../../assets/iron.webp';

import top from '../../../assets/Position_Challenger-Top.png';
import jungle from '../../../assets/Position_Challenger-Jungle.png';
import mid from '../../../assets/Position_Challenger-Mid.png';
import adc from '../../../assets/Position_Challenger-Bot.png';
import support from '../../../assets/Position_Challenger-Support.png';

import lan from '../../../assets/icons8-america-96.png';
import las from '../../../assets/icons8-south-america-96.png';
import { unranked } from '../../../components/AssetsBarrel';

export const getTierIcon = (tier) => {
  const tierIcons = {
    CHALLENGER: challenger,
    GRANDMASTER: grandmaster,
    MASTER: master,
    DIAMOND: diamond,
    EMERALD: emerald,
    PLATINUM: platinum,
    GOLD: gold,
    SILVER: silver,
    BRONZE: bronze,
    IRON: iron,
    UNRANKED: unranked,
    Unranked: unranked
  };
  return tierIcons[tier] || iron;
};

export const getRoleIcon = (role) => {
  const roleIcons = {
    TOP: top,
    JUNGLE: jungle,
    MID: mid,
    ADC: adc,
    SUPPORT: support,
  };
  return roleIcons[role.toUpperCase()] || mid;
};

export const getRegionIcon = (region) => {
  const regionIcons = {
    LAN: lan,
    LAS: las,
  };
  return regionIcons[region.toUpperCase()] || lan;
};