import React, { useState } from "react";
import { AiOutlineCalendar, AiOutlineTeam } from "react-icons/ai";
import { IoTicketOutline } from "react-icons/io5";
import { MdOutlineRule } from "react-icons/md";
import axios from "axios";
import Loader from "../../components/Loader";
import ModalRules from "./ModalRules";
import ModalTeams from "./ModalTeams";
import BanSummonerSpellModal from "./BanSummonerSpellModal";
import BanChampionModal from "./BanChampionModal";
import CancelRuleModal from "./CancelRulesModal";

export const TournamentCard = ({
  name,
  price,
  startDate,
  endDate,
  id,
  joined,
}) => {
  const formattedStartDate = new Date(startDate).toLocaleDateString();
  const formattedEndDate = new Date(endDate).toLocaleDateString();
  const entryCostText = price === 1 ? "1 Ticket" : `${price} Tickets`;
  const [tier1Enabled, setTier1Enabled] = useState(true);
  const [tier2Enabled, setTier2Enabled] = useState(true);
  const [tier3Enabled, setTier3Enabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [teamsModalOpen, setTeamsModalOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [banChampionModalOpen, setBanChampionModalOpen] = useState(false);
  const [banSummonerSpellModalOpen, setBanSummonerSpellModalOpen] = useState(false);
  const [cancelRuleModalOpen, setCancelRuleModalOpen] = useState(false);
  const [rulesModalOpen, setRulesModalOpen] = useState(false);

  const handleJoinTournament = async () => {
    try {
      setLoading(true);
      const response = await axios.post("api/join-tournament/", { id });
      setModalContent(response.data.detail);
      setModalTitle("Join to Tournament");
      setModalOpen(true);
    } catch (error) {
      setModalContent(error.response?.data?.detail || "Error");
      setModalTitle("Join Error");
      setModalOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleAddRule = async (tier) => {
    setImageUrl("");
    try {
      setLoading(true);
      const response = await axios.post(`tournament/exchange/rule-set-tier-${tier}/`, { tournament_id: id });
      setModalContent(response.data.detail);
      if (response.data.image_url) {
        setImageUrl(response.data.image_url);
      }
      setModalTitle(`Added a new rule Tier ${tier} to the tournament`);
      setModalOpen(true);
    } catch (error) {
      setModalContent(error.response?.data?.detail || `Error adding rule Tier ${tier} to the tournament`);
      setImageUrl("");
      setModalTitle(`Error adding rule Tier ${tier} to the tournament`);
      setModalOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalContent("");
    setModalTitle("");
    window.location.reload();
  };

  const currentDate = new Date();
  const isStarted = new Date(startDate) < currentDate;

  return (
    <div className="bg-gray-800 p-6 rounded-xl border border-yellow-500 border-opacity-50 hover:border-opacity-100 transition-all duration-300">
      {loading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <Loader />
        </div>
      )}
      <div className="flex flex-col md:flex-row gap-6">
        <div>
          <h2 className="text-2xl font-bold text-white mb-4">{name}</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 bg-gray-700 p-4 rounded-lg">
            <div className="flex flex-col items-center text-white">
              <IoTicketOutline className="text-yellow-500 text-2xl mb-2" />
              <span className="font-semibold">Cost of entry</span>
              <span>{entryCostText}</span>
            </div>
            <div className="flex flex-col items-center text-white">
              <AiOutlineCalendar className="text-yellow-500 text-2xl mb-2" />
              <span className="font-semibold">Start</span>
              <span>{formattedStartDate}</span>
            </div>
            <div className="flex flex-col items-center text-white">
              <AiOutlineCalendar className="text-yellow-500 text-2xl mb-2" />
              <span className="font-semibold">End</span>
              <span>{formattedEndDate}</span>
            </div>
          </div>
        </div>
        <div className="flex-shrink-0 flex items-center justify-center">
          <img
            src="https://www.autoboost.gg/static/media/logo.0f00e71aa9a3b5051c55.webp"
            alt="Logo"
            className="w-32 h-32 object-contain"
          />
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-6">
        <button
          className={`px-4 py-2 rounded-md text-white font-semibold flex items-center justify-center ${
            joined || isStarted
              ? "bg-gray-600 cursor-not-allowed"
              : "bg-yellow-500 hover:bg-yellow-600"
          }`}
          onClick={handleJoinTournament}
          disabled={loading || joined || isStarted}
        >
          <IoTicketOutline className="mr-2" />
          <span>{isStarted ? "Started" : joined ? "You have already joined" : "Join"}</span>
        </button>
        <button
          className="px-4 py-2 rounded-md bg-yellow-500 text-white font-semibold hover:bg-yellow-600 flex items-center justify-center"
          onClick={() => setRulesModalOpen(true)}
        >
          <MdOutlineRule className="mr-2" />
          <span>View Rules</span>
        </button>
        <button
          className="px-4 py-2 rounded-md bg-yellow-500 text-white font-semibold hover:bg-yellow-600 flex items-center justify-center"
          onClick={() => setTeamsModalOpen(true)}
        >
          <AiOutlineTeam className="mr-2" />
          <span>Teams</span>
        </button>
        <button
          className={`px-4 py-2 rounded-md text-white font-semibold flex items-center justify-center ${
            tier3Enabled ? "bg-yellow-500 hover:bg-yellow-600" : "bg-gray-600 cursor-not-allowed"
          }`}
          onClick={() => handleAddRule(3)}
          disabled={!tier3Enabled}
        >
          <MdOutlineRule className="mr-2" />
          <span>Add Rule Tier 1</span>
        </button>
        <button
          className={`px-4 py-2 rounded-md text-white font-semibold flex items-center justify-center ${
            tier2Enabled ? "bg-yellow-500 hover:bg-yellow-600" : "bg-gray-600 cursor-not-allowed"
          }`}
          onClick={() => handleAddRule(2)}
          disabled={!tier2Enabled}
        >
          <MdOutlineRule className="mr-2" />
          <span>Add Rule Tier 2</span>
        </button>
        <button
          className={`px-4 py-2 rounded-md text-white font-semibold flex items-center justify-center ${
            tier1Enabled ? "bg-yellow-500 hover:bg-yellow-600" : "bg-gray-600 cursor-not-allowed"
          }`}
          onClick={() => handleAddRule(1)}
          disabled={!tier1Enabled}
        >
          <MdOutlineRule className="mr-2" />
          <span>Add Rule Tier 3</span>
        </button>
        <button
          className="px-4 py-2 rounded-md bg-yellow-500 text-white font-semibold hover:bg-yellow-600 flex items-center justify-center"
          onClick={() => setBanChampionModalOpen(true)}
        >
          <span>Target Ban Champion</span>
        </button>
        <button
          className="px-4 py-2 rounded-md bg-yellow-500 text-white font-semibold hover:bg-yellow-600 flex items-center justify-center"
          onClick={() => setBanSummonerSpellModalOpen(true)}
        >
          <span>Target Ban Summoner Spell</span>
        </button>
        <button
          className="px-4 py-2 rounded-md bg-yellow-500 text-white font-semibold hover:bg-yellow-600 flex items-center justify-center"
          onClick={() => setCancelRuleModalOpen(true)}
        >
          <span>Cancel Rule</span>
        </button>
      </div>

      {modalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-gray-800 p-6 rounded-lg shadow-xl border-2 border-yellow-500 text-white max-w-md w-full">
            <h3 className="font-bold text-xl mb-4">{modalTitle}</h3>
            {imageUrl && <img src={imageUrl} alt="Imagen" className="w-16 h-16 mx-auto my-4 rounded-full" />}
            <p className="mb-6">{modalContent}</p>
            <button
              onClick={closeModal}
              className="w-full bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded-md transition-colors duration-300"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {rulesModalOpen && <ModalRules tournamentId={id} closeModal={() => setRulesModalOpen(false)} />}
      {teamsModalOpen && <ModalTeams tournamentId={id} closeModal={() => setTeamsModalOpen(false)} />}
      {banChampionModalOpen && <BanChampionModal tournamentId={id} closeModal={() => setBanChampionModalOpen(false)} />}
      {banSummonerSpellModalOpen && <BanSummonerSpellModal tournamentId={id} closeModal={() => setBanSummonerSpellModalOpen(false)} />}
      {cancelRuleModalOpen && <CancelRuleModal tournamentId={id} closeModal={() => setCancelRuleModalOpen(false)} />}
    </div>
  );
};

export default TournamentCard;