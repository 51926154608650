import React from "react";
import { FaDiscord } from 'react-icons/fa6'
const Discord = ({ small }) => {
  const redirectToDiscord = () => {
    window.location.href = "/oauth2/login"; 
  };

  const imageSizeClass = small
    ? "w-5 h-5 sm:w-6 sm:h-6 md:w-7 md:h-7 lg:w-8 lg:h-8 xl:w-10 xl:h-10"
    : "w-10 h-10 sm:w-12 sm:h-12 md:w-14 md:h-14 lg:w-16 lg:h-16 xl:w-20 xl:h-20";

  return (
    <div className="cursor-pointer" onClick={redirectToDiscord}>
      <FaDiscord
        fill="#5865F2"
        className={imageSizeClass}
      />
    </div>
  );
};

export default Discord;
