import React, { useState } from "react";
import { IoInformationCircleOutline } from "react-icons/io5";
import Container from "../../components/Container";
import { ProductCard } from "./ProductCard";
import DiscordRoleUpgradeImage from "../../assets/shop/Discord_role_upgrade.png";
import RulesetImage from "../../assets/shop/ruleset3.png";
import Ticket from "../../assets/shop/Ticket_Clash.png";
import TitleContainer from "../../components/TitleContainer";
import { ShoppingBag } from "lucide-react";
import unlockCardImage from "../../assets/UnlockCard.png";

const Index = () => {
  const products = [
    {
      id: 1,
      title: "Tournament Ticket",
      price: 2500,
      image: Ticket,
      endpoint: "/shop/buy/tournament-ticket/",
      disabled: false,
      description:
        "The tournament ticket is for joining a tournament that hasn't started yet. Depending on the tournament, you may need more than one ticket to join (Consumable Item).",
    },
    {
      id: 2,
      title: "Ruleset Level 1",
      price: 2500,
      image: RulesetImage,
      endpoint: "/shop/buy/rule-set-tier-3/",
      disabled: false,
      description: `The level 1 ruleset ticket can be exchanged for a consumable ticket that grants one of the following benefits:
  1.- Ban a random champion.
  2.- Ban a low-level summoner spell (Heal/Barrier/Ghost) at random.`,
    },
    {
      id: 3,
      title: "Ruleset Level 2",
      price: 4000,
      image: RulesetImage,
      endpoint: "/shop/buy/rule-set-tier-2/",
      disabled: false,
      description: `The level 2 ruleset ticket can be exchanged for a consumable ticket that grants one of the following benefits:
  1.- Ban a champion of your choice.
  2.- Ban a low-level summoner spell (Heal/Barrier/Ghost) of your choice.
  3.- Ban a random high-level summoner spell (Ignite, Exhilarate).`,
    },
    {
      id: 4,
      title: "Ruleset Level 3",
      price: 10000,
      image: RulesetImage,
      endpoint: "/shop/buy/rule-set-tier-1/",
      disabled: false,
      description: `The level 3 ticket can be exchanged for a consumable ticket that grants one of the following benefits:
  1.- Cancel a tournament rule.
  2.- Ban a champion of your choice.
  3.- Ban a high-level summoner spell (Ignite, Exhilarate, Teleport) of your choice.
  4.- Ban a high-level item at random (costing 3000+ in-game).`,
    },
    {
      id: 5,
      title: "Scrim Card",
      price: 30,
      image: unlockCardImage,
      endpoint: "/api/generate-exchange-code/",
      card_type: "scrim",
      disabled: false,
      description:
        "Exclusive card based on your Scrim stats. This card can be used to customize your profile and will be part of the future card marketplace (launching in 2025). " +
        "When purchased, you will receive a code that you can redeem on our Discord server. Unlock benefits and show off your Scrim performance with 30 blue coins!",
    },
    {
      id: 6,
      title: "SoloQ Card",
      price: 5000,
      image: unlockCardImage,
      endpoint: "/api/generate-exchange-code/",
      card_type: "soloQ",
      disabled: false,
      description:
        "Unique card based on your SoloQ stats. Customize your profile with this card and prepare for the future card marketplace launching in 2025. " +
        "Upon purchase, you will receive a code that you can redeem on our Discord server. Show off your SoloQ prowess with this card and 5000 regular coins!",
    },
  ];
  

  const [showPopover, setShowPopover] = useState(false);

  return (
    <div className="min-h-screen bg-gray-900">
      <Container className="relative">
        <div className="flex flex-col gap-2 mt-4">
          <TitleContainer
            text="Shop"
            icon={<ShoppingBag className="h-6 w-6 text-white" />}
            color="bg-blue-600"
            flex="start"
          />
          <div className="absolute top-0 right-0 mt-4 mr-4">
            <button
              className="flex items-center text-xs font-semibold bg-gray-800 hover:bg-gray-700 text-white rounded-full px-3 py-1 transition duration-300"
              onMouseEnter={() => setShowPopover(true)}
              onMouseLeave={() => setShowPopover(false)}
            >
              <IoInformationCircleOutline className="mr-1" size={16} />
              How to earn coins?
            </button>
            {showPopover && (
              <div className="bg-gray-800 rounded-lg p-4 absolute top-10 right-0 z-10 w-48 text-xs text-white shadow-lg">
                <p className="mb-2">• Playing scrims</p>
                <p className="mb-2">• Participating in the discord</p>
                <p>• Making donations</p>
              </div>
            )}
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {products.map((product) => (
              <ProductCard
                key={product.id}
                id={product.id}
                title={product.title}
                description={product.description}
                price={product.price}
                image={product.image}
                endpoint={product.endpoint}
                disabled={product.disabled}
                card_type={product.card_type}
              />
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Index;
