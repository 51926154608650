import React, { useState, useEffect, useCallback, useMemo } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import ScrimModal from "./ScrimModal";
import MessageModal from "./MessageModal";
import Loader from "../../components/Loader";
import ScrimCard from "./ScrimCard";
import CardsHeading from "../../components/CardsHeading";
import { Search, Globe, ChevronDown } from "lucide-react";
import ScrimListHeading from "./ScrimListHeading";

const ScrimList = ({ scrims, userData }) => {
  const [selectedScrim, setSelectedScrim] = useState(null);
  const [teamDetails, setTeamDetails] = useState(null);
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [comments, setComments] = useState("");
  const [searchRegion, setSearchRegion] = useState("ALL");
  const [searchElo, setSearchElo] = useState("ALL");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [messageModalContent, setMessageModalContent] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [showFilters, setShowFilters] = useState(false);

  const navigate = useNavigate();

  const isCaptain = useCallback(() => {
    return userData?.in_team && (userData?.team_info?.is_captain || userData?.team_info?.is_subcaptain);
  }, [userData]);

  const handleAcceptClick = useCallback(async (scrim) => {
    try {
      const response = await Axios.get(`/api/team/${scrim.team}/`);
      setTeamDetails(response.data);
      setSelectedScrim(scrim);
    } catch (error) {
      console.error("Error fetching team details:", error);
    }
  }, []);

  useEffect(() => {
    const fetchTeamMembers = async () => {
      if (!selectedScrim || !userData?.team_info?.team_name) return;
      try {
        const response = await Axios.get(`/api/team/${userData.team_info.team_name}`);
        setTeamMembers(response.data.members);
      } catch (error) {
        console.error("Error fetching team members:", error);
      }
    };

    fetchTeamMembers();
  }, [selectedScrim, userData]);

  const handlePlayerSelection = useCallback((playerId) => {
    setSelectedPlayers(prev => {
      if (prev.includes(playerId)) {
        return prev.filter(id => id !== playerId);
      } else if (prev.length < 7) {
        return [...prev, playerId];
      }
      return prev;
    });
  }, []);

  const handleSendInvitation = useCallback(async () => {
    setIsLoading(true);
    try {
      await Axios.post("/api/create-scrim-invitation/", {
        scrim_request_id: selectedScrim.id,
        players: selectedPlayers,
        comments: comments,
      });

      setMessageModalContent("Solicitud de scrim enviada exitosamente");
      setSelectedScrim(null);
      setMessageModalOpen(true);
    } catch (error) {
      console.error("Error sending scrim invitation:", error);
      const errorMessage = error.response?.data?.error || "There was an error sending the invitation. " + error.message;
      setMessageModalContent(errorMessage);
      setSelectedScrim(null);
      setMessageModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  }, [selectedScrim, selectedPlayers, comments]);

  const closeModalAndNavigate = useCallback((path) => {
    setMessageModalOpen(false);
    navigate(path);
  }, [navigate]);

  const filteredScrims = useMemo(() => {
    return scrims.filter((scrim) => {
      if (searchQuery && !scrim.team.toLowerCase().includes(searchQuery.toLowerCase())) return false;
      if (searchRegion !== "ALL" && scrim.region !== searchRegion) return false;
      if (searchElo !== "ALL") {
        const [min, max] = searchElo.split('-').map(Number);
        const scrimRating = parseFloat(scrim.average_rating);
        if (scrimRating < min || scrimRating >= max) return false;
      }
      if (startDate && new Date(scrim.date) < new Date(startDate)) return false;
      if (endDate && new Date(scrim.date) > new Date(endDate)) return false;
      return true;
    });
  }, [scrims, searchQuery, searchRegion, searchElo, startDate, endDate]);

  const getTitles = useMemo(() => {
    const baseTitles = ["Equipo", "Elo", "Fecha", "Hora"];
    return isCaptain() ? [...baseTitles, "Accion"] : baseTitles;
  }, [isCaptain]);

  return (
    <div className="space-y-4">
      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50">
          <Loader />
        </div>
      )}
      <div className="flex items-center space-x-2 bg-gray-800 p-2 rounded-lg">
        <div className="relative flex-grow">
          <input
            type="text"
            placeholder="Buscar Scrim"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full bg-gray-700 text-white pl-10 pr-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
        </div>
        <button
          onClick={() => setShowFilters(!showFilters)}
          className="bg-gray-700 text-white px-4 py-2 rounded-md flex items-center space-x-2 hover:bg-gray-600 transition-colors duration-200"
        >
          <Globe size={18} />
          <span>{searchRegion === "ALL" ? "Todas las Regiones" : searchRegion}</span>
          <ChevronDown size={18} />
        </button>
      </div>

      {showFilters && (
        <div className="bg-gray-800 p-4 rounded-lg space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <select
              value={searchRegion}
              onChange={(e) => setSearchRegion(e.target.value)}
              className="bg-gray-700 text-white border border-gray-600 rounded p-2 cursor-pointer"
            >
              <option value="ALL">Todas las Regiones</option>
              <option value="LAN">LAN</option>
              <option value="LAS">LAS</option>
              <option value="BR">BR</option>
              <option value="NA">NA</option>
            </select>

            <select
              value={searchElo}
              onChange={(e) => setSearchElo(e.target.value)}
              className="bg-gray-700 text-white border border-gray-600 rounded p-2 cursor-pointer"
            >
              <option value="ALL">All Elos</option>
              <option value="10.00-11.00">Challenger</option>
              <option value="9.00-9.99">Grandmaster</option>
              <option value="8.00-8.99">Master</option>
              <option value="7.00-7.99">Diamond</option>
              <option value="6.00-6.99">Emerald</option>
              <option value="5.00-5.99">Platinum</option>
              <option value="4.00-4.99">Gold</option>
              <option value="3.00-3.99">Silver</option>
              <option value="2.00-2.99">Bronze</option>
              <option value="1.00-1.99">Iron</option>
            </select>

            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="bg-gray-700 text-white border border-gray-600 rounded p-2 cursor-pointer"
              placeholder="Desde"
            />

            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="bg-gray-700 text-white border border-gray-600 rounded p-2 cursor-pointer"
              placeholder="Hacia"
            />
          </div>

          <button
            onClick={() => {
              setSearchRegion("ALL");
              setSearchElo("ALL");
              setStartDate("");
              setEndDate("");
              setSearchQuery("");
            }}
            className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition-colors duration-300"
          >
            Reset Filters
          </button>
        </div>
      )}

      <div className="space-y-2">
        <ScrimListHeading />
        {filteredScrims.map((scrim) => (
          <ScrimCard
            key={scrim.id}
            userData={userData}
            scrim={scrim}
            handleAcceptClick={() => handleAcceptClick(scrim)}
            isCaptain={isCaptain}
          />
        ))}
      </div>

      <ScrimModal
        title="Scrim"
        isOpen={!!selectedScrim}
        onClose={() => setSelectedScrim(null)}
        onClick={handleSendInvitation}
        className="bg-gray-800 p-6 rounded-xl max-w-2xl w-full mx-auto"
      >
        <div className="space-y-6 text-white">
          <div className="grid grid-cols-2 gap-4 text-sm border border-blue-500 p-4 rounded">
            <div>Equipo: {teamDetails?.name}</div>
            <div>Region: {teamDetails?.region}</div>
            <div>Capitan: {teamDetails?.captain[0]}</div>
            <div>Fecha: {selectedScrim?.date}</div>
            <div>Hora: {selectedScrim?.time}</div>
          </div>

          <div>
            <h3 className="text-lg font-bold mb-2">Jugadores del equipo enemigo:</h3>
            <div className="grid grid-cols-2 gap-4">
              {teamDetails?.members.map((member) => (
                <div key={member.id} className="p-4 border border-gray-600 rounded">
                  <p className="font-bold mb-1 text-sm">Discord: {member.discord_tag}</p>
                  <p className="text-xs">Summoner: {member.soloq_account__summonerName}</p>
                </div>
              ))}
            </div>
          </div>

          <div>
            <h3 className="text-lg font-bold mb-2">Selecciona tus jugadores:</h3>
            <div className="grid grid-cols-2 gap-4">
              {teamMembers.map((member) => (
                <label key={member.id} className="flex items-center p-4 border border-gray-600 rounded cursor-pointer">
                  <input
                    type="checkbox"
                    checked={selectedPlayers.includes(member.id)}
                    onChange={() => handlePlayerSelection(member.id)}
                    className="mr-2"
                  />
                  <div>
                    <p className="font-bold mb-1 text-sm">Discord: {member.discord_tag}</p>
                    <p className="text-xs">Summoner: {member.soloq_account__summonerName}</p>
                  </div>
                </label>
              ))}
            </div>
          </div>

          <div>
            <h3 className="text-lg font-bold mb-2">Comentarios:</h3>
            <textarea
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              className="w-full p-2 border rounded bg-gray-700 text-white"
              placeholder="Escribe tu comentario aquí..."
              rows="4"
            ></textarea>
          </div>
        </div>
      </ScrimModal>

      <MessageModal
        isOpen={messageModalOpen}
        onClose={() => {
          messageModalContent === "Solicitud de scrim enviada exitosamente"
            ? closeModalAndNavigate("/league/scrims/invitations-sent")
            : closeModalAndNavigate("/league/scrims");
        }}
      >
        {messageModalContent}
      </MessageModal>
    </div>
  );
};

export default ScrimList;