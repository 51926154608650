import React, { useState, useCallback, useMemo } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import Images from "../../components/ImagesContainer";
import Flag from "react-world-flags";
import {
  challenger,
  grandmaster,
  master,
  diamond,
  emerald,
  platinum,
  gold,
  silver,
  bronce,
  iron,
  unranked
} from "../../components/AssetsBarrel";
import { ChevronDown, ChevronUp, Calendar, Clock } from "lucide-react";
import { PlayerCard } from "../../components/PlayerCard";
import CardLoader from "../../components/CardLoader";
import { DateTime } from "luxon";

const ScrimCard = ({ userData, scrim, handleAcceptClick, isCaptain }) => {
  const [expanded, setExpanded] = useState(false);
  const [teamData, setTeamData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const goToTeamPage = useCallback((teamName) => {
    navigate(`/league/equipos/${teamName}`);
  }, [navigate]);

  const fetchTeamData = useCallback(async () => {
    setIsLoading(true);
    try {
      const [teamResponse, scrimPlayersResponse] = await Promise.all([
        fetch(`/api/team/${scrim.team}`),
        Axios.get(`/api/scrim-request-players/${scrim.id}/`)
      ]);

      const teamData = await teamResponse.json();
      const scrimPlayers = scrimPlayersResponse.data.map((player) => player.id);

      const filteredMembers = teamData.members.filter((member) =>
        scrimPlayers.includes(member.id)
      );
      
      setTeamData({ ...teamData, members: filteredMembers });
    } catch (error) {
      console.error("Error fetching team data:", error);
    } finally {
      setIsLoading(false);
    }
  }, [scrim.team, scrim.id]);

  const handleChevronClick = useCallback(() => {
    if (!expanded && !teamData) {
      fetchTeamData();
    }
    setExpanded((prev) => !prev);
  }, [expanded, teamData, fetchTeamData]);

  const getPlayerType = useCallback((discordTag, captain, subcaptains, staff) => {
    if (discordTag === captain) return "captain";
    if (subcaptains.includes(discordTag)) return "subcaptain";
    if (staff.includes(discordTag)) return "staff";
    return "player";
  }, []);

  const renderPlayerCards = useMemo(() => {
    if (!teamData || !teamData.members) return null;

    const members = teamData.members.filter((member) =>
      teamData.players.includes(member.discord_tag)
    );
    const captain = teamData.captain && teamData.captain[0];
    const subcaptains = teamData.subcaptains;
    console.log(members)

    return members.map((member, index) => (
      <PlayerCard
        key={index}
        member={getPlayerType(member.discord_tag, captain, subcaptains, teamData.staff)}
        name={member.discord_tag}
        role={member.role}
        region={member.region}
        soloq_account={member.soloq_account__summonerName}
        elo={`${member.soloq_account__tier} ${member.soloq_account__rank}`}
        tier={member.soloq_account__tier}
        rank={member.soloq_account__rank}
        lp={member.soloq_account__leaguePoints}
        active={member.is_active}
        summoner={member.soloq_account__summonerName}
        user_id={member.user_id}
        avatar_id={member.avatar_id}
        coins={member.coins}
      />
    ));
  }, [teamData, getPlayerType]);

  const getRankImage = useCallback((rating) => {
    if (rating >= 10.0) return challenger;
    if (rating >= 9.0) return grandmaster;
    if (rating >= 8.0) return master;
    if (rating >= 7.0) return diamond;
    if (rating >= 6.0) return emerald;
    if (rating >= 5.0) return platinum;
    if (rating >= 4.0) return gold;
    if (rating >= 3.0) return silver;
    if (rating >= 2.0) return bronce;
    return iron;
  }, []);

  const convertDateTime = useCallback((date, time, fromZone, toZone) => {
    return DateTime.fromFormat(`${date} ${time}`, "yyyy-MM-dd HH:mm:ss", { zone: fromZone })
      .setZone(toZone);
  }, []);

  const mexicoDateTime = useMemo(() => 
    convertDateTime(scrim.date, scrim.time, "America/Mexico_City", "America/Mexico_City"),
    [scrim.date, scrim.time, convertDateTime]
  );

  const argentinaDateTime = useMemo(() => 
    convertDateTime(scrim.date, scrim.time, "America/Mexico_City", "America/Argentina/Buenos_Aires"),
    [scrim.date, scrim.time, convertDateTime]
  );

  return (
    <div className="bg-gray-800 rounded-lg text-white text-xs lg:text-sm hover:bg-gray-700 transition-colors">
      <div className="grid grid-cols-1 md:grid-cols-6 items-center gap-2 py-3 px-4">
        <div className="flex items-center space-x-3">
          <img
            src={Images[scrim.team_image]}
            alt="Team Logo"
            className="w-10 h-10 rounded-full"
          />
          <button
            onClick={() => goToTeamPage(scrim.team)}
            className="font-medium truncate max-w-[100px] hover:underline hover:text-rose-500"
          >
            {scrim.team}
          </button>
        </div>

        <div className="flex flex-col items-center justify-center">
          <img src={getRankImage(parseFloat(scrim.average_rating))} alt="Average Rank" className="w-8 h-8 mb-1" />
          <span className="text-gray-400">{scrim.average_rating}</span>
        </div>

        <div className="flex flex-col items-center justify-center space-y-1">
          <div className="flex items-center space-x-1">
            <Calendar className="w-4 h-4 text-gray-400" />
            <span>{mexicoDateTime.toFormat('dd/MM/yyyy')}</span>
            <Flag code="mx" width={16} />
          </div>
          <div className="flex items-center space-x-1">
            <Calendar className="w-4 h-4 text-gray-400" />
            <span>{argentinaDateTime.toFormat('dd/MM/yyyy')}</span>
            <Flag code="ar" width={16} />
          </div>
        </div>

        <div className="flex flex-col items-center justify-center space-y-1">
          <div className="flex items-center space-x-1">
            <Clock className="w-4 h-4 text-gray-400" />
            <span>{mexicoDateTime.toFormat('HH:mm')}</span>
            <Flag code="mx" width={16} />
          </div>
          <div className="flex items-center space-x-1">
            <Clock className="w-4 h-4 text-gray-400" />
            <span>{argentinaDateTime.toFormat('HH:mm')}</span>
            <Flag code="ar" width={16} />
          </div>
        </div>

        <div className="flex items-center justify-center">
          <span className={scrim.status === "PENDIENTE" ? "text-yellow-500" : "text-green-500"}>
            {scrim.status}
          </span>
        </div>

        <div className="flex justify-end items-center">
          {isCaptain() && handleAcceptClick && (
            <button
              className={`px-4 py-2 rounded-md text-xs lg:text-sm font-medium transition-colors
                ${scrim.team === userData.team_info.team_name
                  ? "bg-gray-600 text-gray-400 cursor-not-allowed"
                  : "bg-blue-600 text-white hover:bg-blue-700"
                }`}
              onClick={() =>
                scrim.team !== userData.team_info.team_name && handleAcceptClick(scrim)
              }
              disabled={scrim.team === userData.team_info.team_name}
            >
              {scrim.team === userData.team_info.team_name ? "Tu scrim" : "Invitar"}
            </button>
          )}
          <button
            onClick={handleChevronClick}
            className="ml-2 text-gray-400 hover:text-white transition-colors"
            aria-expanded={expanded}
            aria-label={expanded ? "Collapse details" : "Expand details"}
          >
            {expanded ? (
              <ChevronUp className="w-6 h-6" />
            ) : (
              <ChevronDown className="w-6 h-6" />
            )}
          </button>
        </div>
      </div>

      {expanded && (
        <div className="border-t border-gray-700 mt-2 pt-4 px-4 pb-4">
          <p className="text-gray-400 mb-4">{scrim.comments}</p>
          {isLoading ? (
            <CardLoader />
          ) : (
            <div className="space-y-2 mb-4">
              {renderPlayerCards}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ScrimCard;