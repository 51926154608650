import React, { useState } from "react";
import { SiRiotgames } from "react-icons/si";
import { AlertTriangle } from "lucide-react";
import {
  challenger,
  grandmaster,
  master,
  diamond,
  emerald,
  platinum,
  gold,
  silver,
  bronce,
  iron,
  unranked,
} from "../../components/AssetsBarrel";

const Verify = ({ check, user, data }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState("LAN");
  const [
    isUnlinkConfirmationModalVisible,
    setIsUnlinkConfirmationModalVisible,
  ] = useState(false);
  const [resultModalVisible, setResultModalVisible] = useState(false);
  const [resultMessage, setResultMessage] = useState("");

  const openModal = () => setIsModalVisible(true);
  const closeModal = () => setIsModalVisible(false);

  const handleRegionChange = (event) => setSelectedRegion(event.target.value);

  const isDefaultSoloqAccount =
    data?.user_profile?.soloq_account == null ||
    data?.user_profile?.soloq_account === "default_soloq_account_id";

  const handleLinkRiotAccount = () => {
    const serverMap = {
      BR: "br1",
      NA: "na1",
      LAN: "la1",
      LAS: "la2",
      EUW: "euw1",
    };
    const server = serverMap[selectedRegion] || "euw1";
    window.location.href = `https://www.autoboost.gg/auth/riot/?server=${server}`;
  };

  const openUnlinkConfirmationModal = () =>
    setIsUnlinkConfirmationModalVisible(true);

  const handleUnlinkAccount = async () => {
    try {
      const response = await fetch("/unlink-soloq/", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      });

      const responseData = await response.json();

      setResultMessage(
        response.status === 200
          ? "La cuenta de SoloQ ha sido desvinculada."
          : "Error al desvincular la cuenta de SoloQ."
      );

      setResultModalVisible(true);
      setIsModalVisible(false);
      setIsUnlinkConfirmationModalVisible(false);
    } catch (error) {
      console.error("Error al procesar la solicitud:", error);
    }
  };

  const closeTotalModal = () => {
    setIsModalVisible(false);
    setIsUnlinkConfirmationModalVisible(false);
    setResultModalVisible(false);
  };

  const reloadPage = () => window.location.reload();

  const closeUnlinkConfirmModal = () =>
    setIsUnlinkConfirmationModalVisible(false);

  const ResultModal = () => (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-75">
      <div className="bg-fifth p-8 rounded-xl shadow-lg max-w-md w-full">
        <h3 className="text-2xl font-bold text-white mb-4 text-center">
          Aviso
        </h3>
        <p className="text-gray-300 text-center mb-6">{resultMessage}</p>
        <div className="text-center">
          <button
            className="bg-primary text-white font-bold py-2 px-4 rounded-lg hover:bg-opacity-90 transition-colors"
            onClick={reloadPage}
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  );

  const tierImages = {
    CHALLENGER: challenger,
    GRANDMASTER: grandmaster,
    MASTER: master,
    DIAMOND: diamond,
    EMERALD: emerald,
    PLATINUM: platinum,
    GOLD: gold,
    SILVER: silver,
    BRONZE: bronce,
    IRON: iron,
    UNRANKED: unranked,
    Unranked: unranked,
  };

  return (
    <div>
      <button
        className="riot-button rounded-lg bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 transition-colors flex items-center"
        onClick={openModal}
      >
        <SiRiotgames className="mr-2" />
        {user.verified ? "Manage your Riot account" : "Link Riot Account"}
      </button>

      {isModalVisible && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-75">
          <div className="bg-gray-800 p-8 rounded-xl shadow-lg max-w-md w-full">
            {isDefaultSoloqAccount ? (
              <>
                <h3 className="text-2xl font-bold text-white mb-4 text-center">
                  Link a Riot account
                </h3>
                <p className="text-gray-300 text-center mb-6">
                  Link a new Riot account to continue.
                </p>
                <div className="flex justify-center items-center mb-6">
                  <label className="text-white mr-2">Select the region:</label>
                  <select
                    className="bg-gray-700 text-white border-2 border-yellow-500 rounded-md p-2"
                    value={selectedRegion}
                    onChange={handleRegionChange}
                  >
                    <option value="BR">BR</option>
                    <option value="NA">NA</option>
                    <option value="LAN">LAN</option>
                    <option value="LAS">LAS</option>
                    <option value="EUW">EUW</option>
                  </select>
                </div>
                <div className="flex justify-center space-x-4">
                  <button
                    className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg transition-colors"
                    onClick={handleLinkRiotAccount}
                  >
                    Link
                  </button>
                  <button
                    className="bg-primary hover:bg-opacity-90 text-white font-bold py-2 px-4 rounded-lg transition-colors"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                </div>
              </>
            ) : (
              <>
                <h3 className="text-2xl font-bold text-white mb-4 text-center">
                  Manage your Riot account
                </h3>
                <p className="text-gray-300 text-center mb-2">
                  The following account is linked to your profile:
                </p>
                <p className="text-yellow-500 font-bold text-center text-xl mb-6">
                  {data?.soloq_data?.summonerName}
                </p>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 mb-6">
                  <div className="flex flex-col items-center">
                    <img
                      src={`https://ddragon.leagueoflegends.com/cdn/14.23.1/img/profileicon/${data?.soloq_data?.profileIconId}.png`}
                      alt="Profile Icon"
                      className="w-24 h-24 rounded-full border-4 border-yellow-500"
                    />
                    <span className="bg-yellow-500 text-gray-900 font-bold px-2 py-1 rounded-full -mt-4">
                      {data?.soloq_data?.summonerLevel}
                    </span>
                  </div>
                  <div className="flex flex-col items-center justify-center">
                    <img
                      src={tierImages[data?.soloq_data?.tier.toUpperCase()]}
                      alt="Tier Icon"
                      className="w-24 h-24 mb-2"
                    />
                    <p className="text-white text-center">
                      {data?.soloq_data?.tier.toUpperCase()}{" "}
                      {data?.soloq_data?.rank}
                    </p>
                    <p className="text-gray-300 text-center">
                      {data?.soloq_data?.leaguePoints} LP
                    </p>
                  </div>
                </div>
                <div className="flex justify-center space-x-4">
                  <button
                    className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg transition-colors"
                    onClick={openUnlinkConfirmationModal}
                  >
                    Unlink
                  </button>
                  <button
                    className="bg-primary hover:bg-opacity-90 text-white font-bold py-2 px-4 rounded-lg transition-colors"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {isUnlinkConfirmationModalVisible && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-75">
          <div className="bg-fifth p-8 rounded-xl shadow-lg max-w-md w-full">
            <div className="flex items-center justify-center mb-4">
              <AlertTriangle className="text-yellow-500 w-12 h-12" />
            </div>
            <h3 className="text-2xl font-bold text-white mb-4 text-center">
              Confirm Unlink
            </h3>
            <p className="text-gray-300 text-center mb-6">
              Are you sure you want to unlink the account:{" "}
              <span className="font-bold text-yellow-500">
                {data?.soloq_data?.summonerName}
              </span>
              ?
            </p>
            <div className="flex justify-center space-x-4">
              <button
                className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg transition-colors"
                onClick={handleUnlinkAccount}
              >
                Yes
              </button>
              <button
                className="bg-primary hover:bg-opacity-90 text-white font-bold py-2 px-4 rounded-lg transition-colors"
                onClick={closeUnlinkConfirmModal}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}

      {resultModalVisible && <ResultModal />}
    </div>
  );
};

export default Verify;
