import React, { useState, useEffect, useCallback, useMemo } from "react";
import Axios from "axios";
import TitleContainer from "../../components/TitleContainer";
import Container from "../../components/Container";
import { Swords } from "lucide-react";
import Loader from "../../components/Loader";
import CardsHeading from "../../components/CardsHeading";
import MyInvScrimCard from "./MyInvScrimCard";

const ScrimInvs = ({ userData }) => {
  const [invitations, setInvitations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isWorking, setIsWorking] = useState(false);

  const fetchInvitations = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await Axios.get("/api/my-scrims-invitations/");
      setInvitations(response.data);
    } catch (error) {
      console.error("Error fetching scrim invitations:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchInvitations();
  }, [fetchInvitations]);

  const isCaptainOrSubcaptain = useCallback(() => {
    return userData?.team_info?.is_captain || userData?.team_info?.is_subcaptain;
  }, [userData]);

  const handleAccept = useCallback(async (inviteId) => {
    setIsWorking(true);
    setIsLoading(true);
    try {
      await Axios.post("/api/accept-scrim-invitation/", { id: inviteId });
      await fetchInvitations();
    } catch (error) {
      console.error("Error accepting scrim invitation:", error);
    } finally {
      setIsWorking(false);
      setIsLoading(false);
    }
  }, [fetchInvitations]);

  const handleReject = useCallback(async (inviteId) => {
    setIsWorking(true);
    setIsLoading(true);
    try {
      await Axios.post("/api/reject-scrim-invitation/", { id: inviteId });
      await fetchInvitations();
    } catch (error) {
      console.error("Error rejecting scrim invitation:", error);
    } finally {
      setIsWorking(false);
      setIsLoading(false);
    }
  }, [fetchInvitations]);

  const getTitles = useMemo(() => {
    const baseTitles = ["Equipo", "Elo Promedio", "Comentarios"];
    return isCaptainOrSubcaptain() ? [...baseTitles, "Accion"] : baseTitles;
  }, [isCaptainOrSubcaptain]);

  if (isLoading || userData === undefined) {
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
        <Loader />
      </div>
    );
  }

  return (
    <Container>
      <div className="flex flex-col gap-4 mt-4">
        <TitleContainer
          text="Invitaciones para scrims recibidas"
          icon={<Swords className="w-6 h-6" />}
          color="bg-blue-600"
          flex="start"
        />
        <div className="space-y-4">
          {invitations.map((invite) => (
            <MyInvScrimCard
              key={invite.id}
              scrim={invite}
              handleAcceptClick={() => handleAccept(invite.id)}
              handleCancelClick={() => handleReject(invite.id)}
              isCaptain={isCaptainOrSubcaptain}
              isWorking={isWorking}
            />
          ))}
        </div>
      </div>
    </Container>
  );
};

export default ScrimInvs;