import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import ProfileHeader from "./ProfileHeader";
import SoloQInfo from "./SoloQInfo";
import TeamInfo from "./TeamInfo";
import MatchHistory from "./MatchHistory";

const UserProfile = () => {
  const [profileData, setProfileData] = useState(null);
  const [matchHistory, setMatchHistory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [gameVersion, setGameVersion] = useState(null);
  const { username } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [profileResponse, versionResponse] = await Promise.all([
          axios.get(`/api/userprofile/${username}/`),
          axios.get("https://ddragon.leagueoflegends.com/api/versions.json"),
        ]);

        setProfileData(profileResponse.data);
        setGameVersion(versionResponse.data[0]); // Set the latest version

        // Fetch match history only if puuid is not default_account_id
        if (profileResponse.data.soloQ_account.puuid !== "default_account_id") {
          const matchHistoryResponse = await axios.get(
            `/api/user-matches/${profileResponse.data.soloQ_account.puuid}/`
          );
          setMatchHistory(matchHistoryResponse.data.matches);
        }

        setLoading(false);
      } catch (err) {
        setError("Failed to load user profile, match history, or game version");
        setLoading(false);
      }
    };

    fetchData();
  }, [username]);

  const getTopChampions = () => {
    if (!matchHistory) return [];

    const championCounts = matchHistory.reduce((acc, match) => {
      acc[match.champion] = (acc[match.champion] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(championCounts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 3)
      .map(([champion]) => champion);
  };

  const getMostPlayedChampion = () => {
    const topChampions = getTopChampions();
    return topChampions.length > 0 ? topChampions[0] : null;
  };

  if (loading)
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-yellow-500"></div>
      </div>
    );
  if (error)
    return (
      <div className="text-center text-red-500 text-2xl mt-8">{error}</div>
    );
  if (!profileData || !gameVersion)
    return (
      <div className="text-center text-white text-2xl mt-8">
        No profile data or game version found
      </div>
    );

  const mostPlayedChampion = getMostPlayedChampion();
  const topChampions = getTopChampions();

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-gray-800 rounded-lg shadow-lg overflow-hidden">
        <ProfileHeader
          userProfile={profileData.user_profile}
          mostPlayedChampion={mostPlayedChampion}
          topChampions={topChampions}
          gameVersion={gameVersion}
          selected_scrim_card={profileData.selected_scrim_card}
          selected_soloq_card={profileData.selected_soloq_card}
        />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 p-6">
          <SoloQInfo soloQAccount={profileData.soloQ_account} />
          <TeamInfo
            team={profileData.team}
            teamImage={profileData.team_image}
          />
        </div>
        {profileData.soloQ_account.puuid === "default_account_id" ? (
          <div className="text-center text-white text-xl p-6">
            No recent matches available
          </div>
        ) : (
          <MatchHistory
            puuid={profileData.soloQ_account.puuid}
            initialMatches={matchHistory}
            gameVersion={gameVersion}
            is_private={profileData.private_profile}
          />
        )}
      </div>
    </div>
  );
};

export default UserProfile;
