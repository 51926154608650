import React from "react";

const InvitationList = ({ list, onAccept, onReject }) => {
  return (
    <div className="flex flex-col gap-2">
      <table className="min-w-full bg-gray-300 text-xs rounded-md">
        <thead>
          <tr>
            <th className="text-left py-3 px-4 uppercase font-semibold">ID</th>
            <th className="text-left py-3 px-4 uppercase font-semibold">
              Status
            </th>
            <th className="text-left py-3 px-4 uppercase font-semibold">
              Team
            </th>
            <th className="text-left py-3 px-4 uppercase font-semibold">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="text-gray-700">
          {list.map((invitation, index) => (
            <tr key={index}>
              <td className="text-left py-3 px-4">{invitation.id}</td>
              <td className="text-left py-3 px-4">{invitation.status}</td>
              <td className="text-left py-3 px-4">{invitation.team}</td>
              <td className="text-left py-3 px-4">
                <button
                  onClick={() => onAccept(invitation.id)}
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded mr-2"
                >
                  Accept
                </button>
                <button
                  onClick={() => onReject(invitation.id)}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                >
                  Reject
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default InvitationList;
