import React, { useState, useEffect, useRef } from "react";
import { Menu, X } from 'lucide-react';
import Logo from "./Logo";
import User from "./User";
import Button from "../Button";
import NavLink from "./NavLink";
import Loader2 from "../Loader2";

const Navbar = ({ userData, isUserLoggedIn, user, setUserData }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [showLoginButton, setShowLoginButton] = useState(false);
  const [loading, setLoading] = useState(true);
  const mobileMenuRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
        setIsMobileMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, []);

  useEffect(() => {
    const loginTimer = setTimeout(() => {
      if (!isUserLoggedIn && loading) {
        setLoading(false);
        setShowLoginButton(true);
      }
    }, 3500);
    return () => clearTimeout(loginTimer);
  }, [isUserLoggedIn, loading]);

  useEffect(() => {
    if (user !== null) {
      setLoading(false);
    }
  }, [userData, user]);

  const navItems = [
    { name: "Teams", link: "/league/equipos" },
    { name: "Ranking", link: "/league/equipos/rankings" },
    { name: "Recruitment", link: "/league/free-agents" },
    { name: "Transfers", link: "/league/transferencias" },
    { name: "Scrim Wars", link: "/league/scrims" },
    { name: "Shop", link: "/shop" },
    { name: "Tournaments", link: "/tournaments" },
    { name: "Collection", link: "/mycollection" },

  ];

  return (
    <nav className="bg-indigo-800 py-4 text-white relative z-20">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center">
          <div className="w-1/4">
            <Logo />
          </div>
          
          <div className="hidden lg:flex justify-center space-x-6 w-1/2">
            {navItems.map((item) => (
              <NavLink key={item.name} link={item.link}>{item.name}</NavLink>
            ))}
          </div>
          
          <div className="w-1/4 flex justify-end items-center">
            <button className="lg:hidden mr-4" onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
              <Menu size={24} />
            </button>
            {loading ? (
              <Loader2 />
            ) : (
              <>
                {user !== null && userData !== null ? (
                  user.has_profile ? (
                    <User
                      data={userData}
                      user={user}
                      setUserData={setUserData}
                      coins={user.user_profile.coins}
                      blueCoins={user.blue_coins}

                    />
                  ) : (
                    <Button link="/login">Log in</Button>
                  )
                ) : (
                  <>
                    {showLoginButton ? (
                      <Button link="/login">Log in</Button>
                    ) : (
                      <Loader2 />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div ref={mobileMenuRef} className="fixed inset-y-0 left-0 w-64 bg-indigo-900 shadow-lg transform transition-transform duration-300 ease-in-out z-30">
          <div className="flex justify-between items-center p-4 border-b border-indigo-700">
            <Logo />
            <button onClick={() => setIsMobileMenuOpen(false)}>
              <X size={24} />
            </button>
          </div>
          <ul className="py-4">
            {navItems.map((item) => (
              <li key={item.name} className="px-4 py-2" onClick={() => setIsMobileMenuOpen(false)}>
                <NavLink link={item.link}>{item.name}</NavLink>
              </li>
            ))}
          </ul>
        </div>
      )}
    </nav>
  );
};

export default Navbar;