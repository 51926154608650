import React, { useEffect, useState } from "react";
import { Users } from 'lucide-react';
import TitleContainer from "../../components/TitleContainer";
import Container from "../../components/Container";
import AgentsList from "./AgentsList";
import axios from "axios";
const Index = ({ userData, user }) => {
  const [agents, setAgents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [invitations, setInvitations] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    axios
      .get("/api/free-agents")
      .then((response) => {
        setAgents(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error al obtener agents:", error);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    axios
      .get("/team-invite/pending/team/")
      .then((response) => {
        setInvitations(response.data.invites);
      })
      .catch((error) => {});
  }, []);

  return (
    <Container>
      <div className="flex flex-col gap-2 mt-4">
        <TitleContainer
          text="Players List"
          icon={<Users className="w-6 h-6 text-white" />}
          color="bg-blue-600"
          flex="start"
        />
        <AgentsList
          agents={agents}
          userData={userData}
          user={user}
          isLoading={isLoading}
          invitations={invitations}
        />
      </div>
    </Container>
  );
};

export default Index;
