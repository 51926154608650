import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Grid,
  Filter,
  ChevronLeft,
  ChevronRight,
  Search,
  Users,
  Briefcase,
  Flag,
} from "lucide-react";
import CardGrid from "./CardGrid";
import TitleContainer from "../../components/TitleContainer";

const CardCollection = () => {
  const [cards, setCards] = useState([]);
  const [cardType, setCardType] = useState("ALL");
  const [ratingFilter, setRatingFilter] = useState("ALL");
  const [championFilter, setChampionFilter] = useState("");
  const [roleFilter, setRoleFilter] = useState("ALL");
  const [countryFilter, setCountryFilter] = useState("ALL");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const fetchCards = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/user/cards/", {
        params: { card_type: cardType !== "ALL" ? cardType : undefined },
      });
      setCards(response.data.cards);
    } catch (error) {
      console.error("Error fetching cards:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCards();
  }, [cardType]);

  const uniqueRoles = [...new Set(cards.flatMap((card) => card.roles))];
  const uniqueCountries = [...new Set(cards.map((card) => card.country))];

  const filteredCards = cards.filter((card) => {
    if (ratingFilter !== "ALL" && card.general_rating < parseInt(ratingFilter))
      return false;
    if (
      championFilter &&
      !card.champions.some((champ) =>
        champ.toLowerCase().includes(championFilter.toLowerCase())
      )
    )
      return false;
    if (roleFilter !== "ALL" && !card.roles.includes(roleFilter)) return false;
    if (countryFilter !== "ALL" && card.country !== countryFilter) return false;
    return true;
  });

  const itemsPerPage = 12;
  const pageCount = Math.ceil(filteredCards.length / itemsPerPage);
  const currentCards = filteredCards.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="container mx-auto px-4 py-8">
      <TitleContainer
        text="Mi Colección de Cartas"
        icon={<Briefcase className="w-6 h-6 text-white" />}
        color="bg-blue-600"
        flex="start"
      />

      <div className="flex flex-col gap-4 bg-gray-900 p-6 rounded-lg">
        <p className="text-white text-sm mx-4">
          To change your profile Scrim Card, you can select it on this page.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 mb-6">
          <div className="relative">
            <input
              className="w-full bg-gray-800 text-white border border-gray-700 rounded-lg py-2 px-4 pl-10 focus:outline-none focus:ring-2 focus:ring-blue-600"
              type="text"
              placeholder="Buscar Campeón"
              value={championFilter}
              onChange={(e) => setChampionFilter(e.target.value)}
            />
            <Search
              className="absolute left-3 top-2.5 text-gray-400"
              size={20}
            />
          </div>
          <div className="relative">
            <select
              value={cardType}
              onChange={(e) => setCardType(e.target.value)}
              className="appearance-none w-full bg-gray-800 text-white border border-gray-700 rounded-lg py-2 px-4 pr-8 focus:outline-none focus:ring-2 focus:ring-blue-600"
            >
              <option value="ALL">All Types</option>
              <option value="Scrim">Scrim</option>
              <option value="SoloQ">SoloQ</option>
            </select>
            <Grid
              className="absolute right-3 top-2.5 text-gray-400 pointer-events-none"
              size={20}
            />
          </div>
          <div className="relative">
            <select
              value={roleFilter}
              onChange={(e) => setRoleFilter(e.target.value)}
              className="appearance-none w-full bg-gray-800 text-white border border-gray-700 rounded-lg py-2 px-4 pr-8 focus:outline-none focus:ring-2 focus:ring-blue-600"
            >
              <option value="ALL">All Roles</option>
              {uniqueRoles.map((role) => (
                <option key={role} value={role}>
                  {role}
                </option>
              ))}
            </select>
            <Users
              className="absolute right-3 top-2.5 text-gray-400 pointer-events-none"
              size={20}
            />
          </div>
          <div className="relative">
            <select
              value={countryFilter}
              onChange={(e) => setCountryFilter(e.target.value)}
              className="appearance-none w-full bg-gray-800 text-white border border-gray-700 rounded-lg py-2 px-4 pr-8 focus:outline-none focus:ring-2 focus:ring-blue-600"
            >
              <option value="ALL">All Countries</option>
              {uniqueCountries.map((country) => (
                <option key={country} value={country}>
                  {country}
                </option>
              ))}
            </select>
            <Flag
              className="absolute right-3 top-2.5 text-gray-400 pointer-events-none"
              size={20}
            />
          </div>
          <div className="relative">
            <select
              value={ratingFilter}
              onChange={(e) => setRatingFilter(e.target.value)}
              className="appearance-none w-full bg-gray-800 text-white border border-gray-700 rounded-lg py-2 px-4 pr-8 focus:outline-none focus:ring-2 focus:ring-blue-600"
            >
              <option value="ALL">All Ratings</option>
              <option value="70">70+</option>
              <option value="80">80+</option>
              <option value="90">90+</option>
            </select>
            <Filter
              className="absolute right-3 top-2.5 text-gray-400 pointer-events-none"
              size={20}
            />
          </div>
        </div>

        {loading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-yellow-500"></div>
          </div>
        ) : (
          <>
            <CardGrid cards={currentCards} />

            {pageCount > 1 && (
              <div className="flex justify-center items-center mt-8 space-x-4">
                <button
                  onClick={() =>
                    setCurrentPage((prev) => Math.max(prev - 1, 1))
                  }
                  disabled={currentPage === 1}
                  className="p-2 bg-gray-700 rounded-full disabled:opacity-50 text-white"
                >
                  <ChevronLeft size={24} />
                </button>
                <span className="text-white">
                  Page {currentPage} of {pageCount}
                </span>
                <button
                  onClick={() =>
                    setCurrentPage((prev) => Math.min(prev + 1, pageCount))
                  }
                  disabled={currentPage === pageCount}
                  className="p-2 bg-gray-700 rounded-full disabled:opacity-50 text-white"
                >
                  <ChevronRight size={24} />
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CardCollection;
