import React from "react";
import { Coins, HelpCircle } from 'lucide-react';
import { SiMcdonalds } from "react-icons/si";
import {
  top, jungler, mid, adc, support, coach, analyst,
  challenger, grandmaster, master, diamond, emerald, platinum, gold, silver, bronce, iron,
  LAN, LAS,
  unranked,
} from "../../components/AssetsBarrel";
import Images from "../../components/ImagesContainer";
import ReactCountryFlag from "react-country-flag";

const AgentCard = ({
  name,
  id,
  agentId,
  avatar,
  role,
  region,
  tier,
  rank,
  lp,
  summoner,
  coins,
  invitationStatus,
  onClick,
  userData,
  invitations,
  teamName,
  teamImage,
  country,
  receivingOffers,
}) => {
  const tierImages = {
    CHALLENGER: challenger, GRANDMASTER: grandmaster, MASTER: master,
    DIAMOND: diamond, EMERALD: emerald, PLATINUM: platinum,
    GOLD: gold, SILVER: silver, BRONZE: bronce, IRON: iron, UNRANKED: unranked, Unranked: unranked
  };

  const roleImages = { Top: top, Jungler: jungler, Mid: mid, ADC: adc, Support: support, Coach: coach, Analyst: analyst };
  const regionImages = { LAN, LAS };
  
  if (region !== "LAN" && region !== "LAS") {
    region = "LAN";
  }
  
  const createOpggUrl = (region, summonerName) => {
    // Convertir la región a minúsculas
    const lowerCaseRegion = region.toLowerCase();
  
    // Reemplazar '#' por '-' en el nombre del invocador
    const formattedSummonerName = summonerName.replace('#', '-');
  
    // Construir la URL de OPGG
    const opggUrl = `https://www.op.gg/summoners/${lowerCaseRegion}/${encodeURIComponent(formattedSummonerName)}`;
  
    return opggUrl;
  };
  
  const opggUrl = createOpggUrl(region, summoner);


  const isCaptain = () => {
    if (userData && userData.in_team !== undefined) {
      return userData.in_team && (userData.team_info.is_captain || userData.team_info.is_subcaptain);
    }
    return false;
  };

  const isInvited = invitations.some((invitation) => invitation.player_profile === agentId);

  const buttonText = (invitationStatus === "already_invited" || invitationStatus === "invited" || isInvited) ? "Ya invitado" : "Invitar";

  const renderTeam = () => {
    if (teamName) {
      const teamUrl = `/league/equipos/${encodeURIComponent(teamName)}`;
  
      return (
        <a href={teamUrl} className="flex items-center space-x-2">
          <img src={Images[teamImage]} alt={teamName} className="w-6 h-6 rounded-full" />
          <span className="text-xs truncate">{teamName}</span>
        </a>
      );
    } else {
      return (
        <div className="flex items-center space-x-2">
          <SiMcdonalds className="text-yellow-400 w-6 h-6" />
          <span className="text-xs">F/A</span>
        </div>
      );
    }
  };

  const renderCountry = () => {
    if (country && country !== "Desconocido") {
      return <ReactCountryFlag countryCode={country} svg style={{ width: '16px', height: '16px' }} />;
    } else {
      return <HelpCircle size={16} className="text-gray-400" />;
    }
  };

  return (
    <div className="flex flex-col md:flex-row items-start md:items-center justify-between py-3 px-4 bg-gray-800 rounded-lg text-white text-xs lg:text-sm hover:bg-gray-700 transition-colors">
      <div className="flex items-center space-x-3 w-full md:w-[20%] mb-2 md:mb-0">
        <a href={`/view/profile/${name}`} className="relative flex-shrink-0">
          <img
            src={`https://cdn.discordapp.com/avatars/${id}/${avatar}.png`}
            alt="Discord Avatar"
            className="w-10 h-10 rounded-full bg-gray-600"
            onError={(e) => { e.target.src = "https://i.redd.it/j3t4cvgywd051.png"; }}
          />
          <img
            src={roleImages[role]}
            alt={role}
            className="absolute -bottom-1 -right-1 w-5 h-5 rounded-full bg-gray-800 p-0.5"
          />
        </a>
        <div className="flex flex-col">
          <a href={`/view/profile/${name}`} className="font-medium truncate max-w-[100px] hover:underline">{name}</a>
          <div className="flex items-center space-x-1">
            {renderCountry()}
            <span className="text-xs text-gray-400">{country !== "Desconocido" ? country : "Unknown"}</span>
          </div>
        </div>
      </div>

      <div className="flex items-center space-x-2 w-full md:w-[10%] mb-2 md:mb-0">
        <img src={regionImages[region]} alt={region} className="w-6 h-6" />
        <span>{region}</span>
      </div>

      <div className="flex items-center space-x-2 w-full md:w-[20%] mb-2 md:mb-0 cursor-pointer" onClick={() => window.open(opggUrl, "_blank")}>
        <img src={tierImages[tier]} alt={tier} className="w-8 h-8 flex-shrink-0" />
        <div className="text-xs">
          <div className="font-semibold">{tier.charAt(0) + tier.slice(1).toLowerCase()} {rank}</div>
          <div className="text-gray-400">{lp} LP</div>
        </div>
      </div>

      <div className="flex items-center space-x-2 w-full md:w-[10%] mb-2 md:mb-0">
        <Coins className="w-5 h-5 text-yellow-500 flex-shrink-0" />
        <span>{coins}</span>
      </div>

      <div className="w-full md:w-[15%] mb-2 md:mb-0">
        {renderTeam()}
      </div>

      <div className="w-full md:w-[15%] mb-2 md:mb-0">
        <span className={receivingOffers ? "text-green-500" : "text-red-500"}>
          {receivingOffers ? "Recibiendo ofertas" : "No recibe ofertas"}
        </span>
      </div>

      <div className="w-full md:w-[10%] flex justify-end">
        <button
          onClick={() => onClick(id, name)}
          disabled={!isCaptain() || invitationStatus === "already_invited" || invitationStatus === "invited" || isInvited || !receivingOffers || teamName}
          className={`w-full md:w-24 px-4 py-2 rounded-md text-xs lg:text-sm font-medium transition-colors
            ${(!isCaptain() || invitationStatus === "already_invited" || invitationStatus === "invited" || isInvited || !receivingOffers || teamName)
              ? 'bg-gray-600 text-gray-400 cursor-not-allowed'
              : 'bg-blue-600 text-white hover:bg-blue-700'
            }`}
        >
          {teamName ? 'En equipo' : buttonText}
        </button>
      </div>
    </div>
  );
};

export default AgentCard;