import React from "react";
import { useNavigate } from "react-router-dom";

const NavLink = ({ link, children }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(link)}
      className="relative group cursor-pointer"
    >
      <span className="text-gray-300 hover:text-white transition-colors duration-200 ease-in-out">
        {children}
      </span>
      <span className="absolute inset-x-0 bottom-0 h-0.5 bg-blue-500 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-200 ease-in-out"></span>
    </div>
  );
};

export default NavLink;