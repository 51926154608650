import React from "react";
import Container from "../../components/Container";

const TermsOfService = () => {
  return (
    <div className="flex items-start justify-center">
      <Container className="flex flex-col items-center justify-center mt-20">
        <div className="text-4xl font-bold text-white text-center mb-8 mt-8">
          Terms of Service for Autoboost GG
        </div>
        <p className='text-white'>
          This document outlines the terms and conditions for the use of
          Autoboost.GG (the "Website") operated by Autoboost (the "Company"). By
          accessing this Website, you agree to comply with these terms and
          conditions.
        </p>

        <div className="mt-8">
          <h2 className="text-2xl font-semibold text-white">Acceptance of Terms:</h2>
          <p className='text-white'>
            By using this Website, you agree to be bound by these Terms of
            Service, all applicable laws, and regulations. If you do not agree
            with any of these terms, you are prohibited from using or accessing
            this Website.
          </p>
        </div>

        <div className="mt-8">
          <h2 className="text-2xl font-semibold text-white">Use License:</h2>
          <p className='text-white'>
            Permission is granted to temporarily access the materials on
            Autoboost.GG for personal, non-commercial transitory viewing only.
            This is the grant of a license, not a transfer of title.
          </p>
        </div>

        <div className="mt-8">
          <h2 className="text-2xl font-semibold text-white">User Conduct:</h2>
          <p className='text-white'>
            You agree to use the Website only for lawful purposes and in a way
            that does not infringe upon the rights of others or restrict or
            inhibit their use and enjoyment of the Website.
          </p>
        </div>

        <div className="mt-8">
          <h2 className="text-2xl font-semibold text-white">Intellectual Property Rights:</h2>
          <p className='text-white'>
            The content, logos, trademarks, and other materials on the Website
            are owned or licensed by the Company and are protected by
            intellectual property laws. You may not use, reproduce, or
            distribute any content from this Website without prior written
            permission.
          </p>
        </div>

        <div className="mt-8">
          <h2 className="text-2xl font-semibold text-white">Limitations:</h2>
          <p className='text-white'>
            The Company shall not be liable for any damages arising out of the
            use or inability to use the materials on Autoboost.GG, even if the
            Company or its authorized representative has been notified orally
            or in writing of the possibility of such damage.
          </p>
        </div>

        <div className="mt-8">
          <h2 className="text-2xl font-semibold text-white">Governing Law:</h2>
          <p className='text-white'>
            These terms and conditions are governed by and construed in
            accordance with the laws of México, and you irrevocably submit to
            the exclusive jurisdiction of the courts in that jurisdiction.
          </p>
        </div>

        <div className="mt-8">
          <h2 className="text-2xl font-semibold text-white">Modifications:</h2>
          <p className='text-white'>
            The Company reserves the right to revise these terms of service for
            its Website at any time without notice. By using this Website, you
            are agreeing to be bound by the then-current version of these Terms
            of Service.
          </p>
        </div>

        <div className="mt-8">
          <h2 className="text-2xl font-semibold text-white">Severability:</h2>
          <p className='text-white'>
            If any provision of these terms is found to be unenforceable or
            invalid under any applicable law, such unenforceability or
            invalidity shall not render these terms unenforceable or invalid as
            a whole.
          </p>
        </div>

        <div className="mt-8">
          <h2 className="text-2xl font-semibold text-white">By using this Website:</h2>
          <p className='text-white'>
            By using this Website, you signify your acceptance of these terms.
            If you do not agree to these terms, please refrain from using our
            Website.
          </p>
        </div>
      </Container>
    </div>
  );
};

export default TermsOfService;
