import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaBan, FaSearch, FaTimes } from "react-icons/fa";
import Loader from "../../components/Loader";

const BanChampionModal = ({ tournamentId, closeModal }) => {
  const [champions, setChampions] = useState([]);
  const [filteredChampions, setFilteredChampions] = useState([]);
  const [selectedChampion, setSelectedChampion] = useState("");
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [currentVersion, setCurrentVersion] = useState("");
  const [bannedChampions, setBannedChampions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoadingInitial, setIsLoadingInitial] = useState(true);
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [responseModalContent, setResponseModalContent] = useState("");

  useEffect(() => {
    async function fetchVersion() {
      try {
        const versionResponse = await axios.get(
          "https://ddragon.leagueoflegends.com/api/versions.json"
        );
        const versions = versionResponse.data;
        const firstVersion = versions[0];
        setCurrentVersion(firstVersion);
      } catch (error) {
        console.error("Error fetching version:", error);
      }
    }
    fetchVersion();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const bannedChampionsResponse = await axios.get(
          `api/tournament/${tournamentId}/rules`
        );

        const bannedChampionsData = bannedChampionsResponse.data.filter(
          (item) => item.banned && typeof item.banned === "string"
        );

        const bannedChampionNames = bannedChampionsData.map(
          (bannedItem) => bannedItem.banned
        );

        setBannedChampions(bannedChampionNames);

        const championsResponse = await axios.get(
          `https://ddragon.leagueoflegends.com/cdn/${currentVersion}/data/en_US/champion.json`
        );
        const championData = championsResponse.data.data;
        const championList = Object.keys(championData);

        const filteredChampions = championList.filter(
          (champion) => !bannedChampionNames.includes(champion)
        );

        setChampions(filteredChampions);
        setFilteredChampions(filteredChampions);

        setIsLoadingInitial(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    if (currentVersion) {
      fetchData();
    }
  }, [tournamentId, currentVersion]);

  const handleBanChampion = async (championName) => {
    try {
      setIsLoadingAction(true);

      const response = await axios.post("tournament/exchange/rule-ticket/", {
        tournament_id: tournamentId,
        ban_type: "campeón",
        ban_name: championName,
      });

      setResponseModalContent(response.data.detail);
      setShowResponseModal(true);
    } catch (error) {
      setResponseModalContent(
        error.response?.data?.detail || "Error al banear campeón"
      );
      setShowResponseModal(true);
    } finally {
      setIsLoadingAction(false);
    }
  };

  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);
    const filtered = champions
      .filter((champion) => champion.toLowerCase().includes(term))
      .filter((champion) => !bannedChampions.includes(champion));
    setFilteredChampions(filtered);
  };

  const closeModalHandler = () => {
    setShowResponseModal(false);
    closeModal();
    window.location.reload();
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-gray-800 p-6 rounded-lg shadow-xl border-2 border-yellow-500 text-white w-full max-w-2xl max-h-[90vh] overflow-hidden flex flex-col">
        <h2 className="text-2xl font-bold text-center mb-4 flex items-center justify-center">
          <FaBan className="mr-2" />
          Select a Champion to Ban
        </h2>
        <div className="relative mb-4">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearch}
            placeholder="Search champion..."
            className="bg-gray-700 text-white rounded-md px-3 py-2 w-full pl-10"
          />
          <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
        </div>
        <div className="flex-grow overflow-auto mb-4">
          {isLoadingInitial ? (
            <div className="flex items-center justify-center h-64">
              <Loader />
            </div>
          ) : (
            <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 gap-4">
              {filteredChampions.map((championKey, index) => (
                <button
                  key={index}
                  onClick={() => setSelectedChampion(championKey)}
                  className={`cursor-pointer relative text-center p-2 rounded-lg transition-all duration-200 ${
                    championKey === selectedChampion
                      ? "bg-yellow-500 bg-opacity-25 border-2 border-yellow-500"
                      : "bg-gray-700 hover:bg-gray-600"
                  }`}
                >
                  <img
                    src={`https://ddragon.leagueoflegends.com/cdn/${currentVersion}/img/champion/${championKey}.png`}
                    alt={championKey}
                    className="w-full h-auto object-contain rounded-md"
                  />
                  <p className="text-sm mt-2 truncate">{championKey}</p>
                </button>
              ))}
            </div>
          )}
        </div>
        <div className="flex justify-center space-x-4">
          <button
            className={`px-4 py-2 rounded-md font-bold text-sm sm:text-base transition-colors duration-200 ${
              selectedChampion
                ? "bg-yellow-500 hover:bg-yellow-600 text-gray-900"
                : "bg-gray-600 text-gray-400 cursor-not-allowed"
            }`}
            onClick={() => handleBanChampion(selectedChampion)}
            disabled={!selectedChampion}
          >
            Ban
          </button>
          <button
            className="px-4 py-2 rounded-md bg-red-500 hover:bg-red-600 text-white font-bold text-sm sm:text-base transition-colors duration-200"
            onClick={closeModal}
          >
            Cancel
          </button>
        </div>
      </div>

      {showResponseModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-gray-800 p-6 rounded-lg shadow-xl border-2 border-yellow-500 text-white max-w-md w-full">
            <h3 className="font-bold text-xl mb-4">New Tournament Rule</h3>
            <p className="mb-6">{responseModalContent}</p>
            <button
              onClick={closeModalHandler}
              className="w-full bg-yellow-500 hover:bg-yellow-600 text-gray-900 font-bold py-2 px-4 rounded-md transition-colors duration-200"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {isLoadingAction && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default BanChampionModal;