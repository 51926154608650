import React, { useState } from 'react';
import CardParticles from './CardParticles';
import CardPreviewModal from './CardPreviewModal';

const CardGrid = ({ cards }) => {
  const [hoveredCard, setHoveredCard] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-6">
        {cards.map((card) => (
          <div 
            key={card.id} 
            className="relative overflow-hidden rounded-lg transition-transform duration-300 hover:scale-105 cursor-pointer"
            onMouseEnter={() => setHoveredCard(card.id)}
            onMouseLeave={() => setHoveredCard(null)}
            onClick={() => setSelectedCard(card)}
          >
            {hoveredCard === card.id && (
              <div className="absolute inset-0 z-10 bg-black bg-opacity-30">
                <CardParticles />
              </div>
            )}
            <img 
              src={card.image} 
              alt={`Card ${card.id}`} 
              className="w-full aspect-[3/4] object-contain rounded-lg shadow-lg relativ"
            />
          </div>
        ))}
      </div>
      {selectedCard && (
        <CardPreviewModal 
          card={selectedCard} 
          onClose={() => setSelectedCard(null)} 
        />
      )}
    </>
  );
};

export default CardGrid;