import React, { useState, useEffect } from "react";
import axios from "axios";
import Images from "../../components/ImagesContainer";
import { Loader } from "../../components/LoaderUI";
import {
  Sword,
  Coins,
  Clock,
  Eye,
  Users,
  BarChart,
  ChevronLeft,
  ChevronRight,
  Shield,
  Heart,
  Flag,
  ChevronDown,
  ChevronUp,
} from "lucide-react";

const MatchHistory = ({ puuid, initialMatches, is_private }) => {
  const [scrimMatches, setScrimMatches] = useState([]);
  const [soloQMatches, setSoloQMatches] = useState(initialMatches || []);
  const [scrimStats, setScrimStats] = useState(null);
  const [loading, setLoading] = useState(!initialMatches);
  const [error, setError] = useState(null);
  const [gameVersion, setGameVersion] = useState("");
  const [activeTab, setActiveTab] = useState(is_private ? "soloQ" : "scrim");
  const [currentPage, setCurrentPage] = useState(1);
  const championsPerPage = 5;

  useEffect(() => {
    const fetchGameVersion = async () => {
      try {
        const response = await axios.get(
          "https://ddragon.leagueoflegends.com/api/versions.json"
        );
        setGameVersion(response.data[0]);
      } catch (err) {
        console.error("Failed to fetch game version:", err);
        setGameVersion("14.16.1");
      }
    };

    const fetchScrimMatches = async () => {
      if (!is_private) {
        try {
          const response = await axios.get(`/api/user-scrims/${puuid}/`);
          setScrimMatches(response.data.matches);
        } catch (err) {
          console.error("Failed to load scrim match history:", err);
        }
      }
    };

    const fetchSoloQMatches = async () => {
      if (!initialMatches) {
        try {
          const response = await axios.get(`/api/user-matches/${puuid}/`);
          setSoloQMatches(response.data.matches);
        } catch (err) {
          console.error("Failed to load soloQ match history:", err);
        }
      }
    };

    const fetchScrimStats = async () => {
      if (!is_private) {
        try {
          const response = await axios.get(`/get-player-stats/${puuid}/`);
          setScrimStats(response.data);
        } catch (err) {
          console.error("Failed to load scrim stats:", err);
        }
      }
    };

    fetchGameVersion();
    if (puuid) {
      fetchScrimMatches();
      fetchSoloQMatches();
      fetchScrimStats();
    }
    setLoading(false);
  }, [puuid, initialMatches, is_private]);

  if (!puuid) {
    return null;
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-yellow-500"></div>
          </div>
    );
  }

  if (error) {
    return <div className="text-center text-red-500">{error}</div>;
  }

  return (
    <div className="bg-gray-800 p-6 rounded-lg mt-8">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold text-white">Recent Matches</h2>
        {!is_private && (
          <div className="space-x-2">
            <button
              className={`px-4 py-2 rounded ${
                activeTab === "scrim"
                  ? "bg-blue-600 text-white"
                  : "bg-gray-600 text-gray-300"
              }`}
              onClick={() => setActiveTab("scrim")}
            >
              Scrim
            </button>
            <button
              className={`px-4 py-2 rounded ${
                activeTab === "soloQ"
                  ? "bg-blue-600 text-white"
                  : "bg-gray-600 text-gray-300"
              } ${
                soloQMatches.length === 0 ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={() => soloQMatches.length > 0 && setActiveTab("soloQ")}
              disabled={soloQMatches.length === 0}
            >
              SoloQ
            </button>
            <button
              className={`px-4 py-2 rounded ${
                activeTab === "scrimStats"
                  ? "bg-blue-600 text-white"
                  : "bg-gray-600 text-gray-300"
              }`}
              onClick={() => setActiveTab("scrimStats")}
            >
              Scrim Stats
            </button>
          </div>
        )}
      </div>
      <div className="space-y-4">
        {!is_private && activeTab === "scrim" &&
          (scrimMatches.length > 0 ? (
            scrimMatches.map((match, index) => (
              <ScrimMatchCard key={index} match={match} />
            ))
          ) : (
            <div className="text-center text-gray-300 py-8">
              This player has no recent games
            </div>
          ))}
        {activeTab === "soloQ" &&
          soloQMatches.map((match, index) => (
            <SoloQMatchCard
              key={index}
              match={match}
              gameVersion={gameVersion}
            />
          ))}
        {!is_private && activeTab === "scrimStats" && scrimStats && (
          <div className="flex flex-col lg:flex-row gap-6">
            <GeneralStats stats={scrimStats.general_stats} />
            <ChampionPool
              champions={scrimStats.champion_stats}
              gameVersion={gameVersion}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              championsPerPage={championsPerPage}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const ScrimMatchCard = ({ match }) => {
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div
      className={`bg-gray-700 p-4 rounded-lg flex items-center ${
        match.result === "Victory"
          ? "border-l-4 border-green-500"
          : "border-l-4 border-red-500"
      }`}
    >
      <div className="w-16 h-16 rounded-full overflow-hidden mr-4">
        <img
          src={Images[match.opponent_team.image] || "/placeholder.svg"}
          alt={match.opponent_team.name}
          className="w-full h-full object-cover"
        />
      </div>
      <div className="flex-grow">
        <div className="flex justify-between items-center mb-2">
          <span className="text-lg font-bold text-white">
            {match.opponent_team.name}
          </span>
          <span
            className={`font-bold ${
              match.result === "Victory" ? "text-green-500" : "text-red-500"
            }`}
          >
            {match.result}
          </span>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-gray-300">
            Scrim Match ID: {match.scrim_match}
          </span>
          <span className="text-gray-300">{formatDate(match.start_time)}</span>
        </div>
      </div>
    </div>
  );
};
const SoloQMatchCard = ({ match, gameVersion }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      className={`bg-gray-700 rounded-lg overflow-hidden ${
        match.win ? "border-l-4 border-green-500" : "border-l-4 border-red-500"
      }`}
    >
      <div className="p-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <div className="w-16 h-16 rounded-full overflow-hidden">
              <img
                src={`https://ddragon.leagueoflegends.com/cdn/${gameVersion}/img/champion/${match.champion}.png`}
                alt={match.champion}
                className="w-full h-full object-cover"
              />
            </div>
            <div>
              <h3 className="text-lg font-bold text-white">{match.champion}</h3>
              <p className="text-sm text-gray-300">
                {match.kills}/{match.deaths}/{match.assists} (
                {match.kda.toFixed(2)} KDA)
              </p>
              <p className="text-sm text-gray-300">
                {match.cs_per_min.toFixed(1)} CS/min
              </p>
            </div>
          </div>
          <div className="flex flex-col items-end">
            <div className="flex items-center space-x-1 mb-2">
              {match.items.slice(0, 7).map((item, index) => (
                <div
                  key={index}
                  className="w-8 h-8 bg-gray-800 rounded overflow-hidden"
                >
                  {item !== 0 && (
                    <img
                      src={`https://ddragon.leagueoflegends.com/cdn/${gameVersion}/img/item/${item}.png`}
                      alt={`Item ${item}`}
                      className="w-full h-full object-cover"
                    />
                  )}
                </div>
              ))}
            </div>
            <span
              className={`font-bold ${
                match.win ? "text-green-500" : "text-red-500"
              }`}
            >
              {match.win ? "Victory" : "Defeat"}
            </span>
          </div>
        </div>

        <button
          className="w-full mt-4 py-2 px-4 bg-gray-600 hover:bg-gray-500 text-white rounded flex items-center justify-center transition-colors duration-200"
          onClick={toggleExpand}
        >
          {isExpanded ? (
            <>
              Less info <ChevronUp className="ml-2 h-4 w-4" />
            </>
          ) : (
            <>
              More info <ChevronDown className="ml-2 h-4 w-4" />
            </>
          )}
        </button>

        {isExpanded && (
          <div className="mt-4 space-y-2 text-white">
            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-center">
                <Sword className="mr-2 h-4 w-4 text-red-400" />
                <span>Damage to Champions:</span>
                <span className="ml-auto">
                  {match.total_damage_dealt_to_champions}
                </span>
              </div>
              <div className="flex items-center">
                <Shield className="mr-2 h-4 w-4 text-blue-400" />
                <span>Total Damage Dealt:</span>
                <span className="ml-auto">{match.total_damage_dealt}</span>
              </div>
              <div className="flex items-center">
                <Heart className="mr-2 h-4 w-4 text-green-400" />
                <span>Total Healing:</span>
                <span className="ml-auto">{match.total_healing}</span>
              </div>
              <div className="flex items-center">
                <Eye className="mr-2 h-4 w-4 text-purple-400" />
                <span>Vision Score:</span>
                <span className="ml-auto">{match.vision_score}</span>
              </div>
              <div className="flex items-center">
                <Flag className="mr-2 h-4 w-4 text-yellow-400" />
                <span>Wards Placed/Killed:</span>
                <span className="ml-auto">
                  {match.wards_placed}/{match.wards_killed}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const GeneralStats = ({ stats }) => {
  return (
    <div className="bg-gray-700 p-6 rounded-lg w-full lg:w-1/2">
      <h3 className="text-2xl font-bold text-white mb-6">General Stats</h3>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
        <StatItem
          icon={<BarChart className="w-8 h-8 text-blue-400" />}
          label="Win Rate"
          value={`${(stats.Victory_rate * 100).toFixed(2)}%`}
        />
        <StatItem
          icon={<Clock className="w-8 h-8 text-green-400" />}
          label="Avg CS/min"
          value={stats.avg_cs_min.toFixed(2)}
        />
        <StatItem
          icon={<Sword className="w-8 h-8 text-red-400" />}
          label="Avg Damage/min"
          value={stats.avg_damage_min.toFixed(2)}
        />
        <StatItem
          icon={<Coins className="w-8 h-8 text-yellow-400" />}
          label="Avg Gold/min"
          value={stats.avg_gold_min.toFixed(2)}
        />
        <StatItem
          icon={<Eye className="w-8 h-8 text-purple-400" />}
          label="Avg Vision Score"
          value={stats.avg_vision_score.toFixed(2)}
        />
        <StatItem
          icon={<Users className="w-8 h-8 text-indigo-400" />}
          label="Avg Kill Participation"
          value={`${(stats.avg_kill_participation * 100).toFixed(2)}%`}
        />
        <StatItem
          icon={<Coins className="w-8 h-8 text-yellow-400" />}
          label="Avg Gold Share"
          value={`${(stats.avg_gold_share * 100).toFixed(2)}%`}
        />
        <StatItem
          icon={<Sword className="w-8 h-8 text-red-400" />}
          label="Avg Damage Share"
          value={`${(stats.avg_damage_share * 100).toFixed(2)}%`}
        />
      </div>
    </div>
  );
};

const StatItem = ({ icon, label, value }) => (
  <div className="flex items-center bg-gray-800 p-4 rounded-lg">
    {icon}
    <div className="ml-4">
      <span className="text-gray-400 text-sm block">{label}</span>
      <span className="text-white text-lg font-semibold">{value}</span>
    </div>
  </div>
);

const ChampionPool = ({
  champions,
  gameVersion,
  currentPage,
  setCurrentPage,
  championsPerPage,
}) => {
  const indexOfLastChampion = currentPage * championsPerPage;
  const indexOfFirstChampion = indexOfLastChampion - championsPerPage;
  const currentChampions = champions.slice(
    indexOfFirstChampion,
    indexOfLastChampion
  );
  const totalPages = Math.ceil(champions.length / championsPerPage);

  return (
    <div className="bg-gray-700 p-6 rounded-lg w-full lg:w-1/2">
      <h3 className="text-2xl font-bold text-white mb-6">Champion Pool</h3>
      <div className="space-y-4">
        {currentChampions.map((champion, index) => (
          <ChampionStatItem
            key={index}
            champion={champion}
            gameVersion={gameVersion}
          />
        ))}
      </div>
      <div className="flex justify-between items-center mt-6">
        <button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className="p-2 bg-gray-600 rounded-full disabled:opacity-50"
        >
          <ChevronLeft className="w-6 h-6 text-white" />
        </button>
        <span className="text-white">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() =>
            setCurrentPage((prev) => Math.min(prev + 1, totalPages))
          }
          disabled={currentPage === totalPages}
          className="p-2 bg-gray-600 rounded-full disabled:opacity-50"
        >
          <ChevronRight className="w-6 h-6 text-white" />
        </button>
      </div>
    </div>
  );
};

const ChampionStatItem = ({ champion, gameVersion }) => {
  const formatChampionName = (name) => {
    let formattedName = name.replace(/[^a-zA-Z\s]/g, "").replace(/\s+/g, "");

    if (name.includes("'")) {
      formattedName =
        formattedName.charAt(0).toUpperCase() +
        formattedName.slice(1).toLowerCase();
    }

    return formattedName;
  };

  return (
    <div className="bg-gray-800 p-3 rounded-lg flex items-center">
      <img
        src={`https://ddragon.leagueoflegends.com/cdn/${gameVersion}/img/champion/${formatChampionName(
          champion.champion
        )}.png`}
        alt={champion.champion}
        className="w-12 h-12 rounded-full mr-3"
      />
      <div className="flex-grow">
        <div className="flex justify-between items-center mb-1">
          <span className="text-base font-bold text-white">
            {champion.champion}
          </span>
          <span className="text-gray-400 text-xs">
            Games: {champion.games_played}
          </span>
        </div>
        <div className="grid grid-cols-2 gap-1 text-xs">
          <StatItemChampionPool
            icon={<BarChart className="w-4 h-4 text-blue-400" />}
            label="Win Rate"
            value={`${(champion.win_rate * 100).toFixed(2)}%`}
          />
          <StatItemChampionPool
            icon={<Clock className="w-4 h-4 text-green-400" />}
            label="CS/min"
            value={champion.avg_cs_min.toFixed(2)}
          />
          <StatItemChampionPool
            icon={<Sword className="w-4 h-4 text-red-400" />}
            label="Dmg/min"
            value={champion.avg_damage_min.toFixed(2)}
          />
          <StatItemChampionPool
            icon={<Coins className="w-4 h-4 text-yellow-400" />}
            label="Gold/min"
            value={champion.avg_gold_min.toFixed(2)}
          />
        </div>
      </div>
    </div>
  );
};

const StatItemChampionPool = ({ icon, label, value }) => (
  <div className="flex items-center">
    {icon}
    <div className="ml-1">
      <span className="text-gray-400">{label}:</span>
      <span className="text-white ml-1">{value}</span>
    </div>
  </div>
);

export default MatchHistory;
