import React from 'react';
import { getTierIcon } from './utils/iconHelpers';

const SoloQInfo = ({ soloQAccount }) => {
  if (!soloQAccount.accountId) {
    return (
      <div className="bg-gray-700 p-6 rounded-lg">
        <h2 className="text-xl font-bold text-white mb-4">SoloQ Account</h2>
        <p className="text-gray-300">No SoloQ account linked</p>
      </div>
    );
  }

  return (
    <div className="bg-gray-700 p-6 rounded-lg">
      <h2 className="text-xl font-bold text-white mb-4">SoloQ Account</h2>
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-2 flex items-center justify-center">
          <img
            src={`https://ddragon.leagueoflegends.com/cdn/14.23.1/img/profileicon/${soloQAccount.profileIconId}.png`}
            alt="Summoner Icon"
            className="w-24 h-24 rounded-full border-2 border-yellow-500"
          />
        </div>
        <div className="col-span-2 text-center">
          <p className="text-gray-400">Summoner Name</p>
          <p className="text-white text-xl font-bold">{soloQAccount.summonerName}</p>
        </div>
        <div className="text-center">
          <p className="text-gray-400">Summoner Level</p>
          <p className="text-white text-xl font-bold">{soloQAccount.summonerLevel}</p>
        </div>
        <div className="text-center">
          <p className="text-gray-400">Rank</p>
          <div className="flex items-center justify-center">
            <img src={getTierIcon(soloQAccount.tier)} alt="Tier" className="w-8 h-8 mr-2" />
            <p className="text-white text-xl font-bold">{soloQAccount.tier} {soloQAccount.rank}</p>
          </div>
        </div>
        <div className="text-center">
          <p className="text-gray-400">League Points</p>
          <p className="text-white text-xl font-bold">{soloQAccount.leaguePoints} LP</p>
        </div>
        <div className="text-center">
          <p className="text-gray-400">Win/Loss</p>
          <p className="text-white text-xl font-bold">
            {soloQAccount.wins}W / {soloQAccount.losses}L
          </p>
        </div>
      </div>
    </div>
  );
};

export default SoloQInfo;