import React from "react";
import Button from "../../components/Button";

const UserTeam = ({ userProfile }) => {
  return (
    <div className="w-full bg-gray-800 text-white rounded-lg shadow-lg overflow-hidden">
      {userProfile.status === "SIN_EQUIPO" ? (
        <div className="p-6">
          <div className="flex flex-col sm:flex-row justify-between items-center gap-4">
            <div className="text-center sm:text-left">
              <h3 className="text-xl font-bold mb-2">No Team</h3>
              <p className="text-gray-300 mb-4">
                You are not yet in a team. <br />
                Join one or create your own team.
              </p>
            </div>
            <div className="w-full sm:w-auto">
              <Button
                link="/league/new-team"
                className="w-full sm:w-auto bg-yellow-500 hover:bg-yellow-600 text-gray-900 font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105"
              >
                Create Team
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="p-6">
          <h3 className="text-xl font-bold mb-2">My Team</h3>
          <p className="text-gray-300">You are in a team.</p>
        </div>
      )}
    </div>
  );
};

export default UserTeam;
