import React from "react";
import { Coins, Crown, Medal, Shield, User } from "lucide-react";
import {
  top,
  jungler,
  mid,
  adc,
  support,
  coach,
  analyst,
  BOTH,
  LAN,
  LAS,
  challenger,
  grandmaster,
  master,
  diamond,
  emerald,
  platinum,
  gold,
  silver,
  bronce,
  iron,
  unranked
} from "./AssetsBarrel";

export const PlayerCard = ({
  member,
  role,
  region,
  name,
  position,
  tier,
  rank,
  lp,
  summoner,
  user_id,
  avatar_id,
  coins,
}) => {
  const roleImages = {
    Top: top,
    Jungler: jungler,
    Mid: mid,
    ADC: adc,
    Support: support,
    Coach: coach,
    Analyst: analyst,
  };
  const regionImages = { LAN, LAS, "LAN/LAS": BOTH };
  const tierImages = {
    CHALLENGER: challenger,
    GRANDMASTER: grandmaster,
    MASTER: master,
    DIAMOND: diamond,
    EMERALD: emerald,
    PLATINUM: platinum,
    GOLD: gold,
    SILVER: silver,
    BRONZE: bronce,
    IRON: iron,
    UNRANKED: unranked,
    Unranked: unranked
  };

  const memberIcons = {
    captain: <Crown className="w-5 h-5" />,
    subcaptain: <Medal className="w-5 h-5" />,
    staff: <Shield className="w-5 h-5" />,
    player: <User className="w-5 h-5" />,
  };
  const getOpggUrl = (region, summoner) => {
    const formattedSummoner = summoner.replace("#", "-");
    return `https://www.op.gg/summoners/${region.toLowerCase()}/${encodeURIComponent(
      formattedSummoner
    )}`;
  };

  return (
    <div className="flex flex-col sm:flex-row items-center justify-between py-3 px-4 bg-gray-800 rounded-lg text-white text-sm hover:bg-gray-700 transition-colors">
      <div className="flex items-center space-x-3 w-full sm:w-1/4 mb-2 sm:mb-0">
        <span className="text-gray-400 w-6 text-center">{position || "-"}</span>
        <div className="relative flex-shrink-0">
          <img
            src={`https://cdn.discordapp.com/avatars/${user_id}/${avatar_id}.png`}
            alt="Avatar"
            className="w-10 h-10 rounded-full bg-gray-600"
            onError={(e) => {
              e.target.src = "https://i.redd.it/j3t4cvgywd051.png";
            }}
          />
          <img
            src={roleImages[role]}
            alt={role}
            className="absolute -bottom-1 -right-1 w-5 h-5 rounded-full bg-gray-800 p-0.5"
          />
        </div>
        <a href={`/view/profile/${name}`} className="font-medium truncate max-w-[100px] hover:underline">{name}</a>

      </div>

      <div className="flex items-center justify-center w-full sm:w-1/5 mb-2 sm:mb-0">
        <span className="mr-2 w-8 text-center" title={member}>
          {memberIcons[member] || memberIcons.player}
        </span>
        <span className="capitalize">{member}</span>
      </div>

      <div className="flex items-center justify-center w-full sm:w-1/5 mb-2 sm:mb-0">
        <img src={regionImages[region]} alt={region} className="w-6 h-6 mr-2" />
        <span>{region}</span>
      </div>

      <div
        className="flex items-center justify-center w-full sm:w-1/5 mb-2 sm:mb-0 cursor-pointer"
        onClick={() => window.open(getOpggUrl(region, summoner), "_blank")}
      >
        {" "}
        <img
          src={tierImages[tier]}
          alt={tier}
          className="w-10 h-10 mr-2 flex-shrink-0"
        />
        <div className="text-xs">
          <div className="font-semibold">
            {tier.charAt(0) + tier.slice(1).toLowerCase()} {rank}
          </div>
          <div className="text-gray-400">{lp} LP</div>
        </div>
      </div>

      <div className="flex items-center justify-center w-full sm:w-1/5">
        <Coins className="w-5 h-5 mr-1 text-yellow-500 flex-shrink-0" />
        <span>{coins}</span>
      </div>
    </div>
  );
};

export default PlayerCard;
