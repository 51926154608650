import React, { useState, useEffect } from "react";
import { X } from "lucide-react";
import WorldFlags from "react-world-flags";

const CountryFlagSelector = ({ user_country, onCountrySelect }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const countries = [
    { code: "AR", name: "Argentina" },
    { code: "BO", name: "Bolivia" },
    { code: "BR", name: "Brazil" },
    { code: "CL", name: "Chile" },
    { code: "CO", name: "Colombia" },
    { code: "CR", name: "Costa Rica" },
    { code: "CU", name: "Cuba" },
    { code: "DO", name: "Dominican Republic" },
    { code: "EC", name: "Ecuador" },
    { code: "SV", name: "El Salvador" },
    { code: "GT", name: "Guatemala" },
    { code: "HN", name: "Honduras" },
    { code: "MX", name: "Mexico" },
    { code: "NI", name: "Nicaragua" },
    { code: "PA", name: "Panama" },
    { code: "PY", name: "Paraguay" },
    { code: "PE", name: "Peru" },
    { code: "PR", name: "Puerto Rico" },
    { code: "UY", name: "Uruguay" },
    { code: "VE", name: "Venezuela" },
  ];

  useEffect(() => {
    if (user_country) {
      const country = countries.find(c => c.code === user_country);
      if (country) {
        setSelectedCountry(country);
      }
    }
  }, [user_country]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const selectCountry = (country) => {
    setSelectedCountry(country);
    onCountrySelect(country.code);
    closeModal();
  };

  return (
    <div className="flex flex-col items-center">
      <button
        className="w-16 h-16 border-2 border-yellow-500 rounded-full overflow-hidden cursor-pointer focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50 transition-all duration-300 ease-in-out transform hover:scale-105"
        onClick={openModal}
        aria-label="Select country"
      >
        {selectedCountry ? (
          <WorldFlags
            code={selectedCountry.code}
            alt={selectedCountry.name}
            className="w-full h-full object-cover"
          />
        ) : (
          <div className="w-full h-full bg-gray-700 flex items-center justify-center">
            <span className="text-xl font-bold text-white">?</span>
          </div>
        )}
      </button>
      <div className="text-white mt-2 text-center">
        {selectedCountry ? selectedCountry.name : "Selecciona tu nacionalidad"}
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" onClick={closeModal}></div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                    <h3 className="text-lg leading-6 font-medium text-white mb-4" id="modal-title">
                      Selecciona tu país
                    </h3>
                    <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 gap-4 justify-items-center">
                      {countries.map((country) => (
                        <button
                          key={country.code}
                          className="w-12 h-12 cursor-pointer border border-yellow-500 rounded-lg p-1 flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50 transition-all duration-300 ease-in-out transform hover:scale-110"
                          onClick={() => selectCountry(country)}
                        >
                          <WorldFlags
                            code={country.code}
                            alt={country.name}
                            className="w-full h-full object-cover"
                          />
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-800 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={closeModal}
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CountryFlagSelector;