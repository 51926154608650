import Amarillo1 from '../assets/logos/Amarillo1Fit.webp';
import Amarillo2 from '../assets/logos/Amarillo2Fit.webp';
import AmarilloCyan from '../assets/logos/AmarilloCyanFit.webp';
import AmarilloVerde from '../assets/logos/AmarilloVerdeFit.webp';
import AmarilloVerde2 from '../assets/logos/AmarilloVerde2Fit.webp';
import Anivia1 from '../assets/logos/Azul5Fit.webp';
import Anivia2 from '../assets/logos/AzulRojo4Fit.webp';
import Azul1 from '../assets/logos/Azul1Fit.webp';
import Azul2 from '../assets/logos/Azul2Fit.webp';
import Azul3 from '../assets/logos/Azul3Fit.webp';
import Azul4 from '../assets/logos/Azul4Fit.webp';
import AzulAmarillo1 from '../assets/logos/AzulAmarillo1Fit.webp';
import AzulAmarillo2 from '../assets/logos/AzulAmarillo2Fit.webp';
import AzulAmarillo3 from '../assets/logos/AzulAmarillo3Fit.webp';
import AzulGris1 from '../assets/logos/AzulGris1Fit.webp';
import Azulmorado from '../assets/logos/AzulMoradoFit.webp';
import AzulMorado1 from '../assets/logos/AzulMorado1Fit.webp';
import AzulNegro1 from '../assets/logos/AzulNegro1Fit.webp';
import AzulRojo from '../assets/logos/AzulRojoFit.webp';
import AzulRojo1 from '../assets/logos/AzulRojo1Fit.webp';
import AzulRojo2 from '../assets/logos/AzulRojo2Fit.webp';
import AzulRojo3 from '../assets/logos/AzulRojo3Fit.webp';
import AzulRosa from '../assets/logos/AzulRosaFit.webp';
import Azulverde1 from '../assets/logos/AzulVerde1Fit.webp';
import Black1 from '../assets/logos/Black1Fit.webp';
import Black2 from '../assets/logos/Black2Fit.webp';
import Black5 from '../assets/logos/Black5Fit.webp';
import Black7 from '../assets/logos/Black6Fit.webp';
import BlackOrange from '../assets/logos/BlackOrangeFit.webp';
import BlackRed from '../assets/logos/BlackRedFit.webp';
import BlackRed1 from '../assets/logos/BlackRed1Fit.webp';
import BlackWhite from '../assets/logos/BlackWhiteFit.webp';
import BlancoMorado from '../assets/logos/BlancoMoradoFit.webp';
import BlancoMorado2 from '../assets/logos/BlancoMorado2Fit.webp';
import BlancoNegro from '../assets/logos/BlancoNegroFit.webp';
import Blitzcrank1 from '../assets/logos/Blitzcrank1Fit.webp';
import CyanGold from '../assets/logos/CyanGoldFit.webp';
import Dorado2 from '../assets/logos/Dorado2Fit.webp';
import Dorado3 from '../assets/logos/Dorado3Fit.webp';
import Emerald1 from '../assets/logos/Emerald1Fit.webp';
import Ezreal1 from '../assets/logos/Ezreal1Fit.webp';
import Fiddlesticks1 from '../assets/logos/Fiddlesticks1Fit.webp';
import Gold1 from '../assets/logos/Gold1Fit.webp';
import Green1 from '../assets/logos/Green1Fit.webp';
import Green2 from '../assets/logos/Green2Fit.webp';
import Karthus1 from '../assets/logos/Karthus1Fit.webp';
import Karthus2 from '../assets/logos/Karthus2Fit.webp';
import Kayle1 from '../assets/logos/Kayle1Fit.webp';
import Kayle2 from '../assets/logos/Kayle2Fit.webp';
import Cyan from '../assets/logos/CyanFit.webp';
import Cyan1 from '../assets/logos/Cyan1Fit.webp';
import CyanBrown from '../assets/logos/CyanBrownFit.webp';
import KayleVMorgana from '../assets/logos/KaylevMorganaFit.webp';
import Khazix1 from '../assets/logos/Khazix1Fit.webp';
import Malphite1 from '../assets/logos/Malphite1Fit.webp';
import Morado from '../assets/logos/MoradoFit.webp';
import Morado1 from '../assets/logos/Morado1Fit.webp';
import Morado2 from '../assets/logos/Morado2Fit.webp';
import Morado3 from '../assets/logos/Morado3Fit.webp';
import MoradoAzul from '../assets/logos/MoradoAzulFit.webp';
import MoradoDorado from '../assets/logos/MoradoDoradoFit.webp';
import MoradoNegro1 from '../assets/logos/MoradoNegro1Fit.webp';
import MoradoRojo1 from '../assets/logos/MoradoRojo1Fit.webp';
import NegroBlanco from '../assets/logos/NegroBlancoFit.webp';
import NegroCafe from '../assets/logos/NegroCafeFit.webp';
import NegroMorado from '../assets/logos/NegroMoradoFit.webp';
import Nocturne1 from '../assets/logos/Nocturne1Fit.webp';
import Nocturne2 from '../assets/logos/Nocturne2Fit.webp';
import Orange from '../assets/logos/OrangeFit.webp';
import Orange2 from '../assets/logos/Orange2Fit.webp';
import Pantheon1 from '../assets/logos/Pantheon1Fit.webp';
import PantheonvsAatrox from '../assets/logos/PantheonVAatroxFit.webp';
import Pug1 from '../assets/logos/Pug1Fit.webp';
import Rammus1 from '../assets/logos/Rammus1Fit.webp';
import Rammus2 from '../assets/logos/Rammus2Fit.webp';
import RedBlack from '../assets/logos/RedBlackFit.webp';
import RedBlack2 from '../assets/logos/RedBlack2Fit.webp';
import Ryze1 from '../assets/logos/Ryze1Fit.webp';
import Ryze2 from '../assets/logos/Ryze2Fit.webp';
import Skarner1 from '../assets/logos/Skarner1Fit.webp';
import Swain1 from '../assets/logos/Swain1Fit.webp';
import Swain2 from '../assets/logos/Swain2Fit.webp';
import Trynda1 from '../assets/logos/Trynda1Fit.webp';
import Trynda2 from '../assets/logos/Trynda2Fit.webp';
import Verde from '../assets/logos/Verde0Fit.webp';
import Verde1 from '../assets/logos/Verde1Fit.webp';
import Verde2 from '../assets/logos/Verde2Fit.webp';
import Verde3 from '../assets/logos/Verde3Fit.webp';
import VerdeDorado from '../assets/logos/VerdeDoradoFit.webp';
import WhiteRed from '../assets/logos/WhiteRedFit.webp';

const Images = {
    Amarillo1,
    Amarillo2,
    AmarilloCyan,
    AmarilloVerde,
    AmarilloVerde2,
    Anivia1,
    Anivia2,
    Azul1,
    Azul2,
    Azul3,
    Azul4,
    AzulAmarillo1,
    AzulAmarillo2,
    AzulAmarillo3,
    AzulGris1,
    Azulmorado,
    AzulMorado1,
    AzulNegro1,
    AzulRojo,
    AzulRojo1,
    AzulRojo2,
    AzulRojo3,
    AzulRosa,
    Azulverde1,
    Black1,
    Black2,
    Black5,
    Black7,
    BlackOrange,
    BlackRed,
    BlackRed1,
    BlackWhite,
    BlancoMorado,
    BlancoMorado2,
    BlancoNegro,
    Blitzcrank1,
    CyanGold,
    Dorado2,
    Dorado3,
    Emerald1,
    Ezreal1,
    Fiddlesticks1,
    Gold1,
    Green1,
    Green2,
    Karthus1,
    Karthus2,
    Kayle1,
    Kayle2,
    Cyan,
    Cyan1,
    CyanBrown,
    KayleVMorgana,
    Khazix1,
    Malphite1,
    Morado,
    Morado1,
    Morado2,
    Morado3,
    MoradoAzul,
    MoradoDorado,
    MoradoNegro1,
    MoradoRojo1,
    NegroBlanco,
    NegroCafe,
    NegroMorado,
    Nocturne1,
    Nocturne2,
    Orange,
    Orange2,
    Pantheon1,
    PantheonvsAatrox,
    Pug1,
    Rammus1,
    Rammus2,
    RedBlack,
    RedBlack2,
    Ryze1,
    Ryze2,
    Skarner1,
    Swain1,
    Swain2,
    Trynda1,
    Trynda2,
    Verde,
    Verde1,
    Verde2,
    Verde3,
    VerdeDorado,
    WhiteRed
};

export default Images;
