import React from "react";
import Container from "../../components/Container";

const PrivacyPolicy = () => {
  return (
    <div className="flex items-start justify-center">
      <Container className="flex flex-col items-center justify-center mt-20">
        <div className="text-4xl font-bold text-white text-center mb-8 mt-8">
          Privacy Policy for Autoboost GG
        </div>
        <p className='text-white'>
          This Privacy Policy describes how Autoboost.GG (“we,” “us,” or “our”)
          collects, uses, and shares information collected from users (“you” or
          “your”) of https://www.autoboost.gg/. Your use of this website
          constitutes your acceptance of this Privacy Policy.
        </p>
        <div className="mt-8">
          <h2 className="text-2xl font-semibold text-white">Information We Collect:</h2>
          <p className='text-white'>
            Personal Information: When you visit our website, we may collect
            personal information that you voluntarily provide, such as your
            name, email address, and any other information you choose to provide
            when contacting us or registering for our services.
          </p>
        </div>

        <div className="mt-8">
          <p className='text-white'>
            Automatically Collected Information: We may also automatically
            collect certain information about your device and usage patterns,
            such as your IP address, browser type, operating system, and
            browsing behavior on our website. This information is collected
            using cookies and similar tracking technologies.
          </p>
        </div>

        <div className="mt-8">
          <h2 className="text-2xl font-semibold">
            How We Use Your Information:
          </h2>
          <p className='text-white'>
            To Provide Services: We use the information collected to provide
            League of Legends-related content, updates, and services, and to
            respond to your inquiries or requests.
          </p>
        </div>

        <div className="mt-8">
          <p className='text-white'>
            Improvement of Services: We may use the information to analyze user
            trends, improve our website’s functionality, and enhance user
            experience.
          </p>
        </div>

        <div className="mt-8 text-white">
          <p className='text-white'>
            Communication: We may use your contact information to send you
            newsletters, promotional emails, or other communications related to
            League of Legends content, updates, or events. You can opt out of
            receiving these communications at any time.
          </p>
        </div>

        <div className="mt-8">
          <h2 className="text-2xl font-semibold text-white">Information Sharing:</h2>
          <p className='text-white'>
            We do not sell, trade, or otherwise transfer your personal
            information to third parties without your consent, except as
            described in this Privacy Policy. We may share your information with
            trusted third-party service providers who assist us in operating our
            website or conducting our business, as long as those parties agree
            to keep this information confidential.
          </p>
        </div>

        <div className="mt-8">
          <h2 className="text-2xl font-semibold text-white">Data Security:</h2>
          <p className='text-white'>
            We implement reasonable security measures to protect the
            confidentiality and integrity of your personal information. However,
            no method of transmission over the internet or electronic storage is
            completely secure, so we cannot guarantee absolute security.
          </p>
        </div>

        <div className="mt-8">
          <h2 className="text-2xl font-semibold text-white">Your Choices:</h2>
          <p className='text-white'>
            You can choose not to provide personal information, although it may
            limit your ability to access certain features of our website. You
            can also opt out of receiving promotional communications from us by
            following the unsubscribe instructions provided in those
            communications.
          </p>
        </div>

        <div className="mt-8">
          <h2 className="text-2xl font-semibold text-white">
            Changes to This Privacy Policy:
          </h2>
          <p className='text-white'>
            We reserve the right to update or modify this Privacy Policy at any
            time without prior notice. Any changes will be effective immediately
            upon posting the updated Privacy Policy on this page.
          </p>
        </div>

        <div className="mt-8">
          <h2 className="text-2xl font-semibold text-white">Contact Us:</h2>
          <p className='text-white'>
            If you have any questions or concerns about this Privacy Policy or
            our practices regarding your personal information, please contact us
            at CoachAutoboost@gmail.com.
          </p>
        </div>
      </Container>
    </div>
  );
};

export default PrivacyPolicy;
