import React from "react";
import { AlertCircle, Users, CalendarPlus } from "lucide-react";

const ScrimCreate = () => {
  return (
    <div className="w-full bg-gray-900 text-gray-100 shadow-lg hover:shadow-xl transition-shadow duration-300 rounded-lg overflow-hidden">
      <div className="bg-gradient-to-r from-blue-900 to-blue-700 p-4">
        <h2 className="flex items-center space-x-2 text-2xl font-bold">
          <CalendarPlus className="w-6 h-6" />
          <span>Agendar Scrim War</span>
        </h2>
      </div>
      <div className="p-4 mt-4">
        <div className="flex items-start space-x-3 text-gray-300">
          <AlertCircle className="w-5 h-5 text-yellow-500 flex-shrink-0 mt-0.5" />
          <p className="text-sm">
            Asegúrate de tener al menos 5 jugadores en el equipo antes de crear una Scrim War.
            Esto garantizará una experiencia de juego óptima y competitiva para todos los participantes.
          </p>
        </div>
      </div>
      <div className="flex justify-between items-center bg-gray-800 p-4 rounded-b-lg">
        <div className="flex items-center space-x-2 text-sm text-gray-400">
          <Users className="w-4 h-4" />
          <span>Mínimo: 5 jugadores</span>
        </div>
        <a 
          href="/league/scrims/create" 
          className="bg-yellow-600 hover:bg-yellow-700 text-white px-4 py-2 rounded-md flex items-center space-x-2 transition-colors duration-300"
        >
          <CalendarPlus className="w-4 h-4" />
          <span>Crear Scrim</span>
        </a>
      </div>
    </div>
  );
};

export default ScrimCreate;