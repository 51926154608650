import React, { useState } from 'react';
import axios from 'axios';

const CardPreviewModal = ({ card, onClose }) => {
  const [isSelecting, setIsSelecting] = useState(false);
  const [message, setMessage] = useState(null);

  const selectCard = async () => {
    setIsSelecting(true);
    try {
      const response = await axios.post('/user/update_scrim_card/', {
        selected_scrim_card: card.image
      });
      setMessage(response.data.message);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          setMessage(error.response.data.detail);
        } else {
          setMessage(error.response.data.error);
        } 
      } 
    } finally {
      setIsSelecting(false);
    }
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" onClick={onClose}></div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div className="inline-block align-bottom bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                <h3 className="text-lg leading-6 font-medium text-white mb-4 text-center" id="modal-title">
                  Card Preview
                </h3>
                <div className="flex justify-center mb-4">
                  <img src={card.image} alt={`Card ${card.id}`} className="max-w-full h-auto rounded-lg" style={{ maxHeight: '60vh' }} />
                </div>
                {message && (
                  <div className="p-3 rounded-md mb-4">
                    <p className="text-white text-sm text-center">{message}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="bg-gray-800 px-4 py-3 sm:px-6 sm:flex sm:flex-row justify-center">
            {card.card_type === 'Scrim' && (
              <button
                type="button"
                className="w-full sm:w-auto inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:text-sm"
                onClick={selectCard}
                disabled={isSelecting}
              >
                {isSelecting ? 'Selecting...' : 'Select as Profile Card'}
              </button>
            )}
            <button
              type="button"
              className="mt-3 w-full sm:w-auto sm:mt-0 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-gray-700 text-base font-medium text-white hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:text-sm"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardPreviewModal;