import React, { useState, useEffect } from "react";
import {
  Ticket,
  CheckCircle,
  XCircle,
  Copy,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";

const ExchangeCodeList = ({ codes, loading }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState("all");
  const [cardTypeFilter, setCardTypeFilter] = useState("all");
  const [filteredCodes, setFilteredCodes] = useState([]);
  const itemsPerPage = 10;

  useEffect(() => {
    const filtered = codes.filter((code) => {
      if (filter === "redeemed" && !code.is_redeemed) return false;
      if (filter === "unredeemed" && code.is_redeemed) return false;
      if (cardTypeFilter !== "all" && code.card_type !== cardTypeFilter)
        return false;
      return true;
    });
    setFilteredCodes(filtered);
    setCurrentPage(1);
  }, [codes, filter, cardTypeFilter]);

  const pageCount = Math.ceil(filteredCodes.length / itemsPerPage);
  const currentCodes = filteredCodes.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const copyToClipboard = (code) => {
    navigator.clipboard.writeText(code).then(() => {
      alert("Code copied to clipboard");
    });
  };

  const cardTypes = [...new Set(codes.map((code) => code.card_type))];

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-yellow-500"></div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-wrap gap-4 mb-4">
        <select
          className="bg-gray-700 text-white rounded-md px-3 py-2"
          onChange={(e) => setFilter(e.target.value)}
        >
          <option value="all">All</option>
          <option value="redeemed">Redeemed</option>
          <option value="unredeemed">Not Redeemed</option>
        </select>
        <select
          className="bg-gray-700 text-white rounded-md px-3 py-2"
          onChange={(e) => setCardTypeFilter(e.target.value)}
        >
          <option value="all">All Types</option>
          {cardTypes.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>
      </div>
      {currentCodes.length === 0 ? (
        <div className="bg-gray-800 text-white p-6 rounded-lg shadow-lg">
          <p className="text-sm">No codes found with the current filters.</p>
        </div>
      ) : (
        currentCodes.map((code, index) => (
          <div
            key={index}
            className="bg-gray-800 text-white p-4 rounded-lg shadow-lg flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4"
          >
            <div className="flex items-center gap-2 flex-grow">
              <Ticket className="text-yellow-500 flex-shrink-0" size={24} />
              <span className="font-mono text-sm sm:text-base truncate max-w-[150px] sm:max-w-[250px] md:max-w-full">
                {code.exchange_code}
              </span>
              <button
                onClick={() => copyToClipboard(code.exchange_code)}
                className="ml-2 p-1 bg-gray-700 rounded-full hover:bg-gray-600 transition-colors"
              >
                <Copy size={16} />
              </button>
            </div>
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-2">
                {code.is_redeemed ? (
                  <CheckCircle className="text-green-500" size={20} />
                ) : (
                  <XCircle className="text-red-500" size={20} />
                )}
                <span className="text-sm font-semibold">
                  {code.is_redeemed ? "Redeemed" : "Not Redeemed"}
                </span>
              </div>
              <div className="bg-blue-600 px-3 py-1 rounded-full text-sm">
                {code.card_type}
              </div>
            </div>
          </div>
        ))
      )}
      {pageCount > 1 && (
        <div className="flex justify-center items-center gap-4 mt-4">
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className="p-2 bg-gray-700 rounded-full disabled:opacity-50"
          >
            <ChevronLeft size={20} />
          </button>
          <span className="text-white">
            Page {currentPage} of {pageCount}
          </span>
          <button
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, pageCount))
            }
            disabled={currentPage === pageCount}
            className="p-2 bg-gray-700 rounded-full disabled:opacity-50"
          >
            <ChevronRight size={20} />
          </button>
        </div>
      )}
    </div>
  );
};

export default ExchangeCodeList;
