import React from "react";
import { useNavigate } from "react-router-dom";

const Button = ({ children, primary, disabled, link, customStyle }) => {
  const navigate = useNavigate();

  const baseStyle = "rounded-md font-bold text-sm sm:text-base py-2 px-4 transition-all duration-300 ease-in-out";
  const primaryStyle = "bg-blue-600 text-white hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50";
  const secondaryStyle = "bg-purple-600 text-white hover:bg-purple-700 focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50";
  const disabledStyle = "opacity-50 cursor-not-allowed";

  const buttonStyle = `
    ${baseStyle}
    ${primary ? primaryStyle : secondaryStyle}
    ${disabled ? disabledStyle : ""}
    ${customStyle || ""}
  `.trim();

  const handleClick = () => {
    if (!disabled && link) {
      navigate(link);
    }
  };

  return (
    <button
      onClick={handleClick}
      className={buttonStyle}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;