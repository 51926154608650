import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import { Loader2, Coins,Trophy } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Images from '../../components/ImagesContainer';
import Container from '../../components/Container';
import TitleContainer from "../../components/TitleContainer";

const LeaderboardPage = () => {
  const [teams, setTeams] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await axios.get('/team-ranking/');
        setTeams(response.data);
        setIsLoading(false);
      } catch (err) {
        setError('Failed to fetch team rankings. Please try again later.');
        setIsLoading(false);
      }
    };

    fetchTeams();
  }, []);

  const goToTeamPage = (teamName) => {
    navigate(`/league/equipos/${encodeURIComponent(teamName)}`);
  };

  if (isLoading) {
    return (
      <Container>
        <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-yellow-500"></div>
          </div>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <div className="flex justify-center items-center h-screen">
          <p className="text-red-500 text-xl">{error}</p>
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <div className="py-8">
      <TitleContainer
            text="Global Ranking"
            icon={<Trophy className="h-6 w-6 text-white" />}
            color="bg-blue-600"
            flex="start"
          />
        <div className="bg-gradient-to-r from-gray-800 to-gray-900 shadow-lg rounded-lg overflow-hidden">
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead className="bg-gray-700">
                <tr>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Rank</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Team</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Coins</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-700">
                {teams.map((team, index) => (
                  <motion.tr
                    key={team.name}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: index * 0.05 }}
                    className="hover:bg-gray-700"
                  >
                    <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-100">
                      {index + 1}
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap">
                      <div 
                        className="flex items-center hover:opacity-80 transition-opacity cursor-pointer"
                        onClick={() => goToTeamPage(team.name)}
                      >
                        <div className="flex-shrink-0 h-10 w-10">
                          <img
                            src={Images[team.image]}
                            alt={`${team.name} logo`}
                            className="h-10 w-10 rounded-full object-cover"
                          />
                        </div>
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-100">{team.name}</div>
                        </div>
                      </div>
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-300">
                      <div className="flex items-center">
                        <Coins className="h-5 w-5 text-yellow-500 mr-2" />
                        {team.total_coins.toLocaleString()}
                      </div>
                    </td>
                  </motion.tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default LeaderboardPage;