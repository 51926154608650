import React from 'react';
import { Lock } from 'lucide-react';

const PrivacySettingsModal = ({ isOpen, onClose, isProfilePrivate, setIsProfilePrivate, onSave }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" onClick={onClose}></div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div className="inline-block align-bottom bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                <Lock className="h-6 w-6 text-blue-600" aria-hidden="true" />
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                <h3 className="text-lg leading-6 font-medium text-white mb-4" id="modal-title">
                  Privacy Settings
                </h3>
                <div className="mt-2">
                  <label className="flex items-center text-white">
                    <input
                      type="checkbox"
                      checked={isProfilePrivate}
                      onChange={(e) => setIsProfilePrivate(e.target.checked)}
                      className="form-checkbox h-5 w-5 text-blue-600 transition duration-150 ease-in-out"
                    />
                    <span className="ml-2">Make profile private</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-800 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse sm:gap-3">
            <button
              onClick={onSave}
              className="bg-green-700 hover:bg-opacity-90 text-white font-bold py-2 px-4 rounded-lg transition-colors"
            >
              Save
            </button>
            <button
              onClick={onClose}
              className="bg-primary hover:bg-opacity-90 text-white font-bold py-2 px-4 rounded-lg transition-colors"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacySettingsModal;