import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaBan, FaTimes } from "react-icons/fa";
import Loader from "../../components/Loader";

const BanSummonerSpellModal = ({ tournamentId, closeModal }) => {
  const [summonerSpells, setSummonerSpells] = useState([]);
  const [selectedSpell, setSelectedSpell] = useState("");
  const [isLoadingInitial, setIsLoadingInitial] = useState(true);
  const [bannedSpells, setBannedSpells] = useState([]);
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [responseModalContent, setResponseModalContent] = useState("");
  const [isLoadingAction, setIsLoadingAction] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoadingInitial(true);
        const bannedSpellsResponse = await axios.get(
          `api/tournament/${tournamentId}/rules`
        );

        const bannedSpellsData = bannedSpellsResponse.data.filter(
          (item) => item.banned && typeof item.banned === "string"
        );

        const bannedSpellsNames = bannedSpellsData.map(
          (bannedItem) => bannedItem.banned
        );

        setBannedSpells(bannedSpellsNames);

        const summonerSpellList = [
          {
            name: "Ghost",
            imageUrl:
              "https://ddragon.leagueoflegends.com/cdn/13.23.1/img/spell/SummonerHaste.png",
          },
          {
            name: "Heal",
            imageUrl:
              "https://ddragon.leagueoflegends.com/cdn/13.23.1/img/spell/SummonerHeal.png",
          },
          {
            name: "Exhaust",
            imageUrl:
              "https://ddragon.leagueoflegends.com/cdn/13.23.1/img/spell/SummonerExhaust.png",
          },
          {
            name: "Ignite",
            imageUrl:
              "https://ddragon.leagueoflegends.com/cdn/13.23.1/img/spell/SummonerDot.png",
          },
          {
            name: "Barrier",
            imageUrl:
              "https://ddragon.leagueoflegends.com/cdn/13.23.1/img/spell/SummonerBarrier.png",
          },
          {
            name: "Teleport",
            imageUrl:
              "https://ddragon.leagueoflegends.com/cdn/13.23.1/img/spell/SummonerTeleport.png",
          },
        ];

        const filteredSpells = summonerSpellList.filter(
          (spell) => !bannedSpellsNames.includes(spell.name)
        );

        setSummonerSpells(filteredSpells);
        setIsLoadingInitial(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoadingInitial(false);
      }
    }

    fetchData();
  }, [tournamentId]);

  const handleBanSummonerSpell = async (spellName) => {
    try {
      setIsLoadingAction(true);

      const response = await axios.post("tournament/exchange/rule-ticket/", {
        tournament_id: tournamentId,
        ban_type: "hechizo",
        ban_name: spellName,
      });
      setResponseModalContent(response.data.detail);
      setShowResponseModal(true);
      setSelectedSpell("");
    } catch (error) {
      setResponseModalContent(
        error.response?.data?.detail || "Error al banear Summoner Spell"
      );
      setShowResponseModal(true);
    } finally {
      setIsLoadingAction(false);
    }
  };

  const closeModalHandler = () => {
    setShowResponseModal(false);
    setResponseModalContent("");
    closeModal();
    window.location.reload();
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-gray-800 p-6 rounded-lg shadow-xl border-2 border-yellow-500 text-white w-full max-w-2xl max-h-[90vh] overflow-hidden flex flex-col">
        <h2 className="text-2xl font-bold text-center mb-6 flex items-center justify-center">
          <FaBan className="mr-2" />
          Select a Summoner Spell for Ban
        </h2>
        {isLoadingInitial ? (
          <div className="flex items-center justify-center h-64">
            <Loader />
          </div>
        ) : (
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 mb-6">
            {summonerSpells.map((spell, index) => (
              <button
                key={index}
                onClick={() => setSelectedSpell(spell.name)}
                className={`flex flex-col items-center justify-center p-4 rounded-lg transition-all duration-200 ${
                  selectedSpell === spell.name
                    ? "bg-yellow-500 bg-opacity-25 border-2 border-yellow-500"
                    : "bg-gray-700 hover:bg-gray-600"
                }`}
              >
                <img
                  src={spell.imageUrl}
                  alt={spell.name}
                  className="w-12 h-12 mb-2"
                />
                <span className="text-sm font-medium">{spell.name}</span>
              </button>
            ))}
          </div>
        )}
        <div className="flex justify-center space-x-4">
          <button
            className={`px-6 py-2 rounded-md font-bold text-sm sm:text-base transition-colors duration-200 ${
              selectedSpell
                ? "bg-yellow-500 hover:bg-yellow-600 text-gray-900"
                : "bg-gray-600 text-gray-400 cursor-not-allowed"
            }`}
            onClick={() => handleBanSummonerSpell(selectedSpell)}
            disabled={!selectedSpell}
          >
            Ban
          </button>
          <button
            className="px-6 py-2 rounded-md bg-red-500 hover:bg-red-600 text-white font-bold text-sm sm:text-base transition-colors duration-200"
            onClick={closeModal}
          >
            Cancel
          </button>
        </div>
      </div>

      {showResponseModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-gray-800 p-6 rounded-lg shadow-xl border-2 border-yellow-500 text-white max-w-md w-full">
            <h3 className="font-bold text-xl mb-4">Nueva Regla de Torneo</h3>
            <p className="mb-6">{responseModalContent}</p>
            <button
              onClick={closeModalHandler}
              className="w-full bg-yellow-500 hover:bg-yellow-600 text-gray-900 font-bold py-2 px-4 rounded-md transition-colors duration-200"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {isLoadingAction && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default BanSummonerSpellModal;
