import React from 'react';
import { SiMcdonalds } from "react-icons/si";
import Images from "../../components/ImagesContainer";
import { Link } from 'react-router-dom';

const TeamInfo = ({ team, teamImage }) => {
  return (
    <div className="bg-gray-700 p-6 rounded-lg h-full flex flex-col">
      <h2 className="text-xl font-bold text-white mb-4">Team</h2>
      <div className="flex-grow flex items-center justify-center">
        {team === "SIN_EQUIPO" ? (
          <div className="flex flex-col items-center justify-center">
            <SiMcdonalds className="text-yellow-400 text-6xl mb-4" />
            <p className="text-gray-300 text-center">This user is not part of any team</p>
          </div>
        ) : (
          <Link to={`/league/equipos/${encodeURIComponent(team)}`} className="flex flex-col items-center justify-center">
            <img 
              src={Images[teamImage]} 
              alt={team} 
              className="w-24 h-24 object-cover rounded-full mb-4"
            />
            <div className="bg-indigo-900 px-6 py-3 rounded-full hover:bg-indigo-800 transition-colors">
              <p className="text-white text-xl font-bold">{team}</p>
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};

export default TeamInfo;