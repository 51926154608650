import React, { useState, useEffect, useCallback, useMemo } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import Images from "../../components/ImagesContainer";
import {
  challenger,
  grandmaster,
  master,
  diamond,
  emerald,
  platinum,
  gold,
  silver,
  bronce,
  iron, unranked
} from "../../components/AssetsBarrel";
import { ChevronDown, ChevronUp, MessageSquare, Users } from "lucide-react";
import { PlayerCard } from "../../components/PlayerCard";
import CardLoader from "../../components/CardLoader";

const MyInvScrimCard = ({
  scrim,
  handleCancelClick,
  handleAcceptClick,
  isCaptain,
  sent,
  isWorking,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [teamData, setTeamData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const goToTeamPage = useCallback((teamName) => {
    navigate(`/league/equipos/${teamName}`);
  }, [navigate]);

  const fetchTeamData = useCallback(async () => {
    setIsLoading(true);
    let filteredMembers = [];

    try {
      const teamId = sent ? scrim.receiving_team : scrim.requesting_team;
      const response = await fetch(`/api/team/${teamId}`);
      const data = await response.json();

      const scrimPlayersResponse = await Axios.get(
        sent
          ? `/api/scrim-request-players/${scrim.scrim_request}/`
          : `/api/scrim-invitation-players/${scrim.id}/`
      );
      const scrimPlayers = scrimPlayersResponse.data.map((player) => player.id);
      filteredMembers = data.members.filter((member) =>
        scrimPlayers.includes(member.id)
      );

      const updatedTeamData = { ...data, members: filteredMembers };
      setTeamData(updatedTeamData);
    } catch (error) {
      console.error("Error fetching team data:", error);
    } finally {
      setIsLoading(false);
    }
  }, [scrim, sent]);

  const handleChevronClick = useCallback(() => {
    if (!expanded && !teamData) {
      fetchTeamData();
    }
    setExpanded((prev) => !prev);
  }, [expanded, teamData, fetchTeamData]);

  const getPlayerType = useCallback((discordTag, captain, subcaptains, staff) => {
    if (discordTag === captain) return "captain";
    if (subcaptains.includes(discordTag)) return "subcaptain";
    if (staff.includes(discordTag)) return "staff";
    return "player";
  }, []);

  const renderPlayerCards = useMemo(() => {
    if (!teamData || !teamData.members) return null;

    const members = teamData.members.filter((member) =>
      teamData.players.includes(member.discord_tag)
    );
    const captain = teamData.captain && teamData.captain[0];
    const subcaptains = teamData.subcaptains;

    return members.map((member, index) => (
      <PlayerCard
        key={index}
        member={getPlayerType(member.discord_tag, captain, subcaptains, teamData.staff)}
        name={member.discord_tag}
        role={member.role}
        region={member.region}
        soloq_account={member.soloq_account__summonerName}
        elo={`${member.soloq_account__tier} ${member.soloq_account__rank}`}
        tier={member.soloq_account__tier}
        rank={member.soloq_account__rank}
        lp={member.soloq_account__leaguePoints}
        active={member.is_active}
        summoner={member.soloq_account__summonerName}
        user_id={member.user_id}
        avatar_id={member.avatar_id}
        coins={member.coins}
      />
    ));
  }, [teamData, getPlayerType]);

  const getRankImage = useCallback((rating) => {
    const numericRating = parseFloat(rating);
    if (isNaN(numericRating)) return iron;
    if (numericRating >= 10.0) return challenger;
    if (numericRating >= 9.0) return grandmaster;
    if (numericRating >= 8.0) return master;
    if (numericRating >= 7.0) return diamond;
    if (numericRating >= 6.0) return emerald;
    if (numericRating >= 5.0) return platinum;
    if (numericRating >= 4.0) return gold;
    if (numericRating >= 3.0) return silver;
    if (numericRating >= 2.0) return bronce;
    return iron;
  }, []);

  const formatRating = (rating) => {
    const numericRating = parseFloat(rating);
    return isNaN(numericRating) ? 'N/A' : numericRating.toFixed(2);
  };

  return (
    <div className="bg-gradient-to-r from-gray-800 to-gray-900 rounded-lg shadow-lg overflow-hidden transition-all duration-300">
      <div className="p-6">
        <div className="flex flex-col sm:flex-row justify-between items-center gap-6">
          <div className="flex items-center gap-4">
            <img
              src={Images[scrim.team_image]}
              alt="Team Logo"
              className="w-16 h-16 rounded-full object-cover"
            />
            <div className="flex flex-col">
              <button
                onClick={() => goToTeamPage(sent ? scrim.receiving_team : scrim.requesting_team)}
                className="text-lg font-semibold text-white hover:text-yellow-500 transition-colors"
              >
                {sent ? scrim.receiving_team : scrim.requesting_team}
              </button>
              <div className="flex items-center gap-2 text-gray-400">
                <Users size={16} />
                <span>{teamData ? teamData.members.length : '...'} players</span>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <img
              src={getRankImage(scrim.average_rating)}
              alt="Average Rank"
              className="w-12 h-12"
            />
            <div className="text-center">
              <p className="text-sm text-gray-400">Average Rating</p>
              <p className="text-lg font-semibold">{formatRating(scrim.average_rating)}</p>
            </div>
          </div>
        </div>
        <div className="mt-4 flex items-start gap-2">
          <MessageSquare size={20} className="text-gray-400 mt-1 flex-shrink-0" />
          <p className="text-gray-300 text-sm">{scrim.comments || 'No comments'}</p>
        </div>
        {isCaptain() && (
          <div className="mt-6 flex justify-end gap-4">
            {handleAcceptClick && (
              <button
                className={`bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition-colors ${
                  isWorking ? "opacity-50 cursor-not-allowed" : ""
                }`}
                onClick={handleAcceptClick}
                disabled={isWorking}
              >
                Aceptar
              </button>
            )}
            {handleCancelClick && (
              <button
                className={`bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition-colors ${
                  isWorking ? "opacity-50 cursor-not-allowed" : ""
                }`}
                onClick={handleCancelClick}
                disabled={isWorking}
              >
                Cancelar
              </button>
            )}
          </div>
        )}
      </div>
      <div className="border-t border-gray-700">
        <button
          onClick={handleChevronClick}
          className="w-full py-3 px-6 text-left text-gray-400 hover:text-white transition-colors flex items-center justify-between"
          aria-expanded={expanded}
          aria-label={expanded ? "Collapse details" : "Expand details"}
        >
          <span className="font-semibold">Team Details</span>
          {expanded ? (
            <ChevronUp className="w-5 h-5" />
          ) : (
            <ChevronDown className="w-5 h-5" />
          )}
        </button>
      </div>
      {expanded && (
        <div className="p-6 space-y-4 bg-gray-800">
          {isLoading ? <CardLoader /> : renderPlayerCards}
        </div>
      )}
    </div>
  );
};

export default MyInvScrimCard;