import React from "react";
import Heading from "../../components/Heading";
import Discord from "./Discord";

const index = () => {
  return (
    <div className="min-h-screen w-full bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 flex items-center justify-center px-4">
      <div className="absolute inset-0 bg-cover bg-center bg-no-repeat opacity-20"></div>
      
      <div className="relative z-10 w-full max-w-md bg-gray-800 bg-opacity-80 backdrop-blur-sm rounded-xl shadow-2xl p-8 flex flex-col items-center justify-center space-y-6">
        <div className="text-center space-y-2">
          <Heading white large>Welcome to</Heading>
          <Heading white large>Autoboost</Heading>
        </div>

        <Heading white>Sign In</Heading>
        
        <Discord />

        <p className="text-gray-400 text-sm text-center mt-4">
          By signing in, you agree to our Terms of Service and Privacy Policy.
        </p>
      </div>
    </div>
  );
};

export default index;