import React, { useState, useEffect } from "react";
import Container from "../../components/Container";
import TitleContainer from "../../components/TitleContainer";
import { AiOutlineTrophy } from "react-icons/ai";
import { TournamentCard } from "./TournamentCard";
import Loader from "../../components/Loader";
import axios from "axios";
import UserDetails from "./UserDetailsTournament";

const Index = ({ user }) => {
  const [loading, setLoading] = useState(true);
  const [tournaments, setTournaments] = useState([]);

  useEffect(() => {
    const fetchTournamentData = async () => {
      try {
        setLoading(true);

        const tournamentsListResponse = await axios.get("api/tournamentsList");
        const tournamentsList = tournamentsListResponse.data;

        let joinedTournamentIds = [];

        try {
          const joinedTournamentsResponse = await axios.get(
            "api/tournaments_joined"
          );
          joinedTournamentIds =
            joinedTournamentsResponse.data.joined_tournament_ids;
        } catch (error) {
          console.error(
            "Error al obtener los torneos a los que se ha unido el usuario:",
            error
          );
        }

        const updatedTournaments = tournamentsList.map((tournament) => ({
          ...tournament,
          joined: joinedTournamentIds.includes(tournament.id),
        }));

        setTournaments(updatedTournaments);
      } catch (error) {
        console.error("Error al obtener los torneos:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTournamentData();
  }, []);

  useEffect(() => {
    if (user === null) {
      setLoading(false);
    }
  }, [user]);

  return (
    <Container>
      {loading ? (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
          <Loader />
        </div>
      ) : (
        <div className="mt-4 space-y-8">
          <TitleContainer
            text="Tournaments"
            icon={<AiOutlineTrophy className="text-3xl text-white" />}
            color="bg-blue-600"
            flex="start"
          />

          <div className="space-y-8">
            {user && (
              <div className="bg-gray-800 rounded-lg shadow-lg p-6">
                <h2 className="text-2xl font-bold text-white mb-4">
                  USer Details
                </h2>
                <UserDetails
                  tournamentTickets={user.user_profile.tournament_tickets}
                  targetChampionBan={user.user_profile.target_champion_ban}
                  rulesetTier1={user.user_profile.ruleset_tier_1}
                  rulesetTier2={user.user_profile.ruleset_tier_2}
                  rulesetTier3={user.user_profile.ruleset_tier_3}
                  lowTierSpellBan={user.user_profile.low_tier_spell_ban}
                  highTierSpellBan={user.user_profile.high_tier_spell_ban}
                  canceledRule={user.user_profile.canceled_rule}
                />
              </div>
            )}

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
              {tournaments.map((tournament) => (
                <TournamentCard
                  key={tournament.id}
                  id={tournament.id}
                  name={tournament.name}
                  price={tournament.price}
                  startDate={tournament.start_date}
                  endDate={tournament.end_date}
                  joined={tournament.joined || false}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default Index;
