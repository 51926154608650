import React, { useState, useEffect, useCallback, useMemo } from "react";
import Axios from "axios";
import TitleContainer from "../../components/TitleContainer";
import Container from "../../components/Container";
import { Swords, Filter } from "lucide-react";
import MyScrimCard from "./MyScrimCard";
import Loader from "../../components/Loader";
const MyScrims = ({ userData }) => {
  const [scrims, setScrims] = useState([]);
  const [status, setStatus] = useState("PENDIENTE");
  const [isCancelling, setIsCancelling] = useState({});
  const [isFetching, setIsFetching] = useState(false);

  const fetchScrims = useCallback(async () => {
    setIsFetching(true);
    try {
      const response = await Axios.get(`/api/my-scrims/${status}/`);
      setScrims(response.data);
    } catch (error) {
      console.error("Error fetching scrims:", error);
    } finally {
      setIsFetching(false);
    }
  }, [status]);

  useEffect(() => {
    fetchScrims();
  }, [fetchScrims]);

  const handleCancel = useCallback((scrimId) => {
    setIsCancelling((prev) => ({ ...prev, [scrimId]: true }));

    Axios.post(`/api/cancel-scrim-request/`, { id: scrimId })
      .then(() => {
        setScrims((prevScrims) =>
          prevScrims.filter((scrim) => scrim.id !== scrimId)
        );
      })
      .catch((error) => {
        console.error("Error canceling scrim:", error);
      })
      .finally(() => {
        setIsCancelling((prev) => ({ ...prev, [scrimId]: false }));
      });
  }, []);

  const isCaptain = useCallback(() => {
    return (
      userData?.team_info?.is_captain || userData?.team_info?.is_subcaptain
    );
  }, [userData]);

  const getTitles = useMemo(() => {
    const baseTitles = ["Equipo", "Elo", "Fecha", "Hora"];
    return isCaptain() ? [...baseTitles, "Accion"] : baseTitles;
  }, [isCaptain]);

  if (isFetching || userData === undefined) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <Loader />
      </div>
    );
  }

  return (
    <Container>
      <div className="flex flex-col gap-4 mt-4">
        <TitleContainer
          text="Mis Scrims"
          icon={<Swords className="w-6 h-6" />}
          color="bg-blue-600"
          flex="start"
        />
        <div className="flex flex-wrap gap-4 items-center">
          <div className="relative">
            <select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="appearance-none w-full bg-gray-800 text-white border border-gray-700 rounded-lg py-2 px-4 pr-8 focus:outline-none focus:ring-2 focus:ring-blue-600"
            >
              <option value="PENDIENTE">Pendiente</option>
              <option value="ACEPTADO">Aceptado</option>
              <option value="CANCELADO">Cancelado</option>
            </select>
            <Filter
              className="absolute right-3 top-2.5 text-gray-400 pointer-events-none"
              size={20}
            />
          </div>
          {userData?.in_team && isCaptain() && (
            <a
              href="/league/scrims/create"
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded text-sm transition-colors duration-300"
            >
              Crear Scrim
            </a>
          )}
        </div>
        <div className="mt-4">
          <div className="space-y-4">
            {scrims.map((scrim) => (
              <MyScrimCard
                key={scrim.id}
                scrim={scrim}
                handleCancelClick={() => handleCancel(scrim.id)}
                isCaptain={isCaptain}
                isCancelling={isCancelling[scrim.id] || false}
              />
            ))}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default MyScrims;
