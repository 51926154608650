import React, { useState, useEffect } from "react";
import axios from "axios";
import Container from "../../components/Container";
import { BiSolidBadgeCheck } from "react-icons/bi";
import TitleContainer from "../../components/TitleContainer";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";

const SoloQVerification = () => {
  const [summonerName, setSummonerName] = useState("");
  const [server, setServer] = useState("");
  const [verificationResult, setVerificationResult] = useState(null);
  const [csrfToken, setCsrfToken] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [randomIconId, setRandomIconId] = useState(1);
  const [isPlaceholder, setIsPlaceholder] = React.useState(true);
  const [isVerificationSuccessful, setIsVerificationSuccessful] =
    useState(false);
  const [tag, setTag] = useState(""); 

  const handleSelectChange = (e) => {
    setServer(e.target.value);
    setIsPlaceholder(e.target.value === "");
  };
  useEffect(() => {
    axios
      .get("/api/get-csrf-token/")
      .then((response) => {
        setCsrfToken(response.data.csrfToken);
      })
      .catch((error) => {
        console.error(error);
      });

    const randomId = Math.floor(Math.random() * 5) + 1;
    setRandomIconId(randomId);
  }, []);

  const handleVerification = () => {
    setIsLoading(true);

    axios
      .post(
        "/api/link-lol-account/", 
        {
          soloq_account: {
            server,
            summonerName,
            tag,
            iconId: randomIconId,
          },
        },
        {
          headers: {
            "X-CSRFToken": csrfToken, 
          },
        }
      )
      .then((response) => {
        setVerificationResult(response.data.detail);

        if (response.status === 200) {
          setIsVerificationSuccessful(true);
        } else {
          setIsVerificationSuccessful(false);
        }
      })
      .catch((error) => {
        console.error(error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.detail
        ) {
          setVerificationResult(error.response.data.detail);
        } else {
          setVerificationResult("Error verifying SoloQ account.");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const closeModalAndRedirect = () => {
    setVerificationResult(null);
    if (isVerificationSuccessful) {
      navigate("/profile");
    }
  };
  return (
    <Container>
      <div className="flex flex-col gap-2 mt-2">
        <div
          className={`fixed inset-0 flex justify-center items-center ${
            isLoading ? "" : "hidden"
          }`}
        >
          <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
            <Loader />
          </div>
        </div>

        <TitleContainer
          text={"Verifica tu cuenta de Riot"}
          icon={<BiSolidBadgeCheck />}
          color="--fifth-color"
          flex="start"
        />
        <div className="flex flex-col items-center gap-8 bg-third px-2 py-8 rounded">
          <div className="flex flex-col items-center gap-2">
            <img
              src={`https://ddragon-webp.lolmath.net/latest/img/profileicon/${randomIconId}.webp`}
              alt="Icono de Invocador"
              width="100"
              height="100"
              className="rounded-lg"
            />
            <p className="text-md font-bold text-white w-3/4 sm:w-full text-center">
              Para verificar la cuenta, debes colocarle el siguiente icono de
              invocador
            </p>
            <p className="text-md font-bold text-white w-3/4 sm:w-full text-center">
              Si tu Riot ID es Autoboost#LAN, el campo Riot ID debe ser Autoboost y el campo Riot Tag debe ser LAN (SIN EL SIMBOLO #)
            </p>
          </div>
          <div className="flex flex-col sm:flex-row justify-between w-2/4 items-center gap-4">
            <div className="text-md font-bold flex gap-1 flex-col">
              <label htmlFor="summonerName" className="mr-2 text-white">
                Riot ID
              </label>
              <input
                placeholder="Riot ID Name"
                type="text"
                id="summonerName"
                value={summonerName}
                onChange={(e) => setSummonerName(e.target.value)}
                className="relative bg-gray-900 bg-opacity-25 ring-0 outline-none border border-white text-white placeholder-gray-400 text-sm rounded-lg focus:yellow placeholder-opacity-60 focus:border-yellow-500 block w-full p-2.5 checked:bg-emerald-500"
              />
            </div>
            <div className="text-md font-bold flex gap-1 flex-col">
              <label htmlFor="tag" className="mr-2 text-white">
                Riot Tag (Solamente el tag, sin el #)
              </label>
              <input
                placeholder="356"
                type="text"
                id="Tag"
                value={tag}
                onChange={(e) => setTag(e.target.value)}
                className="relative bg-gray-900 bg-opacity-25 ring-0 outline-none border border-white text-white placeholder-gray-400 text-sm rounded-lg focus:yellow placeholder-opacity-60 focus:border-yellow-500 block w-full p-2.5 checked:bg-emerald-500"
              />
            </div>
            <div className="text-md font-bold flex flex-col gap-1 ">
              <label htmlFor="server" className="mr-2 text-white">
                Region
              </label>
              <select
                id="server"
                value={server}
                onChange={handleSelectChange}
                className={`w-48 relative bg-transparent outline-none border border-white text-sm rounded-lg block p-2.5 bg-gray-900 bg-opacity-25 focus:border-yellow-500 ${
                  isPlaceholder ? "text-gray-400" : "text-white"
                }`}
              >
                <option value="" disabled>
                  Elige Region
                </option>
                <option value="LA1">LAN</option>
                <option value="LA2">LAS</option>
              </select>
            </div>
          </div>
          <div>
            <button
              className={`${
                !summonerName || !server
                  ? "bg-gray-400 text-gray-300 cursor-not-allowed"
                  : "bg-fifth text-white"
              } py-2 px-4 rounded text-sm hover:bg-blue-900`}
              onClick={handleVerification}
              disabled={!summonerName || !server}
            >
              Verificar Cuenta
            </button>
          </div>
          {verificationResult && (
            <div>
              <p>{verificationResult}</p>
            </div>
          )}
        </div>
      </div>
      {verificationResult && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
          <div className="bg-fifth p-6 rounded shadow-md border-2 border-yellow-500 text-white flex flex-col items-center justify-center">
            <h3 className="font-bold text-lg">Verificación de cuenta</h3>

            <p>{verificationResult}</p>
            <button
              className="modal-button bg-primary rounded-md text-white font-bold text-xs py-2 px-4 border-2 border-transparent sm:text-base hover:border-2 hover:border-yellow-500 hover:border-opacity-60 mt-4"
              onClick={closeModalAndRedirect}
            >
              Cerrar
            </button>
          </div>
        </div>
      )}
    </Container>
  );
};

export default SoloQVerification;
