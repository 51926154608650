import React from "react";

const Heading = ({ children, white, large, gray, className }) => {
  let textColor = 'text-gray-900';
  
  if (white) {
    textColor = 'text-white';
  } else if (gray) {
    textColor = 'text-gray-500';
  }
  
  const textSize = large ? 
    'text-2xl sm:text-3xl md:text-4xl lg:text-5xl' : 
    'text-xl sm:text-2xl md:text-3xl lg:text-4xl';
  
  return (
    <h2 className={`
      ${textSize} 
      ${textColor} 
      font-bold 
      tracking-tight 
      leading-tight 
      mb-4
      ${className || ''}
    `.trim()}>
      {children}
    </h2>
  );
};

export default Heading;