import React, { useState, useEffect } from "react";
import { HelpCircle, X } from "lucide-react";

// Import all role icons
import top from "../../assets/Position_Challenger-Top.png";
import jungler from "../../assets/Position_Challenger-Jungle.png";
import mid from "../../assets/Position_Challenger-Mid.png";
import adc from "../../assets/Position_Challenger-Bot.png";
import support from "../../assets/Position_Challenger-Support.png";
import coach from "../../assets/icons8-coach-72.png";
import analyst from "../../assets/icons8-analyst-72.png";
import duelist from "../../assets/duelist.png";
import initiator from "../../assets/initiator.png";
import sentinel from "../../assets/sentinel.png";
import controller from "../../assets/controller.png";
import captain from "../../assets/fifacaptain.png";
import shortfreekick from "../../assets/fifashortfreekick.png";
import longfreekick from "../../assets/fifalongfreekick.png";
import penalty from "../../assets/fifapenalty.png";
import leftcorner from "../../assets/fifarightcorner.png";
import rightcorner from "../../assets/fifarightcorner.png";

const Role = ({ selectedRole, setRole, game }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [defaultIcon, setDefaultIcon] = useState("");
  const [defaultRoleName, setDefaultRoleName] = useState("");

  const lolRoles = [
    { id: "top", name: "Top", icon: top },
    { id: "jungler", name: "Jungler", icon: jungler },
    { id: "mid", name: "Mid", icon: mid },
    { id: "adc", name: "ADC", icon: adc },
    { id: "support", name: "Support", icon: support },
    { id: "coach", name: "Coach", icon: coach },
    { id: "analyst", name: "Analyst", icon: analyst },
  ];

  const valorantRoles = [
    { id: "duelist", name: "Duelist", icon: duelist },
    { id: "initiator", name: "Initiator", icon: initiator },
    { id: "sentinel", name: "Sentinel", icon: sentinel },
    { id: "controller", name: "Controller", icon: controller },
  ];

  const fifaRoles = [
    { id: "captain", name: "Captain", icon: captain },
    { id: "shortfreekick", name: "Short Free Kick", icon: shortfreekick },
    { id: "longfreekick", name: "Long Free Kick", icon: longfreekick },
    { id: "penalty", name: "Penalty", icon: penalty },
    { id: "leftcorner", name: "Left Corner", icon: leftcorner },
    { id: "rightcorner", name: "Right Corner", icon: rightcorner },
  ];

  let roles = lolRoles;

  if (game === "Valorant") {
    roles = valorantRoles;
  } else if (game === "FIFA") {
    roles = fifaRoles;
  }

  useEffect(() => {
    if (selectedRole) {
      const role = roles.find((role) => role.name === selectedRole);
      if (role) {
        setDefaultIcon(role.icon);
        setDefaultRoleName(role.name);
      }
    } else {
      setDefaultIcon("");
      setDefaultRoleName("Selecciona tu rol");
    }
  }, [selectedRole, roles]);

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const handleRoleSelection = (role) => {
    if (selectedRole === role.name) {
      setRole(null);
      setDefaultIcon("");
      setDefaultRoleName("Select your role");
    } else {
      setRole(role.name);
      setDefaultIcon(role.icon);
      setDefaultRoleName(role.name);
    }
    closeModal();
  };

  return (
    <div className="relative">
      <div
        className="p-4 relative inset-0 flex flex-col items-center cursor-pointer"
        onClick={openModal}
      >
        <div className="bg-gray-800 bg-opacity-90 p-4 rounded-lg w-full h-40 sm:h-48 md:h-56 lg:h-64 flex flex-col items-center justify-center text-center transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg">
          {defaultIcon ? (
            <img
              src={defaultIcon}
              alt={defaultRoleName}
              className="w-16 h-16 sm:w-20 sm:h-20 md:w-24 md:h-24 lg:w-28 lg:h-28 mb-4"
            />
          ) : (
            <HelpCircle size={48} className="text-white mb-4" />
          )}
          <span className="text-white text-lg sm:text-xl md:text-2xl lg:text-3xl font-semibold">
            {defaultRoleName}
          </span>
        </div>
      </div>

      {isModalVisible && (
        <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" onClick={closeModal}></div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                    <h3 className="text-lg leading-6 font-medium text-white mb-4" id="modal-title">
                      Select Role
                    </h3>
                    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
                      {roles.map((role) => (
                        <div
                          key={role.id}
                          className={`cursor-pointer relative text-center p-4 rounded-lg transition-all duration-300 ease-in-out transform hover:scale-105 ${
                            selectedRole === role.name
                              ? "bg-blue-600"
                              : "bg-gray-700 hover:bg-gray-600"
                          }`}
                          onClick={() => handleRoleSelection(role)}
                        >
                          <img
                            src={role.icon}
                            alt={role.name}
                            className="w-12 h-12 mx-auto mb-2"
                          />
                          <div className="text-white text-sm">{role.name}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-800 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Role;