import React, { useState, useEffect } from "react";
import Axios from "axios";
import TitleContainer from "../../components/TitleContainer";
import Container from "../../components/Container";
import { Swords, Filter } from "lucide-react";
import Loader from "../../components/Loader";
import ScrimMatchCard from "./ScrimMatchCard";
const ScrimMatches = ({ userData }) => {
  const [matches, setMatches] = useState([]);
  const [filterStatus, setFilterStatus] = useState("PENDIENTE");
  const [isLoading, setIsLoading] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [isWorking, setIsWorking] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    setIsFetching(true);
    Axios.get("/api/my-scrims-schedule")
      .then((response) => {
        setMatches(response.data);
        setIsFetching(false);
      })
      .catch((error) => {
        console.error("Error fetching scrim matches:", error);
        setIsFetching(false);
      });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isCaptainOrSubcaptain = () =>
    userData &&
    userData.team_info &&
    (userData.team_info.is_captain || userData.team_info.is_subcaptain);

  const handleCancel = (matchId) => {
    setIsWorking(true);
    setIsLoading(true);
    Axios.post("/api/cancel-scrim-match/", { id: matchId })
      .then(() => {
        setMatches((prev) => prev.filter((match) => match.id !== matchId));
        setIsWorking(false);
      })
      .catch((error) => {
        console.error("Error canceling scrim match:", error);
        setIsWorking(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Container>
      {isLoading || isFetching || userData === undefined ? (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <Loader />
        </div>
      ) : (
        <div className="flex flex-col gap-2 mt-4">
          <TitleContainer
            text="Scrims agendados"
            icon={<Swords className="w-6 h-6" />}
            color="bg-blue-600"
            flex="start"
          />
          <div className="relative">
            <select
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
              className="appearance-none w-full bg-gray-800 text-white border border-gray-700 rounded-lg py-2 px-4 pr-8 focus:outline-none focus:ring-2 focus:ring-blue-600"
            >
              <option value="PENDIENTE">Pendiente</option>
              <option value="ACEPTADO">Aceptado</option>
              <option value="CANCELADO">Cancelado</option>
            </select>
            <Filter
              className="absolute right-3 top-2.5 text-gray-400 pointer-events-none"
              size={20}
            />
          </div>
          <div className="flex flex-col gap-2">
            {matches
              .filter((match) => match.status === filterStatus)
              .map((match, index) => (
                <div key={index}>
                  <ScrimMatchCard
                    scrim={match}
                    handleCancelClick={() => handleCancel(match.id)}
                    isCaptain={isCaptainOrSubcaptain}
                    isWorking={isWorking}
                  />
                </div>
              ))}
          </div>
        </div>
      )}
    </Container>
  );
};

export default ScrimMatches;
