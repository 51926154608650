import React, { useState } from "react";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";

const ReceiveRequestsSelection = ({ receiveOffers, setReceiveOffers }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isOpenToInvites, setIsOpenToInvites] = useState(receiveOffers);

  const handleInviteOptionChange = (option) => {
    setIsOpenToInvites(option);
  };

  const handleModalOpen = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const handleSelectOption = () => {
    setReceiveOffers(isOpenToInvites);
    handleModalClose();
  };

  const options = [
    {
      id: true,
      label: "Yes",
      icon: <AiFillCheckCircle size={60} color="green" />,
    },
    {
      id: false,
      label: "No",
      icon: <AiFillCloseCircle size={60} color="red" />,
    },
  ];

  const selectedOption = options.find(
    (option) => option.id === isOpenToInvites
  );

  return (
    <div className="relative">
      <div
        className="p-4 relative inset-0 flex flex-col items-center cursor-pointer"
        onClick={handleModalOpen}
      >
        <div className="bg-black bg-opacity-50 p-4 rounded w-36 h-36 marker:cursor-pointer flex flex-col items-center justify-center text-center">
          {selectedOption ? (
            selectedOption.icon
          ) : (
            <AiFillCheckCircle size={60} color="grey" className="mx-auto" />
          )}
          <span className="mb-2 text-white lg:text-2xl flex items-center">
            Receive applications
          </span>
        </div>
      </div>

      {isModalVisible && (
        <div className="modal fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-90">
          <div className="modal-content bg-fifth p-4 rounded-xl">
            <div className="mb-4 text-white font-bold text-xl text-center">
              Select an option
            </div>
            <div className="grid grid-cols-2 gap-4">
              {options.map((option) => (
                <div
                  key={option.id}
                  className={`region-item cursor-pointer relative text-center mx-auto ${
                    isOpenToInvites === option.id ? "opacity-100" : "opacity-50"
                  }`}
                  onClick={() => {
                    handleInviteOptionChange(option.id);
                  }}
                >
                  <div className="text-center">{option.icon}</div>
                  <div className="mb-2 text-white mx-auto text-lg">
                    {option.label}
                  </div>
                </div>
              ))}
            </div>
            <div className="text-center mt-4">
              <button
                className="modal-button bg-primary rounded-md text-white font-bold text-xs py-2 px-4 border-2 border-transparent sm:text-base hover:border-2 hover:border-yellow-500 hover:border-opacity-60"
                onClick={handleSelectOption}
              >
                Select
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReceiveRequestsSelection;
