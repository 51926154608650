import React, { useState, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import Images from "../../components/ImagesContainer";
import Flag from "react-world-flags";
import {
  challenger,
  grandmaster,
  master,
  diamond,
  emerald,
  platinum,
  gold,
  silver,
  bronce,
  iron,
  unranked
} from "../../components/AssetsBarrel";
import { ChevronDown, ChevronUp, Calendar, Clock } from "lucide-react";
import { PlayerCard } from "../../components/PlayerCard";
import CardLoader from "../../components/CardLoader";

const MyScrimCard = ({
  scrim,
  handleCancelClick,
  handleAcceptClick,
  isCaptain,
  isCancelling,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [teamData, setTeamData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const goToTeamPage = useCallback((teamName) => {
    navigate(`/league/equipos/${teamName}`);
  }, [navigate]);

  const fetchTeamData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`/api/team/${scrim.team}`);
      const data = await response.json();

      const scrimPlayersResponse = await Axios.get(
        `/api/scrim-request-players/${scrim.id}/`
      );
      const scrimPlayers = scrimPlayersResponse.data.map((player) => player.id);

      const filteredMembers = data.members.filter((member) =>
        scrimPlayers.includes(member.id)
      );

      setTeamData({ ...data, members: filteredMembers });
    } catch (error) {
      console.error("Error fetching team data:", error);
    } finally {
      setIsLoading(false);
    }
  }, [scrim.team, scrim.id]);

  const handleChevronClick = useCallback(() => {
    if (!expanded && !teamData) {
      fetchTeamData();
    }
    setExpanded((prev) => !prev);
  }, [expanded, teamData, fetchTeamData]);

  const getPlayerType = useCallback((discordTag, captain, subcaptains, staff) => {
    if (discordTag === captain) return "captain";
    if (subcaptains.includes(discordTag)) return "subcaptain";
    if (staff.includes(discordTag)) return "staff";
    return "player";
  }, []);

  const renderPlayerCards = useMemo(() => {
    if (!teamData || !teamData.members) return null;

    const members = teamData.members.filter((member) =>
      teamData.players.includes(member.discord_tag)
    );
    const captain = teamData.captain && teamData.captain[0];
    const subcaptains = teamData.subcaptains;

    return members.map((member, index) => (
      <PlayerCard
        key={index}
        member={getPlayerType(member.discord_tag, captain, subcaptains, teamData.staff)}
        name={member.discord_tag}
        role={member.role}
        region={member.region}
        soloq_account={member.soloq_account__summonerName}
        elo={`${member.soloq_account__tier} ${member.soloq_account__rank}`}
        tier={member.soloq_account__tier}
        rank={member.soloq_account__rank}
        lp={member.soloq_account__leaguePoints}
        active={member.is_active}
        summoner={member.soloq_account__summonerName}
        user_id={member.user_id}
        avatar_id={member.avatar_id}
        coins={member.coins}
      />
    ));
  }, [teamData, getPlayerType]);

  const getRankImage = useCallback((rating) => {
    const numericRating = parseFloat(rating);
    if (isNaN(numericRating)) return iron;
    if (numericRating >= 10.0) return challenger;
    if (numericRating >= 9.0) return grandmaster;
    if (numericRating >= 8.0) return master;
    if (numericRating >= 7.0) return diamond;
    if (numericRating >= 6.0) return emerald;
    if (numericRating >= 5.0) return platinum;
    if (numericRating >= 4.0) return gold;
    if (numericRating >= 3.0) return silver;
    if (numericRating >= 2.0) return bronce;
    return iron;
  }, []);

  const formatRating = (rating) => {
    const numericRating = parseFloat(rating);
    return isNaN(numericRating) ? 'N/A' : numericRating.toFixed(2);
  };

  const convertTime = useCallback((time, fromZone, toZone) => {
    const [hours, minutes] = time.split(':');
    const date = new Date();
    date.setHours(parseInt(hours), parseInt(minutes));

    const options = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZone: toZone,
    };

    return new Intl.DateTimeFormat('en-US', options).format(date);
  }, []);

  const convertDate = useCallback((dateString, timeString, fromZone, toZone) => {
    const [year, month, day] = dateString.split('-');
    const [hours, minutes] = timeString.split(':');
    const date = new Date(Date.UTC(year, month - 1, day, hours, minutes));

    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZone: toZone,
    };

    return new Intl.DateTimeFormat('es-MX', options).format(date).split(',')[0];
  }, []);

  const formatTime = (time) => {
    return time.slice(0, 5); // This will return the first 5 characters, e.g., "02:00"
  };

  const mexicoTime = useMemo(() => formatTime(scrim.time), [scrim.time]);
  const argentinaTime = useMemo(() => convertTime(scrim.time, 'America/Mexico_City', 'America/Argentina/Buenos_Aires'), [scrim.time, convertTime]);
  const mexicoDate = useMemo(() => scrim.date, [scrim.date]);
  const argentinaDate = useMemo(() => convertDate(scrim.date, scrim.time, 'America/Mexico_City', 'America/Argentina/Buenos_Aires'), [scrim.date, scrim.time, convertDate]);

  return (
    <div className="bg-gradient-to-r from-gray-800 to-gray-900 rounded-lg shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl hover:border-2 hover:border-yellow-500">
      <div className="p-6">
        <div className="flex justify-between items-center">
          <div className="flex flex-col items-center">
            <Calendar className="w-6 h-6 text-gray-400" />
            <div className="flex flex-col items-center mt-2">
              <div className="flex items-center gap-2">
                <span className="text-white text-sm">{mexicoDate}</span>
                <Flag code="mex" width={16} />
              </div>
              <div className="flex items-center gap-2">
                <span className="text-white text-sm">{argentinaDate}</span>
                <Flag code="arg" width={16} />
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center">
            <img
              src={Images[scrim.team_image]}
              alt={scrim.team}
              className="w-16 h-16 rounded-full object-cover"
            />
            <button
              onClick={() => goToTeamPage(scrim.team)}
              className="mt-2 text-lg font-semibold text-white hover:text-yellow-500 transition-colors"
            >
              {scrim.team}
            </button>
            <div className="flex items-center mt-2">
              <img
                src={getRankImage(scrim.average_rating)}
                alt="Average Rank"
                className="w-8 h-8 mr-2"
              />
              <span className="text-white font-semibold">{formatRating(scrim.average_rating)}</span>
            </div>
          </div>

          <div className="flex flex-col items-center">
            <Clock className="w-6 h-6 text-gray-400" />
            <div className="flex flex-col items-center mt-2">
              <div className="flex items-center gap-2">
                <span className="text-white text-sm">{mexicoTime}</span>
                <Flag code="mex" width={16} />
              </div>
              <div className="flex items-center gap-2">
                <span className="text-white text-sm">{argentinaTime}</span>
                <Flag code="arg" width={16} />
              </div>
            </div>
          </div>
        </div>

        {isCaptain() && (
          <div className="mt-6 flex justify-end gap-4">
            {handleAcceptClick && (
              <button
                className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition-colors"
                onClick={handleAcceptClick}
              >
                Aceptar
              </button>
            )}
            {handleCancelClick && (
              <button
                className={`bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition-colors ${
                  isCancelling ? "opacity-50 cursor-not-allowed" : ""
                }`}
                onClick={handleCancelClick}
                disabled={isCancelling}
              >
                Cancelar
              </button>
            )}
          </div>
        )}
      </div>
      <div className="border-t border-gray-700">
        <button
          onClick={handleChevronClick}
          className="w-full py-3 px-6 text-left text-gray-400 hover:text-white transition-colors flex items-center justify-between"
          aria-expanded={expanded}
          aria-label={expanded ? "Collapse details" : "Expand details"}
        >
          <span className="font-semibold">Team Details</span>
          {expanded ? (
            <ChevronUp className="w-5 h-5" />
          ) : (
            <ChevronDown className="w-5 h-5" />
          )}
        </button>
      </div>
      {expanded && (
        <div className="p-6 space-y-4 bg-gray-800">
          {isLoading ? <CardLoader /> : renderPlayerCards}
        </div>
      )}
    </div>
  );
};

export default MyScrimCard;