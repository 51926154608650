import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Search, Globe, Users, Briefcase, Flag } from 'lucide-react';
import Modal from "react-modal";
import Loader from "../../components/Loader";
import CardsHeading from "../../components/CardsHeading";
import AgentCard from "./AgentCard";
import ActionButton from '../../components/ActionButton'
import AgentListHeading from "./AgentListHeading";

const overlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.7)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
};

const AgentsList = ({ agents, userData, user, isLoading, invitations }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [region, setRegion] = useState("ALL");
  const [role, setRole] = useState("ALL");
  const [status, setStatus] = useState("ALL");
  const [country, setCountry] = useState("ALL");
  const [receivingOffers, setReceivingOffers] = useState("ALL");
  const [filteredAgents, setFilteredAgents] = useState([]);
  const [invitationStatus, setInvitationStatus] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [isInviting, setIsInviting] = useState(false);

  // New state for filter options
  const [filterOptions, setFilterOptions] = useState({
    regions: [],
    roles: [],
    statuses: [],
    countries: [],
  });

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModalWithContent = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (!agents || agents.length === 0) {
      return; 
    }

    // Generate filter options
    const regions = [...new Set(agents.map(agent => agent.region))];
    const roles = [...new Set(agents.map(agent => agent.role))];
    const statuses = [...new Set(agents.map(agent => agent.status))];
    const countries = [...new Set(agents.map(agent => agent.country))];

    setFilterOptions({
      regions,
      roles,
      statuses,
      countries,
    });

    // Filter agents
    let result = agents;

    if (searchTerm) {
      result = result.filter((agent) =>
        agent.discord_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (region !== "ALL") {
      result = result.filter((agent) => agent.region === region);
    }

    if (role !== "ALL") {
      result = result.filter((agent) => agent.role === role);
    }

    if (status !== "ALL") {
      result = result.filter((agent) => agent.status === status);
    }

    if (country !== "ALL") {
      result = result.filter((agent) => agent.country === country);
    }

    if (receivingOffers !== "ALL") {
      result = result.filter((agent) => agent.receiving_offers === (receivingOffers === "YES"));
    }

    setFilteredAgents(result);
  }, [searchTerm, region, role, status, country, receivingOffers, agents]);

  const sendInvitation = (agentId, agentName) => {
    setIsInviting(true);

    const data = {
      invited_player_id: agentId,
    };

    Axios.post("/team-invite/create/", data)
      .then((response) => {
        setInvitationStatus((prev) => ({ ...prev, [Number(agentId)]: "invited" }));
        openModalWithContent(`Has invitado a ${agentName} exitosamente!`);
      })
      .catch((error) => {
        console.error("Error sending invitation:", error);
        if (
          error.response &&
          error.response.data.detail ===
            "Ya existe una invitación pendiente para este usuario en el mismo equipo."
        ) {
          setInvitationStatus((prev) => ({
            ...prev,
            [agentId]: "already_invited",
          }));
        }
      })
      .finally(() => {
        setIsInviting(false);
      });
  };

  if (isLoading) {
    return (
      <div style={overlayStyle}>
        <Loader />
      </div>
    );
  }

  if (!isLoading && agents.length === 0) {
    return (
      <div className="text-center text-white mt-4">
        No free agents available.
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 bg-gray-900 p-6 rounded-lg">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
        <div className="relative">
          <input
            className="w-full bg-gray-800 text-white border border-gray-700 rounded-lg py-2 px-4 pl-10 focus:outline-none focus:ring-2 focus:ring-blue-600"
            type="text"
            placeholder="Search Agent"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
        </div>
        <div className="relative">
          <select
            value={region}
            onChange={(e) => setRegion(e.target.value)}
            className="appearance-none w-full bg-gray-800 text-white border border-gray-700 rounded-lg py-2 px-4 pr-8 focus:outline-none focus:ring-2 focus:ring-blue-600"
          >
            <option value="ALL">All Regions</option>
            {filterOptions.regions.map((r) => (
              <option key={r} value={r}>{r}</option>
            ))}
          </select>
          <Globe className="absolute right-3 top-2.5 text-gray-400 pointer-events-none" size={20} />
        </div>
        <div className="relative">
          <select
            value={role}
            onChange={(e) => setRole(e.target.value)}
            className="appearance-none w-full bg-gray-800 text-white border border-gray-700 rounded-lg py-2 px-4 pr-8 focus:outline-none focus:ring-2 focus:ring-blue-600"
          >
            <option value="ALL">All Roles</option>
            {filterOptions.roles.map((r) => (
              <option key={r} value={r}>{r}</option>
            ))}
          </select>
          <Users className="absolute right-3 top-2.5 text-gray-400 pointer-events-none" size={20} />
        </div>
        <div className="relative">
          <select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className="appearance-none w-full bg-gray-800 text-white border border-gray-700 rounded-lg py-2 px-4 pr-8 focus:outline-none focus:ring-2 focus:ring-blue-600"
          >
            <option value="ALL">All Status</option>
            {filterOptions.statuses.map((s) => (
              <option key={s} value={s}>{s === "SIN_EQUIPO" ? "No Team" : "In Team"}</option>
            ))}
          </select>
          <Briefcase className="absolute right-3 top-2.5 text-gray-400 pointer-events-none" size={20} />
        </div>
        <div className="relative">
          <select
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            className="appearance-none w-full bg-gray-800 text-white border border-gray-700 rounded-lg py-2 px-4 pr-8 focus:outline-none focus:ring-2 focus:ring-blue-600"
          >
            <option value="ALL">All Countries</option>
            {filterOptions.countries.map((c) => (
              <option key={c} value={c}>{c}</option>
            ))}
          </select>
          <Flag className="absolute right-3 top-2.5 text-gray-400 pointer-events-none" size={20} />
        </div>
        <div className="relative">
          <select
            value={receivingOffers}
            onChange={(e) => setReceivingOffers(e.target.value)}
            className="appearance-none w-full bg-gray-800 text-white border border-gray-700 rounded-lg py-2 px-4 pr-8 focus:outline-none focus:ring-2 focus:ring-blue-600"
          >
            <option value="ALL">Receiving Offers</option>
            <option value="YES">Yes</option>
            <option value="NO">No</option>
          </select>
          <Users className="absolute right-3 top-2.5 text-gray-400 pointer-events-none" size={20} />
        </div>
      </div>

      <AgentListHeading />

      {isInviting && (
        <div style={overlayStyle}>
          <Loader />
        </div>
      )}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Invitation Modal"
        className="bg-gray-800 p-6 rounded-xl text-white"
        style={{
          overlay: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
          },
          content: {
            position: "relative",
            top: "auto",
            right: "auto",
            bottom: "auto",
            left: "auto",
            maxWidth: "80%",
            width: "400px",
          },
        }}
      >
        <div className="flex flex-col items-center gap-4">
          <h2 className="text-xl font-bold">Invitation Status</h2>
          <div>{modalContent}</div>
          <ActionButton onClick={closeModal}>Close</ActionButton>
        </div>
      </Modal>

      <div className="space-y-2">
        {filteredAgents.map((agent, index) => (
          <AgentCard
            key={index}
            name={agent.discord_name}
            id={agent.user_id}
            agentId={agent.id}
            avatar={agent.avatar_id}
            role={agent.role}
            region={agent.region}
            tier={agent.tier}
            rank={agent.rank}
            lp={agent.league_points}
            summoner={agent.summoner_name}
            coins={agent.coins}
            invitationStatus={invitationStatus[Number(agent.id)]}
            onClick={() => sendInvitation(agent.id, agent.discord_name)}
            userData={userData}
            user={user}
            invitations={invitations}
            teamName={agent.team_name}
            teamImage={agent.team_image}
            country={agent.country}
            receivingOffers={agent.receiving_offers}
          />
        ))}
      </div>
    </div>
  );
};

export default AgentsList;