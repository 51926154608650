import React, { useState, useEffect } from "react";
import Axios from "axios";
import TitleContainer from "../../components/TitleContainer";
import Container from "../../components/Container";
import { LuSwords } from "react-icons/lu";
import ScrimList from "./ScrimList";
import ScrimCreate from "./ScrimCreate";
import Loader from "../../components/Loader";

const Index = ({ userData }) => {
  const [scrimList, setScrimList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState("PENDIENTE");

  useEffect(() => {
    const fetchScrims = async () => {
      setIsLoading(true);
      try {
        const response = await Axios.get(`/api/scrims/?status=${status}`);
        setScrimList(response.data);
      } catch (error) {
        console.error("Error fetching scrims:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchScrims();
  }, [status]);

  const isInTeam =
    userData && userData.in_team !== undefined && userData.in_team;
  const isCaptainOrSubCaptain =
    userData &&
    userData.team_info &&
    (userData.team_info.is_captain || userData.team_info.is_subcaptain);

  const handleStatusChange = (newStatus) => {
    setStatus(newStatus);
  };

  return (
    <Container>
      <div className="flex flex-col gap-2 mt-4">
        <TitleContainer
          text={
            "Scrim Wars: Partidas personalizadas 5 vs 5 entre dos equipos, que simula un escenario competitivo"
          }
          icon={<LuSwords className="w-6 h-6 text-white" />}
          color="bg-gradient-to-r from-blue-900 to-blue-700"
          flex="start"
        />
        {isLoading && (
          <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
            <Loader />
          </div>
        )}
        {isInTeam && isCaptainOrSubCaptain && (
          <div className="flex flex-col gap-2">
            <ScrimCreate />
          </div>
        )}

        <div className="flex justify-center space-x-4 my-4">
          <button
            onClick={() => handleStatusChange("PENDIENTE")}
            className={`px-4 py-2 rounded-md text-sm font-medium transition-colors ${
              status === "PENDIENTE"
                ? "bg-blue-600 text-white"
                : "bg-gray-200 text-gray-800 hover:bg-gray-300"
            }`}
          >
            Solicitudes de Scrims
          </button>
          <button
            onClick={() => handleStatusChange("ACEPTADO")}
            className={`px-4 py-2 rounded-md text-sm font-medium transition-colors ${
              status === "ACEPTADO"
                ? "bg-blue-600 text-white"
                : "bg-gray-200 text-gray-800 hover:bg-gray-300"
            }`}
          >
            Scrims Activos
          </button>
        </div>

        <ScrimList scrims={scrimList} userData={userData} />
      </div>
    </Container>
  );
};

export default Index;