import React from 'react'
import { useNavigate } from 'react-router-dom'
const Logo = () => {
    const navigate = useNavigate()
  return (
    <div>
        <img src={require('../../assets/logo.webp')} alt='logo'
        width={40} height={40}  onClick={()=>navigate('/')}
        className='
        rounded-full
        cursor-pointer
        w-5 h-5 sm:w-6 sm:h-6 md:w-7 md:h-7 lg:w-8 lg:h-8 xl:w-10 xl:h-10
        '/>
    </div>
  )
}

export default Logo