import React, { useState, useEffect } from "react";
import { HelpCircle, X } from "lucide-react";

const SelectGame = ({ selectedGame, games, setGame }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedGameId, setSelectedGameId] = useState(selectedGame || null);

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const selectGame = (game) => {
    if (game.enabled) {
      setSelectedGameId(game.id);
      setGame(game.id);
      closeModal();
    }
  };

  useEffect(() => {
    if (selectedGame) {
      setSelectedGameId(selectedGame);
    }
  }, [selectedGame]);

  return (
    <div className="relative">
      <div
        className="p-4 relative inset-0 flex flex-col items-center cursor-pointer"
        onClick={openModal}
      >
        <div className="bg-gray-800 bg-opacity-90 p-4 rounded-lg w-full h-40 sm:h-48 md:h-56 lg:h-64 flex flex-col items-center justify-center text-center transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg">
          {selectedGameId ? (
            <img
              src={games.find((game) => game.id === selectedGameId).logo}
              alt={games.find((game) => game.id === selectedGameId).name}
              className="w-16 h-16 sm:w-20 sm:h-20 md:w-24 md:h-24 lg:w-28 lg:h-28 mb-4"
            />
          ) : (
            <div className="mb-4">
              <HelpCircle size={48} className="text-white" />
            </div>
          )}
          <span className="text-white text-lg sm:text-xl md:text-2xl lg:text-3xl font-semibold">
            {selectedGameId
              ? games.find((game) => game.id === selectedGameId).name
              : "Select Game"}
          </span>
        </div>
      </div>
      {isModalVisible && (
        <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" onClick={closeModal}></div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                    <h3 className="text-lg leading-6 font-medium text-white mb-4" id="modal-title">
                      Select Game
                    </h3>
                    <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                      {games.map((game) => (
                        <div
                          key={game.id}
                          className={`cursor-pointer relative text-center p-4 rounded-lg transition-all duration-300 ease-in-out transform hover:scale-105 ${
                            game.enabled
                              ? selectedGameId === game.id
                                ? "bg-blue-600"
                                : "bg-gray-700 hover:bg-gray-600"
                              : "bg-gray-700 opacity-50 pointer-events-none"
                          }`}
                          onClick={() => selectGame(game)}
                        >
                          <img
                            src={game.logo}
                            alt={game.name}
                            className="w-16 h-16 mx-auto mb-2"
                          />
                          <div className="text-white text-sm">
                            {game.enabled ? game.name : "Coming Soon"}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-800 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectGame;