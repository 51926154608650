import React from "react";

const Container = ({ children }) => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 text-white">
      <div className="container mx-auto px-4 py-8">
      {children}

      </div>
    </div>
  );
};

export default Container;
