import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import Images from "../../components/ImagesContainer";
import Flag from "react-world-flags";
import { ChevronDown, ChevronUp, Calendar, Clock } from "lucide-react";
import { PlayerCard } from "../../components/PlayerCard";
import CardLoader from "../../components/CardLoader";

const ScrimMatchCard = ({ scrim, handleCancelClick, isCaptain, isWorking }) => {
  const [expanded, setExpanded] = useState(false);
  const [teamData, setTeamData] = useState(null);
  const [teamData2, setTeamData2] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const goToTeamPage = useCallback((teamName) => {
    navigate(`/league/equipos/${teamName}`);
  }, [navigate]);

  const fetchTeamData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`/api/team/${scrim.team1}`);
      const data = await response.json();
      const scrimPlayersResponse = await Axios.get(`/api/scrim-match-players/${scrim.id}/`);
      const scrimPlayers = scrimPlayersResponse.data.team1.map((player) => player.id);
      const filteredMembers = data.members.filter((member) => scrimPlayers.includes(member.id));
      setTeamData({ ...data, members: filteredMembers });
    } catch (error) {
      console.error("Error fetching team data:", error);
    } finally {
      setIsLoading(false);
    }
  }, [scrim.team1, scrim.id]);

  const fetchTeamData2 = useCallback(async () => {
    try {
      const response = await fetch(`/api/team/${scrim.team2}`);
      const data = await response.json();
      const scrimPlayersResponse = await Axios.get(`/api/scrim-match-players/${scrim.id}/`);
      const scrimPlayers = scrimPlayersResponse.data.team2.map((player) => player.id);
      const filteredMembers = data.members.filter((member) => scrimPlayers.includes(member.id));
      setTeamData2({ ...data, members: filteredMembers });
    } catch (error) {
      console.error("Error fetching team data:", error);
    }
  }, [scrim.team2, scrim.id]);

  const handleChevronClick = useCallback(() => {
    if (!expanded && !teamData) {
      fetchTeamData();
      fetchTeamData2();
    }
    setExpanded((prev) => !prev);
  }, [expanded, teamData, fetchTeamData, fetchTeamData2]);

  const getPlayerType = useCallback((discordTag, captain, subcaptains, staff) => {
    if (discordTag === captain) return "captain";
    if (subcaptains.includes(discordTag)) return "subcaptain";
    if (staff.includes(discordTag)) return "staff";
    return "player";
  }, []);

  const renderPlayerCards = useCallback((data) => {
    if (!data || !data.members) return null;
    const members = data.members.filter((member) => data.players.includes(member.discord_tag));
    const captain = data.captain && data.captain[0];
    const subcaptains = data.subcaptains;
    return members.map((member, index) => (
      <PlayerCard
        key={index}
        member={getPlayerType(member.discord_tag, captain, subcaptains, data.staff)}
        name={member.discord_tag}
        role={member.role}
        region={member.region}
        soloq_account={member.soloq_account__summonerName}
        elo={`${member.soloq_account__tier} ${member.soloq_account__rank}`}
        tier={member.soloq_account__tier}
        rank={member.soloq_account__rank}
        lp={member.soloq_account__leaguePoints}
        active={member.is_active}
        summoner={member.soloq_account__summonerName}
        user_id={member.user_id}
        avatar_id={member.avatar_id}
        coins={member.coins}
      />
    ));
  }, [getPlayerType]);

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${day}/${month}/${year}`;
  };

  const simplifyTime = (timeString) => {
    return timeString.slice(0, 5);
  };

  const convertToArgentinaDateTime = (mexicoDateString, mexicoTimeString) => {
    const [year, month, day] = mexicoDateString.split('-');
    const [hours, minutes] = mexicoTimeString.split(':');
    const mexicoDate = new Date(year, month - 1, day, hours, minutes);
    mexicoDate.setHours(mexicoDate.getHours() + 3);
    const argentinaDate = `${mexicoDate.getDate().toString().padStart(2, '0')}/${(mexicoDate.getMonth() + 1).toString().padStart(2, '0')}/${mexicoDate.getFullYear()}`;
    const argentinaTime = `${mexicoDate.getHours().toString().padStart(2, '0')}:${mexicoDate.getMinutes().toString().padStart(2, '0')}`;
    return { date: argentinaDate, time: argentinaTime };
  };

  const mexicoDate = formatDate(scrim.date);
  const mexicoTime = simplifyTime(scrim.time);
  const { date: argentinaDate, time: argentinaTime } = convertToArgentinaDateTime(scrim.date, scrim.time);

  return (
    <div className="bg-gradient-to-r from-gray-800 to-gray-900 rounded-lg shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl hover:border-2 hover:border-yellow-500">
      <div className="p-6">
        <div className="flex flex-col items-center gap-6">
          <div className="flex justify-between items-center w-full">
            <div className="flex items-center gap-4">
              <Calendar className="w-8 h-8 text-gray-400" />
              <div className="flex flex-col">
                <div className="flex items-center gap-2">
                  <span className="text-white text-lg">{mexicoDate}</span>
                  <Flag code="mex" className="w-6 h-6" />
                </div>
                <div className="flex items-center gap-2">
                  <span className="text-white text-lg">{argentinaDate}</span>
                  <Flag code="arg" className="w-6 h-6" />
                </div>
              </div>
            </div>
            <div className="flex items-center gap-4">
              <Clock className="w-8 h-8 text-gray-400" />
              <div className="flex flex-col">
                <div className="flex items-center gap-2">
                  <span className="text-white text-lg">{mexicoTime}</span>
                  <Flag code="mex" className="w-6 h-6" />
                </div>
                <div className="flex items-center gap-2">
                  <span className="text-white text-lg">{argentinaTime}</span>
                  <Flag code="arg" className="w-6 h-6" />
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center gap-8 w-full">
            <div className="flex flex-col items-center">
              <img
                src={Images[scrim.team_image1]}
                alt={scrim.team1}
                className="w-20 h-20 rounded-full object-cover"
              />
              <button
                onClick={() => goToTeamPage(scrim.team1)}
                className="mt-2 text-lg font-semibold text-white hover:text-yellow-500 transition-colors"
              >
                {scrim.team1}
              </button>
            </div>
            <span className="text-4xl font-bold text-white">VS</span>
            <div className="flex flex-col items-center">
              <img
                src={Images[scrim.team_image2]}
                alt={scrim.team2}
                className="w-20 h-20 rounded-full object-cover"
              />
              <button
                onClick={() => goToTeamPage(scrim.team2)}
                className="mt-2 text-lg font-semibold text-white hover:text-yellow-500 transition-colors"
              >
                {scrim.team2}
              </button>
            </div>
          </div>
        </div>
        {isCaptain() && handleCancelClick && (
          <div className="mt-6 flex justify-end">
            <button
              className={`bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition-colors ${
                isWorking ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={handleCancelClick}
              disabled={isWorking}
            >
              Cancelar
            </button>
          </div>
        )}
      </div>
      <div className="border-t border-gray-700">
        <button
          onClick={handleChevronClick}
          className="w-full py-3 px-6 text-left text-gray-400 hover:text-white transition-colors flex items-center justify-between"
          aria-expanded={expanded}
          aria-label={expanded ? "Collapse details" : "Expand details"}
        >
          <span className="font-semibold">Team Details</span>
          {expanded ? (
            <ChevronUp className="w-5 h-5" />
          ) : (
            <ChevronDown className="w-5 h-5" />
          )}
        </button>
      </div>
      {expanded && (
        <div className="p-6 space-y-4 bg-gray-800">
          {isLoading ? (
            <CardLoader />
          ) : (
            <>
              <div className="space-y-4">
                <h3 className="text-lg font-semibold text-white">{scrim.team1}</h3>
                {renderPlayerCards(teamData)}
              </div>
              <hr className="border-t border-gray-700 my-6" />
              <div className="space-y-4">
                <h3 className="text-lg font-semibold text-white">{scrim.team2}</h3>
                {renderPlayerCards(teamData2)}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ScrimMatchCard;