import React from "react";
import './Loader.css'
const Loader = () => {
  return (
    <div class="spinner">
    <div class="spinner1"></div>
</div>
  );
};

export default Loader;
