import React from "react";
import { useNavigate } from 'react-router-dom';

const TitleContainer = ({ text, icon, color, flex, link }) => {
    const navigate = useNavigate();

    const handleClick = () => {
      if (link) {
        navigate(link);
      }
    };

    return (
        <div 
            className={`
                relative w-full p-4 rounded-lg
                ${color}
                ${flex === 'end' ? 'justify-end' : 'justify-start'} 
                ${link ? 'cursor-pointer' : 'cursor-default'}
                flex items-center
                transition-all duration-300 ease-in-out
                hover:shadow-[0_0_0_1px_rgba(56,189,248,0.4)]
                group
            `}
            onClick={handleClick}
        >
            <div 
                className="
                    absolute inset-0 rounded-lg opacity-0 group-hover:opacity-100
                    transition-opacity duration-300 ease-in-out pointer-events-none
                "
                style={{
                    boxShadow: '0 0 0 1px rgba(56, 189, 248, 0.2), 0 0 0 4px rgba(56, 189, 248, 0.1)',
                }}
            />
            <div 
                className={`
                    relative z-10 flex items-center gap-3 w-full
                    ${flex === 'end' ? 'flex-row-reverse' : 'flex-row'}
                `}
            >
                {icon}
                <h2 className="text-lg font-semibold text-white">{text}</h2>
            </div>
        </div>
    );
};

export default TitleContainer;