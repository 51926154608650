import React from "react";
import { BsArrowRight } from "react-icons/bs";

const CardsHeading = ({ titles }) => {
  return (
    <div className="flex flex-col md:grid md:grid-cols-12 gap-2 items-center py-2 px-4 text-white text-xs font-semibold uppercase tracking-wider bg-indigo-900 rounded-t-lg">
      <div className="w-full md:col-span-2 mb-2 md:mb-0">{titles[0]}</div>
      <div className="w-full md:col-span-2 mb-2 md:mb-0">{titles[1]}</div>
      <div className="w-full md:col-span-2 mb-2 md:mb-0">{titles[2]}</div>
      <div className="w-full flex justify-between items-center md:col-span-6 md:grid md:grid-cols-3">
        <div className="flex items-center justify-center">
          {titles[3]} <BsArrowRight className="ml-1 hidden md:inline" />
        </div>
        <div className="md:col-span-1 text-center">{titles[4]}</div>
        <div className="flex items-center justify-center">
          {titles[5]} <BsArrowRight className="ml-1 hidden md:inline" />
        </div>
      </div>
    </div>
  );
};

export default CardsHeading;