import React from "react";

const Button = ({ children, primary, disabled, onClick, customStyle, isVerified }) => {
  const baseColorClass = isVerified ? "bg-lime-700" : "bg-primary";
  
  const buttonClasses = [
    "modal-button",
    "rounded-lg",
    "text-white",
    "font-bold",
    "py-2",
    "px-4",
    "transition-all",
    "duration-300",
    "ease-in-out",
    "text-xs",
    "sm:text-base",
    baseColorClass,
    customStyle,
  ];

  if (primary) {
    buttonClasses.push("border-2", "border-transparent", "hover:border-yellow-500", "hover:border-opacity-60");
  }

  if (disabled) {
    if (isVerified) {
      buttonClasses.push("cursor-not-allowed", "opacity-50");
    } else {
      buttonClasses.push("opacity-50", "cursor-not-allowed", "bg-gray-400", "hover:bg-gray-400");
    }
  } else {
    buttonClasses.push("hover:brightness-110");
  }

  return (
    <button
      onClick={onClick}
      className={buttonClasses.join(" ")}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;