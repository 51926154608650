import React from "react";
import { FaArrowRightArrowLeft } from "react-icons/fa6";
import { SiMcdonalds } from "react-icons/si";
import {
  top, jungler, mid, adc, support, coach, analyst,
  challenger, grandmaster, master, diamond, emerald, platinum, gold, silver, bronce, iron, unranked
} from "./AssetsBarrel";

const TransferCard = ({
  date,
  player,
  discordId,
  discordAvatar,
  tier,
  rank,
  lp,
  summonerName,
  role,
  fa,
  from,
  to,
  region,
}) => {
  const tierImages = {
    CHALLENGER: challenger, GRANDMASTER: grandmaster, MASTER: master,
    DIAMOND: diamond, EMERALD: emerald, PLATINUM: platinum,
    GOLD: gold, SILVER: silver, BRONCE: bronce, IRON: iron, UNRANKED : unranked, Unranked : unranked
  };


  const createOpggUrl = (region, summonerName) => {
    // Convertir la región a minúsculas
    const lowerCaseRegion = region.toLowerCase();
  
    // Reemplazar '#' por '-' en el nombre del invocador
    const formattedSummonerName = summonerName.replace('#', '-');
  
    // Construir la URL de OPGG
    const opggUrl = `https://www.op.gg/summoners/${lowerCaseRegion}/${encodeURIComponent(formattedSummonerName)}`;
  
    return opggUrl;
  };
  
  const opggUrl = createOpggUrl(region, summonerName);

  const renderTeam = (team) => {
    if (typeof team === 'object' && team.props) {
      if (team.props.teamName) {
        const teamUrl = `/league/equipos/${encodeURIComponent(team.props.teamName)}`;
  
        return (
          <a href={teamUrl} className="flex flex-col items-center text-center">
            <img
              src={team.props.imgSrc}
              alt={team.props.teamName}
              className="w-6 h-6 md:w-8 md:h-8 rounded-full mb-1"
            />
            <span className="text-xs hidden md:block">{team.props.teamName}</span>
          </a>
        );
      } else {
        return (
          <div className="flex flex-col items-center text-center">
            <SiMcdonalds className="text-yellow-400 text-xl md:text-2xl mb-1" />
            <span className="text-xs hidden md:block">F/A</span>
          </div>
        );
      }
    } else if (team === 'F/A') {
      return (
        <div className="flex flex-col items-center text-center">
          <SiMcdonalds className="text-yellow-400 text-xl md:text-2xl mb-1" />
          <span className="text-xs hidden md:block">F/A</span>
        </div>
      );
    } else {
      return <span>{team}</span>;
    }
  };

  return (
    <div className="flex flex-col md:grid md:grid-cols-12 gap-2 items-center py-3 px-4 text-white text-sm bg-gray-800 hover:bg-gray-750 transition-colors duration-200">
      <div className="w-full md:col-span-2 text-gray-400 text-xs md:text-sm mb-2 md:mb-0">{date}</div>
      <div className="w-full md:col-span-2 flex items-center space-x-2 mb-2 md:mb-0">
        <img
          src={`https://cdn.discordapp.com/avatars/${discordId}/${discordAvatar}.png`}
          alt="Discord Avatar"
          className="w-6 h-6 md:w-8 md:h-8 rounded-full bg-gray-600"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "https://i.redd.it/j3t4cvgywd051.png";
          }}
        />
        <a href={`/view/profile/${player}`} className="font-medium truncate text-xs md:text-sm hover:underline">{player}</a>

      </div>
      <div className="w-full md:col-span-2 flex items-center space-x-2 mb-2 md:mb-0">
        <a href={opggUrl} target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2 hover:text-blue-400">
          <img src={tierImages[tier]} alt={tier} className="w-6 h-6 md:w-8 md:h-8" />
          <div>
            <div className="font-medium text-xs">{summonerName}</div>
            <div className="text-xs text-gray-400">{tier} {rank} {lp} LP</div>
          </div>
        </a>
      </div>
      <div className="w-full flex justify-between items-center md:col-span-6 md:grid md:grid-cols-3">
        <div className="flex justify-center items-center">
          {renderTeam(from)}
        </div>
        <div className="flex items-center justify-center">
          <FaArrowRightArrowLeft className={`text-xl md:text-2xl ${fa ? 'text-green-500' : 'text-red-500'}`} />
        </div>
        <div className="flex justify-center items-center">
          {renderTeam(to)}
        </div>
      </div>
    </div>
  );
};

export default TransferCard;