import React from "react";
import Images from "../../components/ImagesContainer";
import { CheckCircle, XCircle } from 'lucide-react';

const TeamPageCard = ({ teamData }) => {
  return (
    <div className="w-full bg-gray-900 rounded-lg shadow-lg overflow-hidden">
      <div className="p-6 flex flex-col sm:flex-row items-center sm:items-start gap-6">
        <img
          src={Images[teamData.image]}
          alt={teamData.name}
          className="w-32 h-32 sm:w-40 sm:h-40 object-cover rounded-full border-4 border-yellow-500"
        />
        <div className="flex flex-col gap-4 flex-grow">
          <div className="flex flex-col sm:flex-row sm:justify-between items-center sm:items-start">
            <div>
              <h1 className="text-2xl font-bold text-white">{teamData.name}</h1>
              <p className="text-gray-400">{teamData.region}</p>
            </div>
            <div className="flex items-center gap-2 mt-2 sm:mt-0">
              <span className="text-white">
                {teamData.accepting_requests ? "Reclutando" : "No reclutando"}
              </span>
              {teamData.accepting_requests ? (
                <CheckCircle className="text-green-500" size={20} />
              ) : (
                <XCircle className="text-red-500" size={20} />
              )}
            </div>
          </div>
          <p className="text-gray-300 text-sm">
            Creado el {new Date(teamData.created_date).toLocaleDateString()}
          </p>
          <p className="text-gray-300">{teamData.description}</p>
          <div className="flex items-center gap-2">
            <span className="text-white">Verified:</span>
            {teamData.verified ? (
              <CheckCircle className="text-green-500" size={20} />
            ) : (
              <XCircle className="text-red-500" size={20} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamPageCard;