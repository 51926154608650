import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Container from "../../components/Container";
import Loader from "../../components/Loader";
import TitleContainer from "../../components/TitleContainer";
import { RiTeamFill } from "react-icons/ri";
import Heading from "../../components/Heading";
import { PlayerCard } from "../../components/PlayerCard";
import { BOTH, LAN, LAS } from "../profile/AssetsContainer";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import {
  challenger,
  grandmaster,
  master,
  diamond,
  emerald,
  platinum,
  gold,
  silver,
  iron,
  bronce,
  top,
  jungler,
  mid,
  adc,
  support,
  unranked,
} from "../../components/AssetsBarrel";
import TeamPageCard from "./TeamPageCard";
import { XIcon } from "lucide-react";

const TeamPage = ({ user, isUserLoggedIn, userData }) => {
  const { teamName } = useParams();
  const [teamData, setTeamData] = useState(null);
  const [showRequestsModal, setShowRequestsModal] = useState(false);
  const [showInvitesModal, setShowInvitesModal] = useState(false);
  const [noInvitesMessage, setNoInvitesMessage] = useState("");

  const [teamRequests, setTeamRequests] = useState([]);
  const [teamInvites, setTeamInvites] = useState([]);
  const [joined, setJoined] = useState(null);
  const [requestStatus, setRequestStatus] = useState(null);
  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showTeamManagerModal, setShowTeamManagerModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [showRoleUpdateModal, setShowRoleUpdateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [isToggling, setIsToggling] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isJoining, setIsJoining] = useState(false);
  const navigate = useNavigate();
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);

  const fetchTeamData = async () => {
    try {
      const response = await axios.get(`/api/team/${teamName}`);
      setTeamData(response.data);
    } catch (error) {
      console.error("Error fetching team data", error);
    }
  };

  useEffect(() => {
    fetchTeamData();
  }, [teamName]);

  const checkPendingRequest = async () => {
    try {
      const response = await axios.get("/team-requests/pending/me/");

      const pendingRequest = response.data.find(
        (request) => request.team === teamData.name
      );

      if (pendingRequest) {
        setRequestStatus("pending");
      }
    } catch (error) {
      console.error("Error checking for pending requests", error);
    }
  };

  useEffect(() => {
    if (teamData && user && user.has_profile) {
      checkPendingRequest();
    }
  }, [teamData, user]);

  const toggleTeamRequests = async () => {
    setIsToggling(true);
    try {
      const response = await axios.post("/api/update-team-requests/");
      if (response.status === 200) {
        setTeamData((prevData) => ({
          ...prevData,
          accepting_requests: !prevData.accepting_requests,
        }));
      }
    } catch (error) {
      console.error("Error toggling team requests", error);
    } finally {
      setIsToggling(false);
    }
  };

  const toggleTeamInvites = async () => {
    setIsToggling(true);
    try {
      axios
        .get("/team-invites-pending/")
        .then((response) => {
          if (response.data.detail) {
            setNoInvitesMessage(response.data.detail);
            setTeamInvites([]);
          } else {
            setTeamInvites(response.data);
            setNoInvitesMessage("");
            console.log(response.data);
          }
        })
        .catch((error) => {
          console.error("Error al cargar las invitaciones:", error);
        });
    } finally {
      setIsToggling(false);
      setShowInvitesModal(true);
    }
  };

  const deleteTeam = async () => {
    setIsDeleting(true);
    try {
      const response = await axios.post("/api/remove-team-capitan/");
      if (response.status === 200) {
        navigate("/league/equipos");
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        console.error(error.response.data.detail);
      } else {
        console.error("Error deleting the team", error);
      }
    } finally {
      setIsDeleting(false);
      window.location.reload();
    }
  };

  const getRegionIcon = (region) => {
    switch (region) {
      case "LAN":
        return LAN;
      case "LAS":
        return LAS;
      case "BOTH":
        return BOTH;
      default:
        return null;
    }
  };

  const renderPlayerCards = () => {
    if (!teamData || !teamData.members) {
      return null;
    }

    const members = teamData.members;

    const captain = teamData.captain && teamData.captain[0];
    const subcaptains = teamData.subcaptains;

    return members.map((member, index) => {
      const memberType = getPlayerType(
        member.discord_tag,
        captain,
        subcaptains,
        teamData.staff
      );

      return (
        <PlayerCard
          key={index}
          member={memberType}
          name={member.discord_tag}
          role={member.role}
          region={member.region}
          soloq_account={member.soloq_account__summonerName}
          position={index + 1}
          elo={`${member.soloq_account__tier} ${member.soloq_account__rank}`}
          tier={member.soloq_account__tier}
          rank={member.soloq_account__rank}
          lp={member.soloq_account__leaguePoints}
          active={member.is_active}
          summoner={member.soloq_account__summonerName}
          user_id={member.user_id}
          avatar_id={member.avatar_id}
          coins={member.coins}
        />
      );
    });
  };

  const getPlayerType = (discordTag, captain, subcaptains, staff) => {
    if (discordTag === captain) {
      return "captain";
    } else if (subcaptains.includes(discordTag)) {
      return "subcaptain";
    } else if (staff.includes(discordTag)) {
      return "staff";
    } else {
      return "player";
    }
  };

  const joinTeam = async () => {
    try {
      setIsJoining(true);
      const requestData = {
        team_name: teamName,
      };

      const response = await axios.post("/team-requests/create/", requestData);

      setTeamData({ ...teamData, requestSent: true });
      setRequestStatus("pending");
    } catch (error) {
      console.error("Error al crear la solicitud de equipo", error);
    } finally {
      setIsJoining(false);
    }
  };

  const openTeamManagerModal = () => {
    setShowTeamManagerModal(true);
  };

  const closeTeamManagerModal = () => {
    setShowTeamManagerModal(false);
  };
  const openRoleUpdateModal = (member) => {
    setSelectedMember(member);
    setShowRoleUpdateModal(true);
    setShowTeamManagerModal(false);
  };

  const closeRoleUpdateModal = () => {
    setSelectedMember(null);
    setShowRoleUpdateModal(false);
  };
  const openRequestsModal = async () => {
    try {
      const response = await axios.get(`/team-requests/pending/my-team/`);
      setTeamRequests(response.data);
      setShowRequestsModal(true);
    } catch (error) {
      console.error("Error al obtener las solicitudes pendientes", error);
    }
  };

  const openInvitesModal = async () => {
    axios
      .get("/team-invites-pending/")
      .then((response) => {
        if (response.data.detail) {
          // Si la respuesta contiene un mensaje de detalle, significa que no hay invitaciones
          setNoInvitesMessage(response.data.detail);
          setTeamInvites([]); // Limpiamos la lista de invitaciones
        } else {
          // Si la respuesta contiene invitaciones, las cargamos
          setTeamInvites(response.data);
          setNoInvitesMessage(""); // Limpiamos el mensaje de "no invitaciones"
        }
      })
      .catch((error) => {
        console.error("Error al cargar las invitaciones:", error);
      });
  };

  const deleteInvite = (inviteId) => {
    axios
      .delete(`/team-invites/delete/${inviteId}/`)
      .then((response) => {
        // Filtra la invitación eliminada de la lista
        setTeamInvites((prevInvites) =>
          prevInvites.filter((invite) => invite.id !== inviteId)
        );
      })
      .catch((error) => {
        console.error("Error al eliminar la invitación:", error);
      });
  };

  const handleAcceptRequest = async (requestId) => {
    setIsProcessing(true);
    try {
      const response = await axios.post(`/team-requests/accept/`, {
        request_id: requestId,
      });

      if (response.status === 200) {
        fetchTeamData();
        const updatedRequests = teamRequests.filter(
          (request) => request.id !== requestId
        );
        setTeamRequests(updatedRequests);
      }
    } catch (error) {
      console.error("Error al aceptar la solicitud de equipo", error);
    } finally {
      setIsProcessing(false);
    }
  };
  const handleRejectRequest = async (requestId) => {
    setIsProcessing(true);
    try {
      const response = await axios.post(`/team-requests/reject/`, {
        request_id: requestId,
      });

      if (response.status === 200) {
        const updatedRequests = teamRequests.filter(
          (request) => request.id !== requestId
        );
        setTeamRequests(updatedRequests);
      }
    } catch (error) {
      console.error("Error al aceptar la solicitud de equipo", error);
    } finally {
      setIsProcessing(false);
    }
  };
  const leaveTeam = async () => {
    setIsProcessing(true);
    try {
      const response = await axios.post("/team/leave/");

      if (response.status === 200) {
      }
      setJoined(false);
    } catch (error) {
      console.error("Error al abandonar el equipo", error);
    } finally {
      setIsProcessing(false);
    }
  };

  const isUserCaptainOrSubcaptain = () => {
    const member = teamData.members.find((m) => m.id === user.user_profile.id);

    if (!member) return false;

    const discordTag = member.discord_tag;

    return (
      teamData.captain.includes(discordTag) ||
      teamData.subcaptains.includes(discordTag)
    );
  };
  const isUserCaptain = () => {
    const member = teamData.members.find((m) => m.id === user.user_profile.id);

    if (!member) return false;

    const discordTag = member.discord_tag;

    return teamData.captain.includes(discordTag);
  };

  const isUserAMember = () => {
    return teamData.members.some((m) => m.id === user.user_profile.id);
  };
  useEffect(() => {
    if (requestStatus === "pending" && isUserAMember()) {
      setRequestStatus("accepted");
    }
  }, [requestStatus]);

  const confirmLeave = async () => {
    await leaveTeam();
    setShowLeaveModal(false);
    window.location.reload();
  };

  const cancelLeave = () => {
    setShowLeaveModal(false);
  };

  const removeMember = async (memberId) => {
    setIsRemoving(true);

    try {
      const response = await axios.post("/team/remove-member/", {
        user_id: memberId,
      });

      if (response.status === 200) {
        const updatedMembers = teamData.members.filter(
          (member) => member.id !== memberId
        );
        setTeamData({ ...teamData, members: updatedMembers });
      }
    } catch (error) {
      console.error("Error removing the team member", error);
    } finally {
      setIsRemoving(false);
      window.location.reload();
    }
  };

  const updateMemberRole = async (newRole) => {
    setIsLoading(true);

    try {
      const response = await axios.post("/team/edit-member-role/", {
        user_id: selectedMember.id,
        new_role: newRole,
      });

      if (response.status === 200) {
        console.log(response.data.detail);
      }
      setIsLoading(false);
      closeRoleUpdateModal();
    } catch (error) {
      setIsLoading(false);
      console.error("Error updating the member role", error);
    } finally {
      fetchTeamData();
    }
  };
  const getRankImage = (tier) => {
    switch (tier) {
      case "CHALLENGER":
        return challenger;
      case "GRANDMASTER":
        return grandmaster;
      case "MASTER":
        return master;
      case "DIAMOND":
        return diamond;
      case "EMERALD":
        return emerald;
      case "PLATINUM":
        return platinum;
      case "GOLD":
        return gold;
      case "SILVER":
        return silver;
      case "BRONCE":
        return bronce;
      case "IRON":
        return iron;
      default:
        return unranked;
    }
  };

  const getRoleImage = (role) => {
    switch (role) {
      case "Top":
        return top;
      case "Jungler":
        return jungler;
      case "Mid":
        return mid;
      case "ADC":
        return adc;
      case "Support":
        return support;
      default:
        return null;
    }
  };

  return (
    <Container>
      {teamData ? (
        <div className="flex flex-col gap-4 mt-4">
          <TeamPageCard teamData={teamData} />
          {isUserLoggedIn && user && user.has_profile ? (
            <div className="flex flex-wrap gap-2 justify-start items-center w-full">
              {isJoining ? (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                  <Loader />
                </div>
              ) : requestStatus === null &&
                teamData.accepting_requests &&
                !userData.in_team &&
                !isUserAMember() ? (
                <button
                  onClick={joinTeam}
                  className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105"
                >
                  Join
                </button>
              ) : requestStatus === "pending" ? (
                <div className="text-yellow-500 font-semibold">
                  Pending request...
                </div>
              ) : null}
              {isUserCaptainOrSubcaptain() && (
                <button
                  onClick={openRequestsModal}
                  className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105"
                >
                  View Requests
                </button>
              )}
              {isUserCaptain() && (
                <button
                  onClick={openTeamManagerModal}
                  className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105"
                >
                  Configure members.
                </button>
              )}
              {isUserCaptain() && (
                <button
                  onClick={toggleTeamInvites}
                  className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105"
                >
                  Manage invitations.
                </button>
              )}
              {isUserCaptain() && (
                <button
                  onClick={toggleTeamRequests}
                  className={`text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105 ${
                    teamData.accepting_requests
                      ? "bg-green-500 hover:bg-green-600"
                      : "bg-red-500 hover:bg-red-600"
                  }`}
                  disabled={isToggling}
                >
                  {teamData.accepting_requests ? "Open" : "Closed"}
                </button>
              )}
              {isUserCaptain() && teamData.members.length === 1 && (
                <button
                  onClick={() => setShowDeleteConfirmationModal(true)}
                  className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105"
                  disabled={isDeleting}
                >
                  {isDeleting ? "Deleting..." : "Delete Team"}
                </button>
              )}
              {isUserAMember() && !isUserCaptain() && (
                <button
                  onClick={() => setShowLeaveModal(true)}
                  className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105"
                >
                  Leave
                </button>
              )}
            </div>
          ) : null}
          <div className="flex flex-col gap-2">{renderPlayerCards()}</div>

          <Modal
            isOpen={showRoleUpdateModal}
            onRequestClose={closeRoleUpdateModal}
            className="bg-gray-800 rounded-lg p-6 max-w-md w-full mx-auto mt-20"
            overlayClassName="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center"
          >
            {isLoading && (
              <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                <Loader />
              </div>
            )}
            <div className="flex flex-col items-center justify-center relative z-0">
              <Heading medium className="text-white mb-4">
                Update Range
              </Heading>
              {selectedMember && (
                <div className="w-full text-white">
                  <p className="mb-4">
                    Update the range of: {selectedMember.discord_tag}
                  </p>
                  {teamData.subcaptains.includes(selectedMember.discord_tag) ? (
                    <div className="flex flex-col gap-2">
                      <button
                        onClick={() => updateMemberRole("miembro")}
                        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-300"
                      >
                        Delegate to member
                      </button>
                      <button
                        onClick={() => updateMemberRole("capitán")}
                        className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-300"
                      >
                        Promote to captain
                      </button>
                    </div>
                  ) : (
                    <button
                      onClick={() => updateMemberRole("subcapitán")}
                      className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300 w-full"
                    >
                      Promote to sub-captain
                    </button>
                  )}
                </div>
              )}
            </div>
          </Modal>

          <Modal
            isOpen={showTeamManagerModal}
            onRequestClose={closeTeamManagerModal}
            className="bg-gray-800 rounded-lg p-6 max-w-4xl w-full mx-auto mt-20 max-h-[90vh] overflow-y-auto"
            overlayClassName="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center"
          >
            <div className="flex flex-col items-center justify-center text-white">
              <Heading medium className="mb-6 text-white">
              Configure members
              </Heading>
              <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4">
                {teamData.members.map((member) => (
                  <div
                    key={member.id}
                    className="p-4 border-2 border-yellow-500 bg-gray-900 rounded-lg flex flex-col justify-between"
                  >
                    <div>
                      <p>
                        <span className="font-bold">Discord:</span>{" "}
                        {member.discord_tag}
                      </p>
                      <p>
                        <span className="font-bold">Role:</span> {member.role}
                      </p>
                      <p>
                        <span className="font-bold">Region:</span>{" "}
                        {member.region}
                      </p>
                      <p>
                        <span className="font-bold">Summoner:</span>{" "}
                        {member.soloq_account__summonerName}
                      </p>
                      <p>
                        <span className="font-bold">Rank:</span>{" "}
                        {member.soloq_account__tier}{" "}
                        {member.soloq_account__rank}
                      </p>
                      <p>
                        <span className="font-bold">LP:</span>{" "}
                        {member.soloq_account__leaguePoints}
                      </p>
                    </div>
                    {member.discord_tag !== teamData.captain[0] && (
                      <div className="flex flex-col gap-2 mt-4">
                        <button
                          onClick={() => openRoleUpdateModal(member)}
                          className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition duration-300"
                        >
                          Update rank
                        </button>
                        <button
                          onClick={() => removeMember(member.id)}
                          className="bg-red-500 text-white p-2 rounded hover:bg-red-600 transition duration-300"
                        >
                          Remove member
                        </button>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </Modal>

          <Modal
            isOpen={showLeaveModal}
            onRequestClose={() => setShowLeaveModal(false)}
            className="bg-gray-800 rounded-lg p-6 max-w-md w-full mx-auto mt-20"
            overlayClassName="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center"
          >
            <div className="flex flex-col items-center justify-center text-white">
              <Heading medium className="mb-6">
              Are you sure you want to leave the team?
              </Heading>
              <div className="flex gap-4">
                <button
                  onClick={confirmLeave}
                  className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition duration-300"
                >
                  Leave
                </button>
                <button
                  onClick={cancelLeave}
                  className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600 transition duration-300"
                >
                  Stay
                </button>
              </div>
            </div>
          </Modal>

          <Modal
            isOpen={showRequestsModal}
            onRequestClose={() => setShowRequestsModal(false)}
            className="bg-gray-800 rounded-lg p-6 max-w-4xl w-full mx-auto mt-20 max-h-[90vh] overflow-y-auto"
            overlayClassName="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center"
          >
            <div className="relative">
              <button
                onClick={() => setShowRequestsModal(false)}
                className="absolute top-2 right-2 bg-red-500 hover:bg-red-600 text-white font-bold rounded-full w-8 h-8 flex items-center justify-center transition duration-300"
              >
                X
              </button>
              <Heading medium className="text-white mb-6 text-center">
              Pending Requests
              </Heading>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                {teamRequests.map((request) => (
                  <div
                    key={request.id}
                    className="bg-gray-900 rounded-lg p-4 border-2 border-yellow-500 text-white flex flex-col items-center"
                  >
                    <p>
                      <span className="font-bold">Discord:</span>{" "}
                      {request.discord_tag}
                    </p>
                    <p>
                      <span className="font-bold">Summoner:</span>{" "}
                      {request.soloq_account_info.summonerName}
                    </p>
                    <p>
                      <span className="font-bold">Region:</span>{" "}
                      {request.region}
                    </p>
                    <div className="flex flex-col items-center my-2">
                      <p>
                        {request.soloq_account_info.tier}{" "}
                        {request.soloq_account_info.rank}
                      </p>
                      <img
                        src={getRankImage(request.soloq_account_info.tier)}
                        alt={request.soloq_account_info.tier}
                        className="w-16 h-16"
                      />
                    </div>
                    <div className="flex flex-col items-center mb-2">
                      <p>{request.role}</p>
                      <img
                        src={getRoleImage(request.role)}
                        alt={request.role}
                        className="w-12 h-12"
                      />
                    </div>
                    <p>
                      <span className="font-bold">Status:</span>{" "}
                      {request.request_status}
                    </p>
                    <div className="flex gap-2 mt-4">
                      <button
                        onClick={() => handleAcceptRequest(request.id)}
                        className="bg-green-500 text-white py-1 px-3 rounded hover:bg-green-600 transition duration-300"
                      >
                        Accept
                      </button>
                      <button
                        onClick={() => handleRejectRequest(request.id)}
                        className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-600 transition duration-300"
                      >
                        Reject
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Modal>

          <Modal
            isOpen={showDeleteConfirmationModal}
            onRequestClose={() => setShowDeleteConfirmationModal(false)}
            className="bg-gray-800 rounded-lg p-6 max-w-md w-full mx-auto mt-20"
            overlayClassName="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center"
          >
            <div className="flex flex-col items-center justify-center text-white">
              <Heading medium className="mb-6">
              Are you sure you want to delete this team?
              </Heading>
              <div className="flex gap-4">
                <button
                  onClick={deleteTeam}
                  className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition duration-300"
                >
                  Yes
                </button>
                <button
                  onClick={() => setShowDeleteConfirmationModal(false)}
                  className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600 transition duration-300"
                >
                  No
                </button>
              </div>
            </div>
          </Modal>

          <Modal
            isOpen={showInvitesModal}
            onRequestClose={() => setShowInvitesModal(false)}
            className="bg-gray-800 rounded-lg p-6 max-w-md w-full mx-auto mt-20"
            overlayClassName="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center"
          >
            <div className="flex flex-col items-center justify-center text-white">
              <Heading medium className=" text-white">
              Pending Invitations
              </Heading>

              {/* Si no hay invitaciones, mostramos el mensaje */}
              {noInvitesMessage ? (
                <p className="text-center text-white font-bold my-2">
                  {noInvitesMessage}
                </p>
              ) : (
                <ul className="w-full">
                  {teamInvites.map((invite) => (
                    <li
                      key={invite.id}
                      className="flex justify-between items-center mb-4"
                    >
                      <span>{invite.player_discord_tag}</span>
                      <button
                        onClick={() => deleteInvite(invite.id)}
                        className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition duration-300"
                      >
                        Delete
                      </button>
                    </li>
                  ))}
                </ul>
              )}

              {/* Botón para cerrar el modal */}
              <button
                onClick={() => setShowInvitesModal(false)}
                className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600 transition duration-300"
              >
                Close
              </button>
            </div>
          </Modal>
        </div>
      ) : (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <Loader />
        </div>
      )}
      {isProcessing && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <Loader />
        </div>
      )}
      {isDeleting && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <Loader />
        </div>
      )}
      {isRemoving && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <Loader />
        </div>
      )}
    </Container>
  );
};

export default TeamPage;
