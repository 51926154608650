import React, { useState, useEffect } from "react";
import axios from "axios";
import ExchangeCodeList from "./ExchangeCodeList";
import Container from "../../components/Container";
import TitleContainer from "../../components/TitleContainer";
import { Ticket } from "lucide-react";

const ExchangeCodesPage = () => {
  const [codes, setCodes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get("/exchange-codes/")
      .then((response) => {
        setCodes(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error al obtener códigos de intercambio:", error);
        setIsLoading(false);
      });
  }, []);

  return (
    <Container>
      <div className="flex flex-col gap-2 mt-4">
        <TitleContainer
          text="My Card Codes"
          icon={<Ticket />}
          color="bg-yellow-600"
          flex="start"
        />
        <ExchangeCodeList codes={codes} loading={isLoading}/>
      </div>
    </Container>
  );
};

export default ExchangeCodesPage;