import React from "react";
import {
  RiTicket2Line,
  RiShieldStarLine,
  RiCheckboxCircleLine,
  RiArrowDownSLine,
} from "react-icons/ri";

const summonerSpellList = [
  {
    name: "Ghost",
    imageUrl:
      "https://ddragon.leagueoflegends.com/cdn/13.23.1/img/spell/SummonerHaste.png",
  },
  {
    name: "Heal",
    imageUrl:
      "https://ddragon.leagueoflegends.com/cdn/13.23.1/img/spell/SummonerHeal.png",
  },
  {
    name: "Exhaust",
    imageUrl:
      "https://ddragon.leagueoflegends.com/cdn/13.23.1/img/spell/SummonerExhaust.png",
  },
  {
    name: "Ignite",
    imageUrl:
      "https://ddragon.leagueoflegends.com/cdn/13.23.1/img/spell/SummonerDot.png",
  },
  {
    name: "Barrier",
    imageUrl:
      "https://ddragon.leagueoflegends.com/cdn/13.23.1/img/spell/SummonerBarrier.png",
  },
];

export default function UserDetailsTournament({
  tournamentTickets,
  targetChampionBan,
  rulesetTier1,
  rulesetTier2,
  rulesetTier3,
  lowTierSpellBan,
  highTierSpellBan,
  canceledRule,
}) {
  const detailItems = [
    { icon: RiTicket2Line, title: "Join Tickets", value: tournamentTickets },
    { icon: RiShieldStarLine, title: "Ban Champion Ticket", value: targetChampionBan },
    { icon: RiCheckboxCircleLine, title: "Tier 1 Rule", value: rulesetTier1 },
    { icon: RiCheckboxCircleLine, title: "Tier 2 Rule", value: rulesetTier2 },
    { icon: RiCheckboxCircleLine, title: "Tier 3 Rule", value: rulesetTier3 },
    { icon: RiArrowDownSLine, title: "Ban Low Tier Spell", value: lowTierSpellBan },
    { icon: RiArrowDownSLine, title: "Ban High Tier Spell", value: highTierSpellBan },
    { icon: RiArrowDownSLine, title: "Cancel Rule", value: canceledRule },
  ];

  return (
    <div className="bg-gray-800 p-6 rounded-lg shadow-lg border-2 border-yellow-500">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        {detailItems.map((item, index) => (
          <div key={index} className="bg-gray-700 p-4 rounded-md flex items-center space-x-3">
            <item.icon className="text-yellow-500 text-2xl" />
            <div>
              <p className="text-gray-300 text-sm">{item.title}</p>
              <p className="text-white font-bold">{item.value}</p>
            </div>
          </div>
        ))}
      </div>
      
      <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-6">
        <div className="bg-gray-700 p-4 rounded-md">
          <h2 className="font-bold mb-3 text-center text-white">High Tier Summoner Spells</h2>
          <div className="flex justify-center gap-4">
            {summonerSpellList
              .filter(spell => ["Ignite", "Exhaust"].includes(spell.name))
              .map(spell => (
                <div key={spell.name} className="flex flex-col items-center">
                  <img
                    src={spell.imageUrl}
                    alt={spell.name}
                    className="w-12 h-12 rounded-lg"
                  />
                  <span className="text-gray-300 text-sm mt-1">{spell.name}</span>
                </div>
              ))}
          </div>
        </div>
        <div className="bg-gray-700 p-4 rounded-md">
          <h2 className="font-bold mb-3 text-center text-white">Low Tier Summoner Spells</h2>
          <div className="flex justify-center gap-4">
            {summonerSpellList
              .filter(spell => ["Ghost", "Heal", "Barrier"].includes(spell.name))
              .map(spell => (
                <div key={spell.name} className="flex flex-col items-center">
                  <img
                    src={spell.imageUrl}
                    alt={spell.name}
                    className="w-12 h-12 rounded-lg"
                  />
                  <span className="text-gray-300 text-sm mt-1">{spell.name}</span>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}